import Header from './Header';
import Footer from './Footer';
import JoinUs from './JoinUs';
import Faq from './Faq';
import Welcome from './Welcome';
import Partners from './Partners';
import TariffsTabs from './TariffsTabs';
import AnimatedPhone from './AnimatedPhone';
import Advantages from './Advantages';
import TextWithLotti from './TextWithLotti';

export {
  Header,
  Welcome,
  Footer,
  JoinUs,
  Faq,
  Partners,
  TariffsTabs,
  AnimatedPhone,
  Advantages,
  TextWithLotti,
};
