import React from 'react';
import classNames from 'classnames';

const Button = ({ htmlType, type, style, onClick, className, disabled, text, children }) => {
  const classes = classNames(type ? 'btn_v2-' + type : 'btn_v2', className)
  return (
    <button type={htmlType} style={style} onClick={onClick} className={classes} disabled={disabled}>
      {text}
      {children}
    </button>
  )
}

export default Button;
