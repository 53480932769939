import React from 'react';
import {Button, Divider, Typography, Col, Row} from 'antd';
import DeliveryTimeRestrictionsIcon from '../Icons/DeliveryTimeRestrictionsIcon'
import {Icon} from './Icon';
import AsyncWidget from './AsyncWidget'

import translator from '@webpack/translator';
const t = translator('front_office.components.delivery_time_restrictions.');
const {Text} = Typography;

const Content = ({title, description, buttonHref, buttonLabel}) =>
  <>
    <Row wrap={false}>
      <Col flex="68px">
        <Icon size={52} icon={DeliveryTimeRestrictionsIcon} />
      </Col>
      <Col flex="auto">
        <Row>
          <Text style={{color: "#424242"}}>{t("subtitle")}</Text>
        </Row>
        <Row>
          <Text style={{fontSize: "20px", lineHeight: "28px"}} strong>{title}</Text>
        </Row>
      </Col>
    </Row>
    <Divider style={{margin: "16px 0"}} />
    <Text style={{flex: 1, color: "rgba(0, 0, 0, 0.65)"}}>{description}</Text>
    <Button style={{borderRadius: 8, marginTop: 16}} size="large" block target="_blank" href={buttonHref}>
      <Text ellipsis>{buttonLabel}</Text>
    </Button>
  </>;

const DeliveryTimeRestrictions = ({endpoint}) => {
  return (
    <AsyncWidget endpoint={endpoint}>
      {(state) => <Content {...state} />}
    </AsyncWidget>
  );
}

export default DeliveryTimeRestrictions;
