import React from 'react';
import { Modal, Alert, Space, Button } from 'antd';

import translator from '@webpack/translator';
const t = translator('front_office.views.bulk_deliveries.modals.network_error.');

const NetworkError = ({ visible, onCancel }) => (
  <Modal
    centered
    title={t('title')}
    visible={visible}
    onCancel={onCancel}
    maskClosable={true}
    footer={
      <Button type="default" onClick={onCancel}>
        {t('button_return_to_send_form')}
      </Button>
    }
  >
    <Space size="small" direction="vertical">
      <Alert type="error" description={t('description')}></Alert>
    </Space>
  </Modal>
);

export default NetworkError;
