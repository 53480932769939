import { I18n } from 'i18n-js';
import en from './locales/en.json';
import ru from './locales/ru.json';
import ptBR from './locales/pt-BR.json';
import Helper from '@webpack/helper.js';

const defaultLocale = 'ru';

const i18n = new I18n({
  ...ru,
  ...en,
  ...ptBR
});

i18n.locale = Helper.getCookie('locale') || defaultLocale;
i18n.defaultLocale = defaultLocale;
i18n.enableFallback = true;

export const locale = () => {
  return i18n.locale;
};

export const withoutCurrentLocales = () => {
  let allLocales = ['ru', 'en', 'pt-BR'];
  var filteredLocales = allLocales.filter(function (lc) {
    return lc !== locale();
  });

  return filteredLocales;
};

export const locale_for_video = () => {
  return i18n.locale == "ru" ? "ru" : "en";
}

export default (namespace) =>
  (key, options = {}) =>
    i18n.t(namespace + key, options);
