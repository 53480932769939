import React from 'react';

export default (props) => {
  return (
    <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_255_1443)">
        <rect x="0.666626" width="52" height="52" rx="8" fill="#FFE8AD" />
        <path
          d="M38.7931 29.445C39.9863 28.2518 57.7256 10.0651 57.7256 10.0651C58.6983 9.09235 57.677 7.62677 56.5843 5.90829C55.4916 4.18981 50.2162 -0.615447 48.7214 -1.54926C47.2266 -2.48308 45.9167 -2.84623 44.9116 -1.84756L25.2755 17.7885C24.8419 18.1953 24.5827 18.7543 24.5524 19.3481C24.5234 19.5815 24.5455 19.8183 24.6172 20.0423C24.6888 20.2662 24.8084 20.4719 24.9675 20.6451C27.8837 24.5404 31.3834 27.9625 35.3432 30.7906C36.5623 31.6725 37.5999 30.6382 38.7931 29.445Z"
          stroke="#2C2C2B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.5848 19.0436C23.33 23.3592 22.5453 30.0062 21.6666 34.4126C21.6666 34.4126 33.8614 31.796 35.9916 31.0956"
          stroke="#2C2C2B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.8797 27.3342L28.4307 32.8852C26.5923 33.1349 21.68 34.4124 21.68 34.4124L22.8797 27.3342Z"
          fill="#2C2C2B"
          stroke="#2C2C2B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M67.129 -4.64884C66.0363 -6.36732 60.7642 -11.1758 59.2694 -12.1064C57.7746 -13.037 56.4647 -13.4034 55.4596 -12.4047L51.3352 -8.27386C56.1723 -4.97148 60.4233 -0.883747 63.9125 3.82034C66.474 1.26856 68.2671 -0.492067 68.2671 -0.492067C69.2593 -1.481 68.225 -2.93036 67.129 -4.64884Z"
          stroke="#2C2C2B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M50.4622 0.642822L27.9566 23.1484"
          stroke="#2C2C2B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M55.1414 5.07202C47.1099 13.204 40.8747 19.5462 32.84 27.6781"
          stroke="#2C2C2B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M57.7253 10.0652L63.9184 3.83325M51.3345 -8.27393L44.9113 -1.84422L51.3345 -8.27393Z"
          stroke="#2C2C2B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_255_1443">
          <rect x="0.666626" width="52" height="52" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
