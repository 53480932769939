import React from "react";

export default (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.6576 12.8426C24.6576 19.5569 19.2145 25 12.5002 25C10.0899 25 7.84352 24.2986 5.95388 23.0888L1.97935 24.2986L3.10414 20.5579C1.37869 18.459 0.342773 15.7718 0.342773 12.8426C0.342773 6.12824 5.78583 0.685181 12.5002 0.685181C19.2145 0.685181 24.6576 6.12824 24.6576 12.8426Z"
        fill="#868686"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5049 14.7552L13.2238 15.6707C12.512 15.2669 11.8576 14.7694 11.2782 14.1913C10.6561 13.5775 10.1172 12.8847 9.67525 12.1306L10.488 11.4308C10.6573 11.285 10.7718 11.0857 10.8124 10.8659C10.853 10.6461 10.8172 10.419 10.7111 10.2223L9.65704 8.26981C9.5869 8.14014 9.48841 8.02799 9.36893 7.94173C9.24945 7.85546 9.11206 7.79731 8.967 7.7716C8.82193 7.74589 8.67294 7.75329 8.53113 7.79325C8.38933 7.83321 8.25836 7.90469 8.148 8.00237L7.84047 8.27379C7.10182 8.92715 6.66929 9.99586 7.04039 11.0731C7.63725 12.7769 8.61665 14.321 9.90323 15.5865C11.6892 17.351 13.4966 18.0388 14.5534 18.3043C15.4043 18.5172 16.206 18.2191 16.7697 17.753L17.345 17.277C17.4663 17.1767 17.5617 17.0486 17.623 16.9035C17.6843 16.7585 17.7098 16.6007 17.6972 16.4437C17.6847 16.2867 17.6345 16.1351 17.5509 16.0016C17.4674 15.8681 17.3529 15.7568 17.2172 15.6771L15.5685 14.7081C15.4055 14.6122 15.2182 14.5655 15.0293 14.5739C14.8403 14.5823 14.6579 14.6452 14.5039 14.7552L14.5049 14.7552Z"
        fill="white"
      />
    </svg>
  );
};
