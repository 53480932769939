import React from 'react';
import { Row, Col, Form, Input, Checkbox } from 'antd';

import translator from '@webpack/translator';
const t = translator('front_office.views.bulk_deliveries.form.textarea_field.');

const { TextArea } = Input;

const TextareaField = ({
  textTexarea,
  urlShorting,
  shortLinkEnabled,
  recountSms,
  onClick,
  onChange,
  onChangeCheckbox,
}) => {
  return (
    <Form.Item label={t('label')}>
      <Row gutter={30}>
        <Col span={24} md={24} lg={16}>
          <TextArea
            id="smstext"
            name="text"
            defaultValue={''}
            value={textTexarea}
            onChange={onChange}
          />
        </Col>
        <Col span={24} md={24} lg={8}>
          <div className="help-box">
            <span
              className="btn btn-sm btn-secondary small"
              id="clear_sms_text_area"
              onClick={onClick}
            >
              {t('clear_btn')}
            </span>
            {shortLinkEnabled && <p className="help-block pt-3">{t('link_note')}</p>}
            {shortLinkEnabled && (
              <p className="help-block">
                {t('link_example')} <br />
                <b>{t('url_prefix')}</b>
              </p>
            )}
            {shortLinkEnabled && (
              <div className="v1_check_box" onClick={recountSms}>
                <Checkbox
                  id="url_shorter"
                  name="url_shorting"
                  defaultChecked={urlShorting}
                  onChange={onChangeCheckbox}
                >
                  {t('shorten_link')}
                </Checkbox>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default TextareaField;
