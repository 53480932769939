import React from 'react';
import { ConnectButton } from '360dialog-connect-button';

import translator from '@webpack/translator';
const t = translator('front_office.components.waba_button.');

const WabaButton = ({ encoded_customer_id, redirect_url }) => {
  const handleCallback = callbackObject => {
    console.log('client ID: ' + callbackObject.client);
    console.log('channel IDs: ' + callbackObject.channels);
  };

  return (
    <div>
      <ConnectButton
        partnerId={'0BxnbzPA'}
        callback={handleCallback}
        className="btn btn-primary"
        label={t('label')}
        queryParameters={{
          partner: encoded_customer_id,
          redirect_url: location.origin + redirect_url
        }}
      />
    </div>
  );
}

export default WabaButton;
