import OneC from './1c';
import Amocrm from './Amocrm';
import Beautybox from './Beautybox';
import Bitrix from './Bitrix';
import Mindbox from './Mindbox';
import Opencart from './Opencart';
import Yclients from './Yclients';
import Webasyst from './Webasyst';

export { OneC, Amocrm, Beautybox, Bitrix, Mindbox, Opencart, Yclients, Webasyst };
