import React, { useState } from 'react';
import { Col, Row, Slider } from 'antd';
import { Megafon, Beeline, Tele2, Mts } from '../../imgs/tariff_sms';

import translator from '@webpack/translator';
const t = translator('main_page.elements.tariffs.sms_tab.');

const refillSteps = [1, 1000, 3000, 5000, 10000, 25000, 50000, 75000, 100000];
const formatter = (value) => <div>{`${refillSteps[value]} ₽`}</div>;

const SmsTab = ({ tariff_sms_default_prices }) => {
  const [selectedRefill, changeSelectedRefill] = useState(0);

  const smsColumns = [
    {
      title: t('channel'),
      dataIndex: 'channel',
      key: 'channel',
    },
    {
      title: t('adv_pay_name'),
      dataIndex: 'adv_pay_name',
      key: 'adv_pay_name',
    },
    {
      title: t('service_pay_name'),
      dataIndex: 'service_pay_name',
      key: 'service_pay_name',
    },
    {
      title: t('free_name'),
      dataIndex: 'free_name',
      key: 'free_name',
    },
  ];

  const operatorSlugs = ['mobilnye_telesistemy', 'tele2', 'vympelkommunikacii', 'megafon'];
  const operatorIcons = {
    mobilnye_telesistemy: <Mts />,
    tele2: <Tele2 />,
    vympelkommunikacii: <Beeline />,
    megafon: <Megafon />,
  };

  const mainRefillDefaultPrices = tariff_sms_default_prices[selectedRefill];
  return (
    <div className="sms-tab-content">
      <Row>
        <Col lg={2} xl={4}></Col>
        <Col lg={20} xl={16}>
          <div className="sms-tab-content-desc">{t('description')}</div>
          <div className="sms-tab-content-price_range">
            <Slider
              id="sms_slider"
              defaultValue={0}
              max={8}
              onChange={(value) => changeSelectedRefill(value)}
              tooltip={{
                formatter: formatter,
                placement: 'bottom',
              }}
            />
          </div>
        </Col>
        <Col lg={2} xl={4}></Col>
        <Col xs={0} lg={24} xl={24}>
          <table>
            <thead>
              <tr>
                {smsColumns.map((column) => (
                  <th key={column.key}>{column.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {mainRefillDefaultPrices &&
                operatorSlugs.map((operator) => (
                  <tr>
                    <td>
                      {operatorIcons[operator]}{' '}
                      <span>{mainRefillDefaultPrices[operator]['name']}</span>
                    </td>
                    <td>{mainRefillDefaultPrices[operator]['name_advert']} ₽</td>
                    <td>{mainRefillDefaultPrices[operator]['service']} ₽</td>
                    <td>{mainRefillDefaultPrices[operator]['advert']} ₽</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Col>
        <Col xs={24} md={24} lg={0} xl={0}>
          {mainRefillDefaultPrices &&
            operatorSlugs.map((operator) => (
              <table>
                <tbody>
                  <tr>
                    <td>
                      {operatorIcons[operator]}{' '}
                      <span>{mainRefillDefaultPrices[operator]['name']}</span>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>{smsColumns[1].title}</td>
                    <td>{mainRefillDefaultPrices[operator]['name_advert']} ₽</td>
                  </tr>
                  <tr>
                    <td>{smsColumns[2].title}</td>
                    <td>{mainRefillDefaultPrices[operator]['service']} ₽</td>
                  </tr>
                  <tr>
                    <td>{smsColumns[3].title}</td>
                    <td>{mainRefillDefaultPrices[operator]['advert']} ₽</td>
                  </tr>
                </tbody>
              </table>
            ))}
        </Col>
      </Row>
    </div>
  );
};

export default SmsTab;
