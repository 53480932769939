import React from 'react';

import translator from '@webpack/translator';
const t = translator('main_page.elements.tariffs.radio.');

const Radio = ({
  name,
  value,
  title,
  desc,
  price,
  indexPrice,
  black_label,
  onClick,
  selectedTariffPrice,
}) => {
  return (
    <label
      htmlFor={`${name}_${value}`}
      className="messenger-card with_radio"
      onClick={() => onClick(title, indexPrice)}
    >
      <input
        id={`${name}_${value}`}
        type="radio"
        name={name}
        value={value}
        defaultChecked={indexPrice == selectedTariffPrice}
      />

      <div className="messenger-card-radio">
        <div className="messenger-card-radio-checkmark_box">
          <div className="messenger-card-radio-checkmark_box-checkmark"></div>
        </div>
      </div>
      <div className="messenger-card-body">
        <div className="messenger-card-body-title">{title}</div>
        <div className="messenger-card-body-desc">{desc}</div>
        <div className="messenger-card-body-labels">
          <label className="messenger-card-body-label">
            {price} {t('unit')}
          </label>
          {black_label && (
            <label className="messenger-card-body-label black">{t('discount')}</label>
          )}
        </div>
      </div>
    </label>
  );
};

export default Radio;
