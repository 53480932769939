import React from 'react';

export default props => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.8135 16H21.9693C21.3389 16 20.8262 16.5128 20.8262 17.1431V19.826H21.8697V17.1431C21.8697 17.0882 21.9143 17.0435 21.9693 17.0435H30.8135C30.8684 17.0435 30.9131 17.0882 30.9131 17.1431V25.9873C30.9131 26.0423 30.8684 26.087 30.8135 26.087H28.2142V27.1305H30.8135C31.4438 27.1305 31.9566 26.6177 31.9566 25.9873V17.1431C31.9566 16.5128 31.4438 16 30.8135 16Z"
        fill="#1F1F1F"
      />
      <path
        d="M26.0284 20.8696H17.1841C16.5538 20.8696 16.041 21.3824 16.041 22.0128V30.857C16.0411 31.4873 16.5538 32.0001 17.1841 32.0001H26.0284C26.6587 32.0001 27.1715 31.4873 27.1715 30.857V22.0128C27.1716 21.3824 26.6587 20.8696 26.0284 20.8696ZM26.0284 30.9567H17.1841C17.1292 30.9567 17.0845 30.912 17.0845 30.857V22.0128C17.0845 21.9578 17.1292 21.9131 17.1841 21.9131H26.0284C26.0833 21.9131 26.128 21.9578 26.128 22.0128V30.857H26.1281C26.1281 30.912 26.0833 30.9567 26.0284 30.9567Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
