import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Wrapper = ({ id, children, className, style }) => {
    return (
        <div id={id} className={classNames('wrapper', className)} style={style}>
            {children}
        </div>
    );
}

Wrapper.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string
};

export default Wrapper;
