import React from 'react';
import { Col, Row } from 'antd';
import Lottie from 'lottie-react';
import classNames from 'classnames';

const TextWithLotti = ({ id, title, desc, links, lottie, revert, col_grid }) => {
  const default_col_grid = col_grid || { xs: 24, md: 24, lg: 20, xl: 16 };
  return (
    <div id={id} className="container">
      <div className={classNames('text_with_lotti', id)}>
        <Row>
          <Col xs={0} md={0} lg={2} xl={4}></Col>
          <Col {...default_col_grid}>
            <Row gutter={{ md: 80, lg: 140 }} justify={revert && 'revert'}>
              <Col xs={24} md={12} lg={12}>
                <Lottie {...lottie} className="lottie" />
              </Col>
              <Col xs={24} md={12} lg={12}>
                <div className="text_with_lotti-body">
                  <div className="text_with_lotti-body-title">{title}</div>
                  <div className="text_with_lotti-body-desc">{desc}</div>
                  <div className="text_with_lotti-body-links">
                    {links &&
                      links.map(({ className, href, text }) => (
                        <a
                          key={`${href}${text}`}
                          className={`button button-common ${className}`}
                          href={href}
                        >
                          {text}
                        </a>
                      ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={0} md={0} lg={2} xl={4}></Col>
        </Row>
      </div>
    </div>
  );
};

export default TextWithLotti;
