import React, { useState, useEffect } from 'react';
import { Col, Row, Slider } from 'antd';
import Scope from '../../imgs/Scope';
import Radio from './Radio';

import translator from '@webpack/translator';
const t = translator('main_page.elements.tariffs.messenger_tab.');

const MessengerTab = () => {
  const [selectedTariff, changeSelectedTariff] = useState('WhatsApp');
  const [selectedTariffPrice, changeSelectedTariffPrice] = useState(0);
  const [selectedStandartStep, selectedStandartStepChange] = useState(0);
  const [selectedSalonStep, selectedSalonStepChange] = useState(0);
  const [telegramTariffVisible, changeTelegramTariffVisible] = useState(false);

  const standartDiscountRange = [0, 0, 5, 5, 5, 10, 10, 10, 10, 10, 10, 20, 20];
  const salondiscountRange = [0, 15, 20, 22, 25, 25, 26, 27, 28, 30, 35];
  const standartMonthRange = t('standart_month_range');
  const salonCountRange = t('salon_count_range');

  const defaultTariffPrices = [2000, 2750, 3500, 2000];

  const refreshCards = () => {
    calcStandardTariff();
    calcSmallSalonTariff();
    salonTariffCalc();
  };

  useEffect(() => refreshCards());

  const calcStandardTariff = () => {
    const tariff_price = defaultTariffPrices[selectedTariffPrice];
    const price = tariff_price - (tariff_price / 100) * standartDiscountRange[selectedStandartStep];

    document.getElementById('standart_tariff_months').textContent =
      standartMonthRange[selectedStandartStep];
    document.getElementById('standart_tariff_discount').textContent = t('discount', {
      percent: standartDiscountRange[selectedStandartStep],
    });
    document.getElementById('standart_tariff_price').textContent = price;
    document.getElementById('standart_tariff_link').href = t('link_text', {
      tariff: selectedTariff,
      months: standartMonthRange[selectedStandartStep],
      price,
    });
  };

  const calcSmallSalonTariff = () => {
    let price = 999;
    if (selectedTariffPrice == 1) {
      price = 1749;
    }
    if (selectedTariffPrice == 2) {
      price = 2500;
    }

    document.getElementById('small_salon_price').textContent = price;
    document.getElementById('small_salon_link').href = t('small_salon_link_text', {
      tariff: selectedTariff,
      price,
    });
  };

  const salonTariffCalc = () => {
    const tariff_price = defaultTariffPrices[selectedTariffPrice];
    const price = tariff_price - (tariff_price / 100) * salondiscountRange[selectedSalonStep];

    document.getElementById('salon_tariff_months').textContent = salonCountRange[selectedSalonStep];
    document.getElementById('salon_tariff_discount').textContent = t('discount', {
      percent: salondiscountRange[selectedSalonStep],
    });
    document.getElementById('salon_tariff_price').textContent = price;
    document.getElementById('salon_tariff_link').href = t('network_link_text', {
      tariff: selectedTariff,
      months: standartMonthRange[selectedStandartStep],
      price,
    });
  };

  const onClickRadio = (title, indexPrice) => {
    changeSelectedTariff(title);
    changeSelectedTariffPrice(indexPrice);
  };

  return (
    <div className="messenger-tab-content">
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={8}>
          <Radio
            name="tariff"
            value="whatsapp"
            title="WhatsApp"
            desc={t('whatsapp.desc')}
            price={2000}
            indexPrice={0}
            onClick={onClickRadio}
            selectedTariffPrice={selectedTariffPrice}
          />
        </Col>
        <Col xs={24} lg={8}>
          <Radio
            name="tariff"
            value="whatsapp_max"
            title="WhatsApp Max"
            desc={t('whatsapp_max.desc')}
            price={2750}
            indexPrice={1}
            onClick={onClickRadio}
            selectedTariffPrice={selectedTariffPrice}
          />
        </Col>
        <Col xs={24} lg={8}>
          <Radio
            name="tariff"
            value="whatsapp_telegram"
            title={t('whatsapp_telegram.title')}
            desc={t('whatsapp_telegram.desc')}
            black_label={t('whatsapp_telegram.black_label')}
            indexPrice={2}
            price={3500}
            onClick={onClickRadio}
            selectedTariffPrice={selectedTariffPrice}
          />
        </Col>
        <Col xs={0} lg={8}></Col>
        <Col xs={24} lg={8}>
          <div className="telegram_tariff_box">
            <div className={`telegram_tariff_box-card ${telegramTariffVisible && 'full'}`}>
              <Radio
                name="tariff"
                value="telegram"
                title="Telegram"
                desc={t('telegram.desc')}
                price={2000}
                indexPrice={3}
                onClick={onClickRadio}
                selectedTariffPrice={selectedTariffPrice}
              />
            </div>

            <span
              className={`${telegramTariffVisible && 'hidden'}`}
              onClick={() => changeTelegramTariffVisible(true)}
            >
              {t('other_tariffs')}
            </span>
          </div>
        </Col>
        <Col xs={0} lg={8}></Col>
        <Col xs={24} lg={8}>
          <div className="messenger-card">
            <div className="messenger-card-body">
              <div className="start">
                <div className="messenger-card-body-title">{t('standard_title')}</div>
                <div className="messenger-card-body-label">{selectedTariff}</div>
                <div className="messenger-card-body-desc">{t('standard_desc')}</div>
              </div>

              <div className="end">
                <div className="messenger-card-body-slider_text_box">
                  <div className="messenger-card-body-slider_text_box-discount">
                    <div id="standart_tariff_months">{t('standard_months')}</div>
                    <div id="standart_tariff_discount">{t('standard_discount')}</div>
                  </div>
                  <Slider
                    min={0}
                    max={12}
                    step={1}
                    tooltip={{ open: false }}
                    defaultValue={0}
                    onChange={(value) => {
                      selectedStandartStepChange(value);
                      calcStandardTariff();
                    }}
                  />
                </div>
                <a
                  id="standart_tariff_link"
                  className="button"
                  href={t('link_text', {
                    tariff: 'WhatsApp',
                    months: standartMonthRange[0],
                    price: '2000',
                  })}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('standard_link', {
                        price: '<span id="standart_tariff_price">2000</span>',
                      }),
                    }}
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={8}>
          <div className="messenger-card">
            <div className="messenger-card-body">
              <div className="start">
                <div className="messenger-card-body-title">{t('small_title')}</div>
                <div className="messenger-card-body-label">{selectedTariff}</div>
                <div className="messenger-card-body-desc">{t('small_desc')}</div>
              </div>
              <div className="end">
                <div className="messenger-card-body-slider_text_box">
                  <div className="messenger-card-body-slider_text_box-desc">
                    <div>
                      <Scope />
                    </div>
                    <div>{t('small_check')}</div>
                  </div>
                </div>
                <a
                  id="small_salon_link"
                  className="button"
                  href={t('small_salon_link_text', {
                    tariff: 'WhatsApp',
                    price: '999',
                  })}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('small_link', {
                        price: '<span id="small_salon_price">999</span>',
                      }),
                    }}
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={8}>
          <div className="messenger-card">
            <div className="messenger-card-body">
              <div className="start">
                <div className="messenger-card-body-title">{t('network_title')}</div>
                <div className="messenger-card-body-label">{selectedTariff}</div>
                <div className="messenger-card-body-desc">{t('network_desc')}</div>
              </div>
              <div className="end">
                <div className="messenger-card-body-slider_text_box">
                  <div className="messenger-card-body-slider_text_box-discount">
                    <div id="salon_tariff_months">1 салон</div>
                    <div id="salon_tariff_discount">{t('network_discount')}</div>
                  </div>
                  <Slider
                    min={0}
                    max={10}
                    step={1}
                    defaultValue={0}
                    tooltip={{ open: false }}
                    onChange={(value) => {
                      selectedSalonStepChange(value);
                      salonTariffCalc();
                    }}
                  />
                </div>
                <a
                  id="salon_tariff_link"
                  className="button"
                  href={t('network_link_text_2', {
                    tariff: 'WhatsApp',
                    price: '2000',
                  })}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('network_link', {
                        price: '<span id="salon_tariff_price">200</span>',
                      }),
                    }}
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MessengerTab;
