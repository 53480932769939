import React from 'react';

export default (props) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.0844 11.9147C11.0844 11.9147 10.8078 13.6077 10.7412 14.0249C10.6365 14.6795 10.1806 14.7148 9.5867 14.6808C8.82485 14.6368 8.04032 14.5982 7.2878 14.4755C6.79122 14.3949 5.46813 14.4422 5.25084 13.8756C5.17086 13.6683 5.39881 12.2993 5.39881 12.2993L5.58145 10.005C5.58145 10.005 5.89406 6.54766 5.94471 6.09775C5.97737 5.81513 5.98137 5.43854 6.21133 5.2799C6.49928 5.0846 7.57041 5.05194 9.01081 5.18458C10.4512 5.31722 11.369 5.58451 11.4897 5.78847C11.6103 5.99243 11.4824 6.95892 11.4344 7.37218C11.4217 7.48549 11.3737 8.08738 11.3177 8.81858L11.0844 11.9147Z"
        fill="#FFEE77"
        stroke="#FFEE77"
        strokeWidth="0.46658"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.05324 10.9595L2.54601 11.3468"
        stroke="#262D33"
        strokeWidth="0.46658"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.10924 4.92063L2.78596 4.64069"
        stroke="#262D33"
        strokeWidth="0.46658"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.54648 4.16143L3.34918 3.82483"
        stroke="#262D33"
        strokeWidth="0.46658"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7181 3.97612L13.2427 3.45288"
        stroke="#262D33"
        strokeWidth="0.46658"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5193 8.99987L14.1172 8.86057"
        stroke="#262D33"
        strokeWidth="0.46658"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5673 10.1577L13.9906 10.2603"
        stroke="#262D33"
        strokeWidth="0.46658"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.87253 3.03431C6.88738 2.78816 6.84637 2.54184 6.75255 2.31378"
        stroke="#262D33"
        strokeWidth="0.46658"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.23714 9.68242C7.47709 10.0157 7.73304 10.4256 7.97767 10.7909C8.66221 10.2243 9.31075 9.49178 9.9593 8.8819"
        stroke="#262D33"
        strokeWidth="0.46658"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
