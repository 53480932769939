import Start from './Start.jsx';
import WhatsApp from './WhatsApp.jsx';
import Sms from './Sms.jsx';
import Manual from './Manual.jsx';
import PaymentMethods from './PaymentMethods.jsx';
import End from './End';
import Canceled from './Canceled';
import Success from './Success';

export { Start, WhatsApp, Sms, Manual, PaymentMethods, End, Canceled, Success };
