import React from 'react';
import SuccessIcon from '../Icons/SuccessIcon';

import translator from '@webpack/translator';
const t = translator('front_office.components.ready_to_help.');

const ReadyToHelp = ({
  customerId,
  phone,
  email,
  yclientsSalonItems = [],
  altegioSalonItems = [],
  developer,
}) => {
  const yclientsSalonCount = yclientsSalonItems.length;
  const altegioSalonCount = altegioSalonItems.length;

  const addYclientsSalonInfo = () => {
    const salonIds = yclientsSalonItems.map(({ salonId }) => salonId).join(', ');
    const salonNames = yclientsSalonItems.map(({ salonName }) => salonName).join(', ');
    return (
      <>
        <br />
        {t('yclients_salon_id')} {salonIds}
        <br />
        {t('yclients_salon_name')} {salonNames}
      </>
    );
  };

  const addAltegioSalonInfo = () => {
    const salonIds = altegioSalonItems.map(({ salonId }) => salonId).join(', ');
    const salonNames = altegioSalonItems.map(({ salonName }) => salonName).join(', ');
    return (
      <>
        <br />
        {t('allegio_salon_id')} {salonIds}
        <br />
        {t('allegio_salon_name')} {salonNames}
      </>
    );
  };

  const getPopoverText = () => {
    return (
      <>
        {t('phone')} {phone},
        <br />
        {t('email')} {email}
        <br />
        ID: {customerId}
        <br />
        {yclientsSalonCount > 0 && addYclientsSalonInfo()}
        <br />
        {altegioSalonCount > 0 && addAltegioSalonInfo()}
      </>
    );
  };

  const getPopoverHeader = () => {
    let popover_header = '';
    if (yclientsSalonCount > 0 || altegioSalonCount > 0) {
      const salonItems = yclientsSalonCount > 0 ? yclientsSalonItems : altegioSalonItems;
      const [firstSalon] = salonItems;
      const { salonName, salonId } = firstSalon;
      popover_header += `${salonName} / ${salonId} `;
      if (salonItems.length > 1)
        popover_header += t('many_salons_count', { count: salonItems.length - 1 });
    } else {
      popover_header = `${t('customer_id')} ${customerId} ${developer ? 'Developer' : ''}`;
    }
    return popover_header;
  };

  return (
    <div>
      <div>
        <div className="d-flex">
          <SuccessIcon />
          <h4>{t('your_data')}</h4>
        </div>
        <div className="telegram-flow_popover-text">{getPopoverText()}</div>
      </div>
      <p
        className="ready_to_help-title text-truncate"
        data-toggle="tooltip"
        data-placement="bottom"
        title={getPopoverHeader()}
      >
        {getPopoverHeader()}
      </p>
    </div>
  );
};

export default ReadyToHelp;
