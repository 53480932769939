import React from 'react';
import { Form } from 'antd';

import translator from '@webpack/translator';
const t = translator('front_office.views.bulk_deliveries.form.sms_progress_bar.');

const SmsProgressBar = ({
  isSmsSelected,
  isWhatsAppSelected,
  lengthValue,
  countValue,
  timeValue,
}) => {
  const smsInfo = isSmsSelected && (
    <div className="sms_info">
      <div className="symbol_count">
        {t('symbol_count')} &nbsp;<b id="sms_length_value"> {lengthValue} </b>
      </div>
      <div className="sms_count">
        {t('sms_count')}&nbsp;<b id="sms_count_value"> {countValue} </b>
      </div>
    </div>
  );

  const whatsAppInfo = isWhatsAppSelected && (
    <div className="d-flex justify-content-start text-center">
      <div className="whatsapp_messages_time_delivery">
        {t('whatsapp_messages_delivery_time')}&nbsp;
        <b id="whatsapp_messages_time_delivery_value"> {timeValue} </b>
      </div>
    </div>
  );

  const isShowProgressBar = isWhatsAppSelected || isSmsSelected;

  return (
    <>
      {isShowProgressBar && (
        <Form.Item>
          <div className="sms_counter">
            <div className="progress_bar_box">
              <div id="ru_bar" className="progress_bar_box-ru">
                <div id="ru_bar_line" className="progress_bar_line">
                  {[...Array(13).keys()].map((num) => {
                    return (
                      <div key={`ru-divider-${num}`} className={`divider divider-${num}`}></div>
                    );
                  })}
                </div>
              </div>
              <div id="en_bar" className="progress_bar_box-en">
                <div id="en_bar_line" className="progress_bar_line">
                  {[...Array(5).keys()].map((num) => {
                    return (
                      <div key={`eng-divider-${num}`} className={`divider divider-${num}`}></div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {whatsAppInfo || smsInfo}
        </Form.Item>
      )}
    </>
  );
};

export default SmsProgressBar;
