import React, {useEffect, useState} from 'react';
import VanillaAntd from './VanillaAntd';
import {Section} from '@components/grid';
import {Divider, Col, Row, Skeleton} from 'antd';
import axios from 'axios';

const AsyncWidget = ({endpoint, children}) => {
  const [state, setState] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios({
      method: 'GET',
      url: endpoint,
      headers: {
        Accept: 'application/javascript',
      },
    })
      .then(({data}) => setState(data))
      .catch(_error => setState({hidden: true}))
      .finally(() => setIsLoading(false));
  }, []);

  const Loading = () =>
    <>
      <Row wrap={false}>
        <Col flex="68px">
          <Skeleton.Avatar style={{borderRadius: 8}} active size={52} shape="square" />
        </Col>
        <Col flex="auto">
          <Row>
            <Skeleton active title={false} paragraph={{block: true, rows: 2}} />
          </Row>
        </Col>
      </Row>
      <Divider style={{margin: "8px 0px 16px 0px"}} />
      <Skeleton active title={false} paragraph={{block: true, rows: 4}} />
    </>;

  if (state.hidden) {
    return null;
  }

  return (
    <VanillaAntd>
      <Section style={{
        display: "flex",
        flexDirection: "column",
        padding: 24,
        borderRadius: 16,
        minHeight: 370
      }}>
        {isLoading ? <Loading /> : children(state)}
      </Section>
    </VanillaAntd>
  );
};

export default AsyncWidget
