import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Container = ({ id, children, className, style }) => {
    return (
        <div id={id} className={classNames('container', className)} style={style}>
            {children}
        </div>
    )
}

Container.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string
};

export default Container;
