import React from 'react';
import {SVGUniqueID} from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 16C9 12.134 12.134 9 16 9C19.866 9 23 12.134 23 16C23 19.866 19.866 23 16 23C12.134 23 9 19.866 9 16Z"
          fill="#EE8208"
        />
        <path
          d="M18.2221 14.0645C18.2221 15.2026 17.2232 16.1284 15.9944 16.1284C14.7658 16.1284 13.7666 15.2026 13.7666 14.0645C13.7666 12.926 14.7658 12 15.9944 12C17.2232 12 18.2221 12.926 18.2221 14.0645ZM16.9168 14.0645C16.9168 13.5932 16.503 13.2099 15.9944 13.2099C15.4861 13.2099 15.072 13.5932 15.072 14.0645C15.072 14.5355 15.4861 14.919 15.9944 14.919C16.503 14.919 16.9168 14.5355 16.9168 14.0645Z"
          fill="white"
        />
        <path
          d="M16.9014 17.8134C17.3549 17.7177 17.7922 17.5514 18.195 17.317C18.4999 17.1389 18.5917 16.7655 18.3996 16.483C18.2077 16.1999 17.805 16.1147 17.4996 16.2928C16.5871 16.8245 15.4122 16.8244 14.5002 16.2928C14.1948 16.1147 13.792 16.1999 13.6005 16.483C13.4084 16.7657 13.5 17.1389 13.8048 17.317C14.2076 17.5512 14.645 17.7177 15.0984 17.8134L13.8529 18.9676C13.5983 19.2038 13.5983 19.5867 13.8532 19.8229C13.9808 19.9408 14.1477 19.9999 14.3145 19.9999C14.4817 19.9999 14.6488 19.9408 14.7764 19.8229L15.9998 18.6887L17.2242 19.8229C17.4788 20.059 17.8919 20.059 18.1468 19.8229C18.4019 19.5867 18.4019 19.2035 18.1468 18.9676L16.9014 17.8134Z"
          fill="white"
        />
        <rect x="1" y="1" width="14" height="14" rx="4" fill="#2789F6" />
        <path
          d="M11.4617 9.16705C11.3539 9.02089 11.3298 8.87296 11.3887 8.72306C11.5356 8.32314 13.6747 5.78306 12.7836 5.78306C12.1712 5.7912 11.4348 5.78306 10.8477 5.78306C10.7387 5.80959 10.6682 5.86679 10.6241 5.98315C10.2808 6.77473 9.86071 7.74074 9.24504 8.36157C9.16404 8.42688 9.11353 8.4241 9.02126 8.39994C8.58939 7.94527 9.04988 6.38754 8.78726 5.77203C8.73549 5.65154 8.6222 5.59712 8.50627 5.56648C7.91994 5.42675 6.58084 5.50992 6.36235 5.7912C6.29989 5.87161 6.29118 5.91549 6.33627 5.92271C6.54445 5.95555 6.69182 6.03412 6.77856 6.15833C6.94219 6.51868 7.06278 8.4382 6.58084 8.4382C6.0989 8.4382 5.32176 6.5544 5.10827 6.03218C5.05146 5.87494 4.90683 5.78479 4.75443 5.75819L3.32865 5.76909C3.07803 5.76909 2.93796 5.89272 3.02679 6.14179C3.7694 7.98515 5.39449 11.5582 7.88696 11.4687C8.13664 11.4687 8.56619 11.5646 8.7612 11.3481C9.02918 10.9926 8.70235 10.3594 9.16187 10.1179C9.27776 10.0563 9.39788 10.108 9.49745 10.1838C10.0086 10.573 10.28 11.2561 10.9103 11.4635C11.0211 11.5 11.1182 11.5091 11.2016 11.4909L12.5337 11.469C12.781 11.469 13.0246 11.359 12.9734 11.0633C12.7792 10.4292 12.0054 9.77749 11.4617 9.16705Z"
          fill="white"
        />
      </svg>
    </SVGUniqueID>
  );
};
