import React from 'react'
import Button from '@components/Button';

const Card = ({ title, text, icon, link, button, onClick }) => {
  return (
    <div className='postcard'>
      <div className='postcard-header'>
        <div className='postcard-title'>{title}</div>
      </div>
      <div className='postcard-body'>
        {text && <div className='postcard-body-text'>{text}</div>}
        {icon && <div className='postcard-body-icon'><img src={icon} /></div>}
      </div>
      <div className='postcard-footer'>
        {link && <a href={link.href} className={"btn_v2-primary"} onClick={onClick}> {link.text} </a>}
        {button && <Button className={"btn_v2-primary"} text={button.text} onClick={onClick} />}
      </div>
    </div>
  )
}

export default Card;