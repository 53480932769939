import React from 'react';
import { Col, Row } from 'antd';

import translator from '@webpack/translator';
const t = translator('main_page.elements.join_us.');

const JoinUs = () => {
  return (
    <div className="container">
      <div className="join_us">
        <Row>
          <Col xs={0} md={4} lg={2} xl={4}></Col>
          <Col xs={24} md={16} lg={20} xl={16}>
            <div className="join_us-body">
              <div className="join_us-body-title">{t('title')}</div>
              <div
                className="join_us-body-desc"
                dangerouslySetInnerHTML={{ __html: t('description') }}
              ></div>
              <a className="button button-primary" href="/customers/sign_up">
                {t('button_text')}
              </a>
            </div>
          </Col>
          <Col xs={0} md={4} lg={2} xl={4}></Col>
        </Row>
      </div>
    </div>
  );
};

export default JoinUs;
