import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 10.9157C31.9881 9.57019 29.4992 8.95944 23.0564 8.95944C7.93935 8.95944 4.10408 8.31439 0 4C1.72662 6.59055 2.67129 11.4495 18.6495 11.2077C35.3203 10.9511 25.1501 15.5018 22.191 20.6431C27.5276 15.885 31.9839 12.7488 32 10.9352V10.9157ZM10.7573 24.7323C10.7567 25.983 9.72678 27.012 8.47457 27.0123C7.20986 27.0123 6.1871 25.9907 6.1871 24.7323C6.1871 23.4796 7.21879 22.4469 8.47338 22.4469C9.73392 22.4469 10.7573 23.4687 10.7573 24.7323ZM21.1676 24.7323C21.167 25.983 20.1372 27.012 18.885 27.0123C17.6214 27.0123 16.5981 25.9907 16.5981 24.7323C16.5981 23.4796 17.6298 22.4469 18.8838 22.4469C20.1443 22.4469 21.1676 23.4687 21.1676 24.7323Z"
          fill="url(#paint0_linear_364_2266)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_364_2266"
            x1="-42.4586"
            y1="26.8772"
            x2="-42.4586"
            y2="4.00006"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#04B6F0" />
            <stop offset="1" stopColor="#3ECAF0" />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  );
};
