import Chatpush from './Chatpush.jsx';
import Chategy from './Chategy.jsx';
import Jetapi from './Jetapi.jsx';

const LogoCollection = {
  'chatpush.ru': Chatpush,
  'chategy.io': Chategy,
  'jetapi.io': Jetapi,
};

export default LogoCollection;
