import React from "react";

export default (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12.8425" cy="12.8426" r="12.1574" fill="#868686" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7319 8.16666C16.453 8.16666 17.1467 8.45165 17.657 8.96304C18.1678 9.47334 18.4534 10.1616 18.4534 10.8822V15.1303C18.4534 16.6305 17.2327 17.8458 15.7319 17.8458H10.4197C8.91884 17.8458 7.69873 16.6305 7.69873 15.1303V10.8822C7.69873 9.38193 8.91347 8.16666 10.4197 8.16666H15.7319ZM16.5878 11.6834L16.6308 11.6404C16.7593 11.4845 16.7593 11.2586 16.6249 11.1027C16.5501 11.0225 16.4474 10.9736 16.3404 10.9629C16.2275 10.9569 16.12 10.9951 16.0388 11.0704L13.6141 13.0062C13.3022 13.2649 12.8554 13.2649 12.5387 13.0062L10.1189 11.0704C9.95164 10.9467 9.72041 10.9629 9.58114 11.108C9.43595 11.2532 9.41982 11.4845 9.54296 11.6458L9.61341 11.7157L12.0601 13.6246C12.3612 13.8612 12.7263 13.9903 13.1087 13.9903C13.4899 13.9903 13.8615 13.8612 14.1621 13.6246L16.5878 11.6834Z"
        fill="white"
      />
    </svg>
  );
};
