import React from 'react';
import { Link } from 'react-router-dom';
import { useIntegrationState } from "./State";

export function Welcome() {
  const { state } = useIntegrationState();

  if (state.has_updated_fields) {
    return (
      <div className="integration-choice-v2-main">
        <div className="integration-choice-v2-header">
          <img src="/integrations/yclients/medal.svg" />
          <h2>Остался 1 шаг!</h2>
        </div>
        <div className="integration-choice-v2-body">
          <div className="integration-choice-v2-body__text">
            Вы отредактировали данные и теперь они такие:
            <br />
            <br />
            Email: {state.email_to_confirm}
            <br />
            Телефон: {state.formatted_phone_to_confirm}
            <br />
            <br />
            Для продолжения нажмите «Подключить», или, если снова хотите изменить их перед регистрацией, нажмите «Изменить».
          </div>
          <div className="integration-choice-v2-body__actions">
            <Link className="integration-choice-v2-body__actions-btn integration-choice-v2-body__actions-btn--primary" to="/confirm">
              Подключить
            </Link>
            <Link className="integration-choice-v2-body__actions-btn integration-choice-v2-body__actions-btn--secondary" to='/edit'>
              Изменить
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (state.is_phone_already_taken || state.is_email_already_taken) {
    return (
      <div className="integration-choice-v2-main">
        <div className="integration-choice-v2-header">
          <img src="/integrations/yclients/phone.svg" />
          <h2>Упс, с такими данными уже есть аккаунт!</h2>
        </div>
        <div className="integration-choice-v2-body">
          <div className="integration-choice-v2-body__text">
            Yclients передал нам эти данные:
            <br />
            email: <b>{state.email_to_confirm}</b>
            <br />
            и телефон: <b>{state.formatted_phone_to_confirm}</b>,
            <br />
            но на них уже создан аккаунт у нас в системе.
            <br />
            <br />
            Если хотите войти в существующий аккаунт и привязать к нему ваш Yclients, нажмите «Войти».
            <br />
            <br />
            Если хотите создать новый аккаунт, нажмите «Изменить» и укажите другие контактные данные.
          </div>
          <div className="integration-choice-v2-body__actions">
            <Link className="integration-choice-v2-body__actions-btn integration-choice-v2-body__actions-btn--primary" to='/edit'>
              Изменить
            </Link>
            <a
              className="integration-choice-v2-body__actions-btn integration-choice-v2-body__actions-btn--secondary"
              href={state.sign_in_path}
            >Войти</a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="integration-choice-v2-main">
      <div className="integration-choice-v2-header">
        <img src="/integrations/yclients/hello.svg" />
        <h2>Добро пожаловать!</h2>
      </div>
      <div className="integration-choice-v2-body">
        <div className="integration-choice-v2-body__text">
          Ваш YCLIENTS зарегистрирован на:
          <br />
          Email: <b>{state.email_to_confirm}</b>
          <br />
          Телефон: <b>{state.formatted_phone_to_confirm}</b>
          <br />
          <br />
          Создадим аккаунт Chatpush на эти же данные? Ha email пришлём пароль, а на телефон – код подтверждения.
          <br />
          (Эти данные нужны только для создания аккаунта, с отправкой уведомлений они не связаны).
          <br />
          <br />
          Если вы хотите изменить их – нажмите «Изменить».
          <br />
          Если хотите войти в уже существующий кабинет и привязать к нему ваш Yclients, нажмите «Войти».
        </div>
        <div className="integration-choice-v2-body__actions">
          <Link className="integration-choice-v2-body__actions-btn integration-choice-v2-body__actions-btn--primary" to="/confirm">
            Создать
          </Link>
          <Link className="integration-choice-v2-body__actions-btn integration-choice-v2-body__actions-btn--secondary" to='/edit'>
            Изменить
          </Link>
          <a
            className="integration-choice-v2-body__actions-btn integration-choice-v2-body__actions-btn--secondary"
            href={state.sign_in_path}
          >Войти</a>
        </div>
      </div>
    </div>
  );
}
