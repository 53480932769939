import React, {useContext} from 'react';
import {Input, Row, Col, Typography, Checkbox} from 'antd';
import WarningAlert from './WarningAlert';
import TextPreview from './TextPreview';
import {useNotificationsState} from './State';
import translator from '@webpack/translator';
const t = translator('front_office.views.yclients_notification_settings.');
const {Text} = Typography;
const {TextArea} = Input;

const createFeedbackStep = ({type}) => ({
  title: t(`steps.${type}_feedback`),
  validation: ({body}) => (body || "").length > 0,
  Component: ({isVisible}) => {
    const {state} = useNotificationsState();
    const bot_response_text = state['bot_responses'][`${type}_feedback`]['text'];
    const bot_response_values = state['bot_responses'][`${type}_feedback`]['values'];
    const bot_response_available_values = state['bot_responses'][`${type}_feedback`]['bot_response_available_values'];

    return <Row gutter={[0, 24]} style={{display: isVisible ? 'flex' : 'none'}}>
      <WarningAlert message={
        <span>
          {t(`alerts.disabled_${type}_feedback_start`)}
          <a style={{textDecoration: 'underline'}} target='_blank' href='/whatsappproblems'>
            {t(`alerts.disabled_${type}_feedback_link_text`)}
          </a>
          {t(`alerts.disabled_${type}_feedback_end`)}
        </span>
      } />

      <Col sm={24} xl={11}>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Text strong>{t(`fields.${type}_feedback`)}</Text>
          </Col>
          <Col span={24}>
            <Text>{t(`fields.${type}_feedback_help`)}</Text>
          </Col>
        </Row>
      </Col>
      <Col sm={0} xl={2} />
      <Col sm={24} xl={11}>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Checkbox.Group value={bot_response_values}>
              {bot_response_available_values.map(i => <Checkbox key={i} disabled value={i}>{i}</Checkbox>)}
            </Checkbox.Group>
          </Col>
        </Row>
      </Col>

      <Col sm={24} xl={11}>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Text strong>{t('fields.bot_response')}</Text>
          </Col>
          <Col span={24}>
            <Text>{t('fields.bot_response_help')}</Text>
          </Col>
          <Col span={24}>
            <div className='yclients-notification-settings-create__text-input'>
              <TextArea disabled bordered={false} autoSize value={bot_response_text} style={{padding: 0, flexGrow: 1}} />
            </div>
          </Col>
        </Row>
      </Col>
      <Col sm={0} xl={2} />
      <Col sm={24} xl={11}>
        {/* TODO: preview can be enabled only after https://github.com/PushSMS/pushsms/issues/3214
        <TextPreview text={bot_response_text} />
        */}
      </Col>
    </Row>
  }
});

export default createFeedbackStep;

