import React from "react";

export const Icon = (props) => {
  const { size, color, icon, style: styleArg, ...svgProps } = props;
  let svgExtraProps = {};

  if (size !== undefined) {
    svgExtraProps.width = `${size}px`;
    svgExtraProps.height = `${size}px`;
  } else {
    svgExtraProps.width = "25px";
    svgExtraProps.height = "25px";
  }

  if (color !== undefined) {
    svgExtraProps.style = { color, ...styleArg };
  }
  const IconComp = icon;
  return <IconComp {...svgProps} {...svgExtraProps} />;
};
