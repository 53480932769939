import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_363_3185)">
          <path
            d="M0 15.9988C0 24.8352 7.16242 31.9971 16.0011 31.9971C24.8359 31.9971 32 24.8352 32 15.9988C32 7.16177 24.8359 -7.96714e-05 16.0011 -7.96714e-05C7.16242 -7.96714e-05 0 7.16177 0 15.9988"
            fill="black"
          />
          <path
            d="M4.5 13.9193V15.2186L7.9131 14.8331V13.4268L4.5 13.9193ZM5.58918 15.2977V19.75H6.83875V15.1665L5.58918 15.2977ZM8.37406 13.3603V19.75H9.69075V13.1702L8.37406 13.3603ZM9.93605 13.1347V14.6046L11.8374 14.3898V12.8604L9.93605 13.1347ZM9.93605 15.6987V17.1953L11.1841 17.1254V15.5883L9.93605 15.6987ZM9.93605 18.2742V19.75H11.8374V18.2153L9.93605 18.2742ZM16.7544 12.1485V19.75H18.4805V11.8993L16.7544 12.1485ZM18.8028 11.8527V13.6013L21.3182 13.3172V11.4896L18.8028 11.8527ZM18.8028 14.9163V16.6972L20.4515 16.6047V14.7713L18.8028 14.9163ZM18.8028 17.9989V19.75H21.3182V17.9209L18.8028 17.9989ZM12.3404 12.7877V19.75H13.8403V12.5711L12.3404 12.7877ZM14.1202 18.1445V19.7499H16.2974V18.077L14.1202 18.1445ZM26.9577 11.4036C26.4122 10.8743 25.6123 10.6572 24.7375 10.7863V12.7256C25.0918 12.7054 25.3788 12.8405 25.5067 13.1123C25.7087 13.5409 25.5003 14.184 25.0088 14.7798C24.2501 15.6992 22.2326 17.5811 22.2326 17.5811L25.1288 17.4833C26.8984 15.7079 27.5339 14.6302 27.648 13.2851C27.7079 12.5735 27.4564 11.8879 26.9577 11.4036ZM27.75 19.75V17.7208L21.8756 17.9025M21.8756 17.9024L21.8762 19.7499H27.75M24.3998 10.8298C22.6932 11.2031 21.8552 12.4924 21.9519 14.5963L23.7715 14.4304C23.6886 13.2419 24.0426 12.9599 24.4007 12.8049L24.3998 10.8298Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_363_3185">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  );
};
