import intlTelInput from 'intl-tel-input';

/**
 * @param {string} isoSelector
 * @param {boolean} onlyRussianAllowed
 */
export const makePhoneNumberInput = (isoSelector, onlyRussianAllowed = false) => {
  const phoneField = document.getElementById('phone');

  const countriesSettings = {
    preferredCountries: onlyRussianAllowed ? [] : ['ru', 'kz', 'ua', 'by'],
    onlyCountries: onlyRussianAllowed ? ['ru'] : [],
  };

  const iti = intlTelInput(phoneField, {
    utilsScript: '/intl_tel_input/utils.js',
    separateDialCode: true,
    ...countriesSettings,
  });

  phoneField.addEventListener('change', () => {
    iti.setNumber(phoneField.value);

    const { iso2 } = iti.getSelectedCountryData();
    const countryCodeField = document.getElementById(isoSelector);
    countryCodeField.value = iso2.toUpperCase();
    phoneField.classList.remove('ant-input-status-error')

    if (!iti.isValidNumber()) {
      phoneField.classList.add('ant-input-status-error')
    }
  });

  phoneField.addEventListener('countrychange', () => iti.setNumber(''));
};

export const go_to_step = (action, child) => {
  if (action == 'init') {
    $('section').not('section:nth-of-type(1)').hide();
    $('section').not('section:nth-of-type(1)').css('transform', 'translateX(100px)');
  } else {
    window.scrollTo(0, 0);
    var currentSection = $('section:nth-of-type(' + child + ')');
    currentSection.css('display', 'block');
    currentSection.css('transform', 'translateX(0)');
    currentSection.prevAll('section').css('transform', 'translateX(100px)');
    currentSection.nextAll('section').css('transform', 'translateX(100px)');

    $('section').not(currentSection).hide();
  }
};

export const play_firework = () => {
  if (window.innerWidth > 768) {
    $('#firework-desktop').css('display', 'initial');
    setTimeout(() => $('#firework-desktop')[0].play(), 1000);
    setTimeout(() => $('#firework-desktop').css('display', 'none'), 3000);
  } else {
    $('#firework-mob').css('display', 'initial');
    setTimeout(() => $('#firework-mob')[0].play(), 1000);
    setTimeout(() => $('#firework-mob').css('display', 'none'), 3000);
  }
};

export const init_whats_app = (whats_app_enabled, customer_id, api_key) => {
  if (whats_app_enabled == 'true') {
    OnboardingWhatsApp.init_connect(customer_id, api_key);
  }
};

export const select_dispatch_routing = () => {
  $('#dispatch_dispatch_routing').select2({
    dropdownParent: $('.v1_select'),
    language: 'ru',
    allowClear: false,
    width: '100%',
  });
};

export const whats_app_open_modal = (target) => {
  target.preventDefault();
  $('#delivery_info_modal').modal('toggle');
};

export const restart_step_one = () => {
  var url = window.location.href.split('?')[0] + '?wait_approve=true';
  window.location.href = url;
};

export const change_delivery_length_limit = (action) => {
  input = $('#dispatch_delivery_length_limit');
  input_value = parseInt(input.val());

  if (input_value < 0 || (input_value == 0 && action == 'minus')) {
    input.val(0);
  } else {
    action == 'minus' && input.val(input_value - 1);
    action == 'plus' && input.val(input_value + 1);
  }
};
