import React from 'react';
import { Col, Row } from 'antd';
import Persona from '../imgs/partners/Persona';
import Britva from '../imgs/partners/Britva';
import Kistochka from '../imgs/partners/Kistochka';
import Amocrm from '../imgs/partners/Amocrm';
import Topgun from '../imgs/partners/Topgun';

import translator from '@webpack/translator';
const t = translator('main_page.elements.partners.');

const partners = () => {
  return (
    <div className="container">
      <div className="partners">
        <Row>
          <Col xs={0} md={0} lg={2} xl={4}></Col>
          <Col xs={24} md={24} lg={20} xl={16}>
            <div className="partners-body">
              <div className="partners-body-title">{t('title')}</div>
              <div
                className="partners-body-desc"
                dangerouslySetInnerHTML={{ __html: t('description') }}
              ></div>
              <div className="partners-body-imgs">
                <Row gutter={{ xs: 40, md: 56, lg: 56 }}>
                  <Col xs={12} md={5} lg={5}>
                    <div className="partners-body-imgs-img">
                      <Persona />
                    </div>
                  </Col>
                  <Col xs={12} md={5} lg={5}>
                    <div className="partners-body-imgs-img">
                      <Britva />
                    </div>
                  </Col>
                  <Col xs={0} md={4} lg={4}>
                    <div className="partners-body-imgs-img">
                      <Topgun id={1} />
                    </div>
                  </Col>
                  <Col xs={9} md={5} lg={5}>
                    <div className="partners-body-imgs-img">
                      <Kistochka />
                    </div>
                  </Col>
                  <Col xs={6} md={0} lg={0}>
                    <div className="partners-body-imgs-img">
                      <Topgun id={2} />
                    </div>
                  </Col>
                  <Col xs={9} md={5} lg={5}>
                    <div className="partners-body-imgs-img">
                      <Amocrm />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={0} md={0} lg={2} xl={4}></Col>
        </Row>
      </div>
    </div>
  );
};

export default partners;
