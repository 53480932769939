import React from "react";
import axios from "axios";
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import classNames from "classnames";
import { useIntegrationState } from "./State";

export function Edit() {
  const navigate = useNavigate();
  const { state } = useIntegrationState();

  const formMethods = useForm(
    {
      defaultValues:
      {
        updated_phone: state.phone_to_confirm,
        updated_email: state.email_to_confirm,
      }
    }
  );

  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting }
  } = formMethods;

  const onSubmit = (formData, e) => {
    e.preventDefault();

    if (!isDirty) {
      navigate('/');
      return;
    }

    return axios({
      method: 'PATCH',
      url: window.location.href,
      data: { integration_choice: formData },
      headers: {
        Accept: 'application/javascript',
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
      },
    }).then(({ data }) => {
      if (data.success) {
        location.reload(true);
      } else {
        Object.entries(data.errors).map(([fieldName, errorMessages]) => {
          setError(fieldName, { type: 'manual', message: errorMessages.join(', ') });
        });
      }
    }).catch((_error) => {
      alert('Что-то пошло не так. Попробуйте ещё раз или обратитесь к администратору.');
    });
  };

  const onPhoneChange = (onChange) => (phone) => {
    if (phone.startsWith('+')) {
      onChange(phone);
    } else {
      onChange("+" + phone);
    }
  };

  const phoneError = errors.updated_phone?.message;
  const emailError = errors.updated_email?.message;

  return (
    <div className="integration-choice-v2-main">
      <div className="integration-choice-v2-header">
        <h2>Изменение данных</h2>
      </div>
      <div className="integration-choice-v2-body">
        <div className="integration-choice-v2-body__text">
          Ниже указаны данные, которые мы получили от YClients.
          <br />
          <br />
          Для изменения введите новые данные и нажмите «Сохранить».
        </div>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="integration-choice-v2-body__inputs">
              <div className={classNames("integration-choice-v2-body__inputs-item", { 'integration-choice-v2-body__inputs-item--invalid': !!phoneError })}>
                <label htmlFor="phone">Телефон</label>
                <Controller
                  control={control}
                  name='updated_phone'
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      value={value}
                      onChange={onPhoneChange(onChange)}
                      specialLabel={null}
                      inputProps={{ name: "updated_phone", id: "updated_phone", className: null }}
                      country="ru"
                      onlyCountries={['ru']}
                      preferredCountries={['ru']}
                      disableDropdown={true}
                      countryCodeEditable={false}
                      buttonStyle={{ display: "none" }}
                    />
                  )}
                />
                {phoneError &&
                  <div className="integration-choice-v2-body__inputs-error">
                    {phoneError}
                  </div>}
              </div>
              <div className={classNames("integration-choice-v2-body__inputs-item", { 'integration-choice-v2-body__inputs-item--invalid': !!emailError })}>
                <label htmlFor="updated_email">Email</label>
                <input
                  id="updated_email"
                  autoComplete="email"
                  type="text"
                  placeholder="Email"
                  {...register('updated_email')}
                />
                {emailError &&
                  <div className="integration-choice-v2-body__inputs-error">
                    {emailError}
                  </div>}
              </div>
            </div>
            <div className="integration-choice-v2-body__actions">
              <button
                disabled={isSubmitting}
                className="integration-choice-v2-body__actions-btn integration-choice-v2-body__actions-btn--primary"
                type='submit'
              >
                Сохранить
              </button>
              <button
                disabled={isSubmitting}
                className="integration-choice-v2-body__actions-btn integration-choice-v2-body__actions-btn--secondary"
                type="button"
                onClick={() => navigate('/')}
              >
                Назад
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
