import React, { useState, useEffect } from 'react';
import { Col, Row, Collapse } from 'antd';
import Logo from '../imgs/Logo';
import {
  OneC,
  Amocrm,
  Beautybox,
  Bitrix,
  Mindbox,
  Yclients,
  Opencart,
  Webasyst,
} from '../imgs/header/integrations';
import { Ok, Sms, Telegram, TelegramBot, Vk, WhatsApp } from '../imgs/header/services';

const { Panel } = Collapse;

import translator from '@webpack/translator';
const t = translator('main_page.elements.header.');

const Header = () => {
  const [activeSubNav, setActiveSubNav] = useState(false);
  const [desktopSubNav, changeDesktopSubNav] = useState('none');

  useEffect(() => {
    document.body.style.overflow = activeSubNav ? 'hidden' : 'unset';
  }, [activeSubNav]);

  const subnav = {
    services: [
      {
        icon: <WhatsApp />,
        href: '/channels/whatsapp',
        title: t('services.whatsapp.title'),
        desc: t('services.whatsapp.desc'),
      },
      {
        icon: <Telegram />,
        href: '/channels/telegram',
        title: t('services.telegram.title'),
        desc: t('services.telegram.desc'),
      },
      {
        icon: <TelegramBot />,
        href: '/channels/telegrambot',
        title: t('services.telegram_bot.title'),
        desc: t('services.telegram_bot.desc'),
      },
      {
        icon: <Sms />,
        href: '/channels/sms',
        title: t('services.sms.title'),
        desc: t('services.sms.desc'),
      },
      {
        icon: <Vk />,
        href: '/channels/vk',
        title: t('services.vk.title'),
        desc: t('services.vk.desc'),
      },
      {
        icon: <Ok />,
        href: '/channels/ok',
        title: t('services.ok.title'),
        desc: t('services.ok.desc'),
      },
    ],
    integrations: [
      {
        icon: <Yclients />,
        href: '/integrations/yclients',
        title: t('integrations.yclients.title'),
        desc: t('integrations.yclients.desc'),
      },
      {
        icon: <OneC />,
        href: '/integrations/1c',
        title: t('integrations.one_c.title'),
        desc: t('integrations.one_c.desc'),
      },
      {
        icon: <Bitrix />,
        href: '/integrations/bitrix24',
        title: t('integrations.bitrix24.title'),
        desc: t('integrations.bitrix24.desc'),
      },
      {
        icon: <Amocrm />,
        href: '/integrations/amocrm',
        title: t('integrations.amocrm.title'),
        desc: t('integrations.amocrm.desc'),
      },
      {
        icon: <Webasyst />,
        href: '/integrations/webasyst',
        title: t('integrations.webasyst.title'),
        desc: t('integrations.webasyst.desc'),
      },
      {
        icon: <Opencart />,
        href: '/integrations/opencart',
        title: t('integrations.opencart.title'),
        desc: t('integrations.opencart.desc'),
      },
      {
        icon: <Beautybox />,
        href: '/integrations/beatybox',
        title: t('integrations.beatybox.title'),
        desc: t('integrations.beatybox.desc'),
      },
      {
        icon: <Mindbox />,
        href: '/integrations/mindbox',
        title: t('integrations.mindbox.title'),
        desc: t('integrations.mindbox.desc'),
      },
    ],
    none: [],
  };

  const openSubNav = (section) => {
    if (activeSubNav) {
      () => (document.body.style.overflow = 'hidden');
    } else {
      () => (document.body.style.overflow = 'unset');
    }

    if (section != desktopSubNav && activeSubNav) {
      changeDesktopSubNav(section);
    } else {
      setActiveSubNav(!activeSubNav);
      changeDesktopSubNav(section);
    }
  };

  return (
    <>
      <header>
        <div className="navbar">
          <div className="container">
            <Row>
              <Col xs={4}>
                <div className="navbar-logo">
                  <a href="/">
                    <Logo />
                  </a>
                </div>
              </Col>
              <Col xs={0} md={0} lg={16}>
                <ul className="navbar-nav">
                  <li className="navbar-nav-item" onClick={() => openSubNav('services')}>
                    {t('services_title')}
                  </li>
                  <li
                    className="navbar-nav-item"
                    onClick={() => {
                      openSubNav('integrations');
                    }}
                  >
                    {t('integrations_title')}
                  </li>
                  <li className="navbar-nav-item">
                    <a href={t('api_href')}>{t('api_title')}</a>
                  </li>
                  <li className="navbar-nav-item">
                    <a href="#tariffs">{t('tariffs_title')}</a>
                  </li>
                  <li className="navbar-nav-item">
                    <a href="/blog">{t('blog_title')}</a>
                  </li>
                  <li className="navbar-nav-item">
                    <a href="/contacts">{t('contacts_title')}</a>
                  </li>
                </ul>
              </Col>
              <Col xs={0} md={0} lg={4}>
                <div className="navbar-actions">
                  <a className="navbar-actions-nav-link" href="/login">
                    {t('login_title')}
                  </a>
                  <a className="button button-primary" href="/customers/sign_up">
                    {t('registration_title')}
                  </a>
                </div>
              </Col>
              <Col xs={18} md={18} lg={0}></Col>
              <Col xs={2} md={2} lg={0}>
                <div className="navbar-mobile-actions">
                  <div
                    className={`navbar-mobile-actions-hum ${(activeSubNav && 'open') || 'close'}`}
                    onClick={() => setActiveSubNav(!activeSubNav)}
                  >
                    <div className="hum-line"></div>
                    <div className="hum-line"></div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className={`subnav ${(activeSubNav && 'open') || 'close'}`}>
          <div className="container">
            <Row>
              <Col xs={0} lg={2} xl={4}></Col>
              <Col xs={0} lg={20} xl={16}>
                <Row gutter={[20, 20]}>
                  {subnav[desktopSubNav].map((subnav_item) => (
                    <Col key={`${subnav_item.href}${subnav_item.title}`} lg={6}>
                      <a href={subnav_item.href} className="subnav-box">
                        <div className="subnav-box-content">
                          <div className="subnav-box-content-icon">{subnav_item.icon}</div>
                          <div className="subnav-box-content-title">{subnav_item.title}</div>
                          <div className="subnav-box-content-desc">{subnav_item.desc}</div>
                        </div>
                      </a>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col xs={0} lg={2} xl={4}></Col>
              <Col xs={24} md={24} lg={0}>
                <div className="subnav-nav">
                  <div className="subnav-nav-item">
                    <div className="subnav-nav-actions">
                      <a className="button button-common" href="/login">
                        {t('login_title')}
                      </a>
                      <a className="button button-primary" href="/customers/sign_up">
                        {t('registration_title')}
                      </a>
                    </div>
                  </div>
                  <Collapse expandIconPosition="end">
                    <Panel key={'Услуги'} header={t('services_title')}>
                      <div>
                        {subnav['services'].map(({ icon, title, href }) => (
                          <a key={`${href}_serv`} href={href} className="subnav-box">
                            <div className="subnav-box-icon">{icon}</div>
                            <div className="subnav-box-title">{title}</div>
                          </a>
                        ))}
                      </div>
                    </Panel>
                    <Panel key={'Интеграции'} header={t('integrations_title')}>
                      <div>
                        {subnav['integrations'].map(({ icon, title, href }) => (
                          <div key={`${href}_int`} href={href} className="subnav-box">
                            <div className="subnav-box-icon">{icon}</div>
                            <div className="subnav-box-title">{title}</div>
                          </div>
                        ))}
                      </div>
                    </Panel>
                  </Collapse>
                  <div className="subnav-nav-item">
                    <a href={t('api_href')}>{t('api_title')}</a>
                  </div>
                  <div className="subnav-nav-item">
                    <a href="#tariffs" onClick={() => setActiveSubNav(!activeSubNav)}>
                      {t('tariffs_title')}
                    </a>
                  </div>
                  <div className="subnav-nav-item">
                    <a href="/blog">{t('blog_title')}</a>
                  </div>
                  <div className="subnav-nav-item">
                    <a href="/contacts">{t('contacts_title')}</a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </header>
      <div
        className={`fader ${(activeSubNav && 'active') || <WhatsApp />}`}
        onClick={() => setActiveSubNav(!activeSubNav)}
      ></div>
    </>
  );
};

export default Header;
