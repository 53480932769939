import React from 'react';

export default (props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon / DownOutlined">
        <path
          d="M12.0859 3.5H11.0605C10.9907 3.5 10.9251 3.53418 10.8841 3.59023L6.99993 8.94414L3.11575 3.59023C3.07473 3.53418 3.00911 3.5 2.93938 3.5H1.91399C1.82512 3.5 1.77317 3.60117 1.82512 3.67363L6.64583 10.3195C6.82083 10.5602 7.17903 10.5602 7.35266 10.3195L12.1734 3.67363C12.2267 3.60117 12.1747 3.5 12.0859 3.5Z"
          fill="#161615"
        />
      </g>
    </svg>
  );
};
