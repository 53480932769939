import React from 'react';

export default (props) => {
  return (
    <svg width="35" height="44" viewBox="0 0 35 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9668 31.5366C23.3567 35.1008 26.8174 38.5943 30.3488 42.017"
        stroke="#161615"
        strokeWidth="2.16313"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.52207 31.9221C3.39648 30.3066 1.73692 26.4696 2.03465 22.7207C2.29356 19.5233 5.29942 15.2928 8.4399 14.3608C10.405 13.7782 12.7118 13.3018 14.7545 13.4261C17.8614 13.6151 19.8005 15.3834 21.2789 18.032C23.5417 22.089 23.6608 26.9512 20.4633 30.6017C18.2756 33.1001 15.9196 33.9519 12.6186 34.2988C11.0263 34.4645 7.66061 33.5377 5.52207 31.9221Z"
        fill="white"
        stroke="#161615"
        strokeWidth="2.16313"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.80469 21.978C6.09994 21.0255 6.5872 20.1435 7.23642 19.3865C7.88564 18.6296 8.68306 18.0136 9.57949 17.5767"
        stroke="#161615"
        strokeWidth="2.16313"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2715 16.7999L13.0042 16.541"
        stroke="#161615"
        strokeWidth="2.16313"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2832 6.64315L21.2742 1.98291"
        stroke="#161615"
        strokeWidth="2.16313"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9824 11.4147L30.8116 8.85938"
        stroke="#161615"
        strokeWidth="2.16313"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7432 20.414L33.0002 20.3804"
        stroke="#161615"
        strokeWidth="2.16313"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
