import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.1709 1.07185C27.4322 1.07327 28.6414 1.57493 29.5332 2.46677C30.4251 3.35862 30.9267 4.5678 30.9282 5.82906V26.1709C30.9267 27.4322 30.4251 28.6414 29.5332 29.5332C28.6414 30.4251 27.4322 30.9267 26.1709 30.9282H5.82906C4.5678 30.9267 3.35862 30.4251 2.46677 29.5332C1.57493 28.6414 1.07327 27.4322 1.07185 26.1709V5.82906C1.07327 4.5678 1.57493 3.35862 2.46677 2.46677C3.35862 1.57493 4.5678 1.07327 5.82906 1.07185H26.1709ZM26.1709 0H5.82906C4.2831 0 2.80045 0.614132 1.70729 1.70729C0.614132 2.80045 0 4.2831 0 5.82906V26.1709C0 27.7169 0.614132 29.1995 1.70729 30.2927C2.80045 31.3858 4.2831 32 5.82906 32H26.1709C27.7169 32 29.1995 31.3858 30.2927 30.2927C31.3858 29.1995 32 27.7169 32 26.1709V5.82906C32 4.2831 31.3858 2.80045 30.2927 1.70729C29.1995 0.614132 27.7169 0 26.1709 0Z"
          fill="#020202"
        />
        <path
          d="M25.7997 8.79005C25.9596 8.54263 25.8497 8.34344 25.555 8.34344H18.6773C18.5152 8.35232 18.357 8.39697 18.2142 8.47417C18.0713 8.55139 17.9474 8.65926 17.8511 8.79005L10.1588 20.6929C10.08 20.8302 10.0381 20.9854 10.0371 21.1437C10.0362 21.302 10.0762 21.4578 10.1534 21.5959L13.5226 26.9311C13.6798 27.1803 13.9397 27.1812 14.0996 26.9311L25.7997 8.79005Z"
          fill="black"
        />
        <path
          d="M15.1196 8.33987C15.4144 8.33987 15.5252 8.54263 15.3653 8.78648L11.2244 15.2095C11.0654 15.4569 10.8055 15.456 10.6483 15.2095L6.59937 8.79273C6.44216 8.54353 6.55471 8.33987 6.84947 8.33987H15.1196Z"
          fill="black"
        />
      </svg>
    </SVGUniqueID>
  );
};
