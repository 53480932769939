import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useLocation, Navigate, Outlet} from "react-router-dom";
import {Table, Select, Row, Col, Space, Modal, Breadcrumb, Grid} from 'antd';
import DispatchRouting from '../../components/DispatchRouting';
import VanillaAntd from '../../components/VanillaAntd';
import LockUnlockedIcon from '../../Icons/LockUnlockedIcon';
import SettingsIcon from '../../Icons/SettingsIcon';
import TableActions from './TableActions';
import {tableData, useNotificationsState} from './State';
import translator from '@webpack/translator';
const t = translator('front_office.views.yclients_notification_settings.');

const NotificationsTable = () => {
  const {state, updateState} = useNotificationsState();
  const {salons} = state;
  const [salonOptions, _setSalonOptions] = useState(salons);
  const [dataToDisplay, setDataToDisplay] = useState(tableData(state.action_types, [], state.selectedSalon));
  const [openedUpsaleModal, setOpenedUpsaleModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [limitModal, limitModalPlaceholder] = Modal.useModal();

  const fetchTableData = () => {
    axios(
      '/front_office/yclients_notification_settings',
      {headers: {'Accept': 'application/json'}}
    ).then(({data}) => {
      if (data.success) {
        updateState({notifications: data.notifications});
      } else {
        updateState({notifications: []});
      }
    }).catch((_error) => {
      updateState({notifications: []});
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    fetchTableData();
  }, []);

  const openUpsaleModal = (action_type, data) => {
    setModalData({action_type, ...data});
    setOpenedUpsaleModal(true);
  };

  const closeUpsaleModal = () => {
    setOpenedUpsaleModal(false);
    setModalData({});
  };

  useEffect(() => {
    setDataToDisplay(tableData(state.action_types, state.notifications || [], state.selectedSalon));
  }, [state.selectedSalon, state.notifications]);

  const UnavailableIcon = ({modal_data}) =>
    modal_data["modal_type"] == 'need_bot_setup' ? <SettingsIcon /> : <LockUnlockedIcon />;

  const screens = Grid.useBreakpoint();

  const columns = [
    {
      title: t('notification_type'),
      dataIndex: 'action_type_name',
      width: screens.md ? '25%' : '100%',
      render: (_, record) => {
        const {action_type_name, modal_data, dispatch_routing} = record;

        return (
          <div className='yclients-notification-settings-table__primary-col'>
            <span className='yclients-notification-settings-table__action-type'>
              <span className='yclients-notification-settings-table__action-type-name'>
                {action_type_name}
              </span>
              {modal_data &&
                <span className='yclients-notification-settings-table__not-available'>
                  <UnavailableIcon modal_data={modal_data} />
                </span>}
            </span>
            {
              screens.md ? null : dispatch_routing
                ?
                <>
                  <DispatchRouting routing={dispatch_routing} />
                  {record.visit_time_offset_label}
                  <TableActions
                    limitModal={limitModal}
                    openUpsaleModal={openUpsaleModal}
                    {...record}
                  />
                </>

                :
                <TableActions
                  limitModal={limitModal}
                  openUpsaleModal={openUpsaleModal}
                  {...record}
                />
            }
          </div>
        );
      },
    },
    {
      responsive: ['md'],
      title: t('dispatch_routing'),
      dataIndex: 'dispatch_routing',
      width: '25%',
      render: (_, {dispatch_routing}) => {
        if (dispatch_routing) {
          return <DispatchRouting routing={dispatch_routing} />;
        } else {
          return null;
        }
      },
    },
    {
      responsive: ['md'],
      title: t('sending_time'),
      dataIndex: 'visit_time_offset_label',
      width: '25%',
    },
    {
      responsive: ['md'],
      title: t('actions'),
      dataIndex: 'actions',
      width: '25%',
      render: (_, record) => <TableActions
        limitModal={limitModal}
        openUpsaleModal={openUpsaleModal}
        {...record}
      />
    },
  ];

  const expandedRowRender = ({items}) => {
    return <Table
      className='ant-custom-table'
      rowKey="id"
      onRow={() => ({style: {backgroundColor: '#FAFAFA'}})}
      onCell={() => ({style: {backgroundColor: '#FAFAFA'}})}
      onHeaderRow={() => ({style: {visibility: 'collapse'}})}
      showHeader={true}
      columns={columns}
      dataSource={items}
      pagination={false} />;
  };

  const location = useLocation();

  // ensure root hash is present
  if (location.pathname + location.search + location.hash === "/" && window.location.hash === "") {
    return <Navigate to="" replace={true} />;
  }

  return (
    <>
      <Breadcrumb className='yclients-notification-settings-breadcrumb'>
        <Breadcrumb.Item>{t("breadcrumb.yclients")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("breadcrumb.notifications")}</Breadcrumb.Item>
      </Breadcrumb>
      <Outlet />
      <VanillaAntd>
        {limitModalPlaceholder}
        <Modal
          title={
            <span>
              <span className='yclients-notification-settings-upsale-modal__title-icon'>
                <UnavailableIcon modal_data={modalData} />
              </span>
              <span className='yclients-notification-settings-upsale-modal__title-text'>
                {modalData["title"]}
              </span>
            </span>
          }
          centered
          open={openedUpsaleModal}
          okText={modalData["modal_type"] == 'need_bot_setup' ? t('upsale_modal.ok_bot') : t('upsale_modal.ok')}
          okButtonProps={{className: 'ant-custom-btn', href: modalData["href"], target: "_blank"}}
          onCancel={closeUpsaleModal}
          cancelButtonProps={{className: 'ant-custom-btn'}}
          cancelText={t('upsale_modal.cancel')}
          width={520}
          wrapClassName='ant-custom-modal'
        >
          {!!modalData["header"] && !!modalData["description"] &&
            <div className='yclients-notification-settings-upsale-modal__inner-block'>
              <div className='yclients-notification-settings-upsale-modal__inner-block_header'>
                {modalData["header"]}
              </div>
              <div className='yclients-notification-settings-upsale-modal__inner-block_description'>
                {modalData["description"]}
              </div>
            </div>}
          <div className='yclients-notification-settings-upsale-modal__footer'>{modalData["footer"]}</div>
        </Modal>
      </VanillaAntd>
      <Space direction='vertical' size={24} style={{width: "100%"}}>
        <Row>
          <Col span={24}>
            <Select
              className='ant-custom-select'
              style={{width: "100%", maxWidth: 420}}
              value={state.selectedSalon}
              popupClassName='ant-custom-select-dropdown'
              options={salonOptions}
              onChange={(value) => {
                updateState({selectedSalon: value});
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              loading={isLoading}
              className='ant-custom-table'
              scroll={{x: true}}
              expandable={{
                expandedRowRender,
                defaultExpandAllRows: true,
                expandRowByClick: true,
                rowExpandable: ({items}) => (items || []).length > 0,
              }}
              rowKey="action_type"
              columns={columns}
              dataSource={dataToDisplay}
              showHeader={screens.md}
              pagination={false} />
          </Col>
        </Row>
      </Space>
    </>
  );
}

export default NotificationsTable;
