import React from 'react';
import Canceled from './Canceled';
import Success from './Success';

const End = ({ has_payment_status, edit_customer_url, endImg, textElements}) => {
  if (has_payment_status) {
    return <Success
    endImg={endImg}
    textElements={textElements}/>;
  }
  return <Canceled edit_customer_url={edit_customer_url} />;
};

export default End;
