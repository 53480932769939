import React from 'react';
import { Row, Cell } from '@components/grid';
import { InfoPane } from '../InfoPane';
import Button from '@components/Button';

import translator from '@webpack/translator';
const t = translator('front_office.views.integrations.telegram_bot.question_view.');

interface Props {
  showSiteLinkForm: React.Dispatch<React.SetStateAction<boolean>>;
  moveToLastStep: (arg: string) => void;
}
export default ({ showSiteLinkForm, moveToLastStep }: Props) => {
  return (
    <div className="telegram-flow_split-pane_right">
      <Row>
        <Cell>
          <InfoPane title={t('page_link_title')}>
            <p>{t('page_link_question')}</p>
          </InfoPane>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <div className="telegram-flow_footer">
            <Button className="btn_v2-secondary" text={t('no_button')} onClick={moveToLastStep} />
            <Button className="btn_v2-primary" text={t('yes_button')} onClick={showSiteLinkForm} />
          </div>
        </Cell>
      </Row>
    </div>
  );
};
