import React, { useState } from 'react';
import { MemoryRouter, Routes, Route } from "react-router-dom";
import { Welcome } from './Welcome';
import { Edit } from './Edit';
import { Confirm } from './Confirm';
import { StateContext } from './State';

export default function IndexView(props) {
  const [state, setState] = useState(props);

  const updateState = (newState) => {
    setState(state => ({ ...state, ...newState }));
  };

  return (
    <StateContext.Provider value={{ state, updateState }}>
      <MemoryRouter>
        <Routes>
          <Route
            path='/'
            element={<Welcome />}
          />
          <Route
            path='/edit'
            element={<Edit />}
          />
          <Route
            path='/confirm'
            element={<Confirm />}
          />
        </Routes>
      </MemoryRouter>
    </StateContext.Provider>
  );
};
