import React from 'react';
import {Row} from 'antd';
import WhatsappIcon from '../Icons/WhatsappIcon';
import TelegramIcon from '../Icons/TelegramIcon';
import TelegramBotIcon from '../Icons/TelegramBotIcon';
import VkOkIcon from '../Icons/VkOkIcon';
import SmsIcon from '../Icons/SmsIcon';

const routingToIcon = (routing) => {
  switch (routing) {
    case 'whatsapp':
      return <WhatsappIcon width={20} height={20} />;
    case 'tdlib':
      return <TelegramIcon width={20} height={20} />
    case 'telegram':
      return <TelegramBotIcon width={20} height={20} />
    case 'sms':
      return <SmsIcon width={20} height={20} />
    case 'notify':
      return <VkOkIcon width={20} height={20} />
    default:
      return null;
  }
};

const DispatchRouting = ({routing}) => {
  if (!routing) return null;

  return (
    <Row wrap={false}>
      {routing.map((routing, i) =>
        <div style={{left: -6 * i, zIndex: 10 - i}} className='dispatch-routing-i' key={routing}>
          {routingToIcon(routing)}
        </div>)}
    </Row>
  );
};

export default DispatchRouting;
