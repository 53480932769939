import React, {useState} from 'react';
import {Button, Modal} from 'antd';
import {List, arrayMove} from 'react-movable';
import ChannelInfo from './ChannelInfo';
import {JsxElement} from 'typescript';

import translator from '@webpack/translator';
const t = translator('front_office.views.integrations.selector_view.');

interface Props {
  disabled?: boolean,
  channels: [string, boolean][];
  setChannelsOrder: any;
  title?: string;
  setRouting?: any;
  hasTelegramBot?: boolean;
  hasTdLib?: boolean;
  editTelegramBotsUrl: string;
  editTdLibSessionsUrl: string;
}
export default ({
  disabled,
  channels,
  setChannelsOrder,
  title,
  setRouting,
  hasTelegramBot,
  hasTdLib = false,
  editTelegramBotsUrl,
  editTdLibSessionsUrl,
}: Props) => {
  const [modalMessage, setMessage] = useState<React.SetStateAction<JsxElement>>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const buildModal = (link, destination) => {
    setMessage(
      <span className="help-block" dangerouslySetInnerHTML={{__html: t("auth_firstly", {link: link, destination: t(destination)})}}></span>,
    );
    showModal();
  };

  const buildSmsModal = () => {
    setMessage(
      <span className="help-block">{t("sms_alert")}</span>,
    );
    showModal();
  };

  const handleSwitch = (switched: boolean, checkedChannel: string) => {
    const updatedItems: [string, boolean][] = channels.map((item) => {
      const [channelName] = item;

      if (checkedChannel === 'telegram' && !hasTelegramBot && !switched) {
        buildModal(editTelegramBotsUrl, 'telegram');
      } else if (checkedChannel === 'tdlib' && !hasTdLib && !switched) {
        buildModal(editTdLibSessionsUrl, 'tdlib');
      } else if (checkedChannel === 'sms' && !switched && channelName === checkedChannel) {
        buildSmsModal();
        return [channelName, !switched];
      } else if (channelName === checkedChannel) {
        return [channelName, !switched];
      }
      return item;
    });
    setChannelsOrder(updatedItems);
  };

  const updateChannels = () => {
    const selectedChannels = channels.filter(([, isActive]) => isActive);
    if (selectedChannels.length > 0) {
      setRouting();
    } else {
      setMessage(t("choose_routing"));
      showModal();
    }
  };

  return (
    <>
      <div className='selector-view'>
        <List
          lockVertically
          values={channels}
          onChange={({oldIndex, newIndex}) =>
            setChannelsOrder(arrayMove(channels, oldIndex, newIndex))
          }
          renderList={({children, props, isDragged}) => (
            <>
              {title && <h5 style={{color: 'black', padding: '12px 0'}}>{title}</h5>}
              <ul {...props} style={{margin: 0, padding: 0, cursor: isDragged ? 'grabbing' : undefined}}>
                {children}
              </ul>
              {setRouting && (
                <button style={{marginTop: 16}} className="btn btn-success" onClick={updateChannels}>
                  {t("save")}
                </button>
              )}
            </>
          )}
          renderItem={({value, props, isDragged, isSelected, index}) => (
            <li
              {...props}
              className="selector-view__item"
              style={{
                ...props.style,
                marginBottom: index === channels.length - 1 ? 0 : '8px',
                cursor: isDragged ? 'grabbing' : 'grab',
                backgroundColor: isDragged || isSelected ? '#F4F4F4' : '#FFF',
              }}
            >
              <ChannelInfo disabled={disabled} channel={value} handleSwitch={handleSwitch} />
            </li>
          )}
        />
      </div>

      <Modal
        open={isModalOpen}
        title={t("notify")}
        onCancel={handleCancel}
        footer={<Button onClick={handleCancel}>{t("ok")}</Button>}
      >
        <p>{modalMessage}</p>
      </Modal>
    </>
  );
};
