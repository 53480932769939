import React, { useState } from 'react';
import { Row, Col, Form, Input } from 'antd';
import Button from '@components/Button';
import classNames from 'classnames';

import translator from '@webpack/translator';
const t = translator('front_office.views.integrations.telegram_tdlib.password_form.');

export const PasswordForm = ({ setStep, forwardStep, backwardStep, setCustomerHasTdLib }) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [inputStatus, setInputStatus] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const onFinish = ({ password }) => {
    sendPassword(password);
    setInputStatus({
      validateStatus: 'validating',
      help: t('sending_password'),
    });
  };

  const setError = (errorMessage) => {
    setInputStatus({
      validateStatus: 'error',
      help: errorMessage,
    });
  };

  const handleInputChange = ({ target }) => {
    setButtonDisabled(target.value ? false : true);
    setInputStatus({});
  };

  const sendPassword = (password: string) => {
    setFormDisabled(true);
    setButtonDisabled(true);
    const tdlibData = new FormData();
    tdlibData.append('tdlib_session[password]', password);

    const queryOptions = {
      method: 'PATCH',
      headers: {
        Accept: 'text/javascript',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      body: tdlibData,
    };

    const requestServer = async (url, options = {}) => {
      const response = await fetch(url, options);
      const body = await response.json();
      setFormDisabled(false);
      setButtonDisabled(false);
      return body;
    };

    const handleResponse = (body) => {
      if (body.success) {
        const handleStatus = {
          authorized: () => { setCustomerHasTdLib(true); setStep(forwardStep); },
          wait_password: () => setError(t('check_the_password')),
        };
        handleStatus[body.success]();
      } else {
        console.error(t('server_error'));
      }
    };

    requestServer('/front_office/send_password', queryOptions)
      .then((body) => handleResponse(body))
      .catch((error) => {
        console.error(t('server_error'), error);
        setError(t('server_error'));
      });
  };

  return (
    <div className="telegram-flow_split-pane_right">
      <Row justify="center">
        <Col>
          <p className="telegram-flow_sub-title">{t('sub_title')}</p>
        </Col>
      </Row>
      <Form onFinish={onFinish} disabled={formDisabled}>
        <Row>
          <Col>
            <p className="telegram-flow_text">{t('password')}</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className="telegram-flow_bottom-spacing" name="password" {...inputStatus}>
              <Input.Password
                autoFocus
                className="telegram-flow_input"
                onChange={(event) => handleInputChange(event)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <div className="telegram-flow_footer">
                <Button htmlType='button' className="btn_v2-secondary" onClick={() => setStep(backwardStep)}>
                  {t('back_button')}
                </Button>
                <Button
                  htmlType='submit'
                  type='primary'
                  className={classNames('btn_v2', {'btn_v2-primary_disabled': buttonDisabled})}
                  disabled={buttonDisabled}
                >
                  {t('next_button')}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
