import React, {useState} from 'react';
import {HashRouter, Routes, Route} from "react-router-dom";
import NotificationsTable from './NotificationsTable';
import Form from './Form';
import ShowDrawer from './ShowDrawer';
import {StateContext} from './State';

const IndexView = (props) => {
  const [state, setState] = useState({
    selectedSalon: props.salons[0].value,
    ...props
  });

  const updateState = (newState) => {
    setState(state => ({...state, ...newState}));
  };

  return (
    <StateContext.Provider value={{state, updateState}}>
      <HashRouter>
        <Routes>
          <Route
            path='/'
            element={<NotificationsTable />}
          >
            <Route
              path=':action_type/:id'
              element={<ShowDrawer />}
            />
          </Route>
          <Route
            path=':salon_id/:action_type/new'
            element={<Form />}
          />
          <Route
            path=':action_type/:id/edit'
            element={<Form isEditMode />}
          />
        </Routes>
      </HashRouter>
    </StateContext.Provider>
  );
}

export default IndexView;
