import React from "react"
import {ConfigProvider} from 'antd';

const VanillaAntd = ({children}) => {
  return (
    <ConfigProvider prefixCls="vanilla-ant-d ant">
      {children}
    </ConfigProvider>
  )
}

export default VanillaAntd;
