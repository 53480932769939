import React, { useState } from 'react';
import { Container, Row, Cell } from '@components/grid';
import Radio from '@front_office/components/payment/Radio';
import Input from '@components/form/Input';
import Button from '@components/Button';
import { useForm } from 'react-hook-form';

import translator from '@webpack/translator';
const t = translator('front_office.views.payments.variants.sms.');

const Sms = ({ setStep, sms_payment_list, setPaymentSum, smsImg, setPaymentObject }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const payment_sum_validate = register('payment_sum', {
    required: true,
    valueAsNumber: true,
    min: 1,
  });

  const payment_radio = sms_payment_list.slice(0).map((opt) => (
    <Radio
      key={opt.sum}
      title={t('payment_radio_title', { days: opt.days })}
      label={
        opt.discount_percent &&
        t('payment_radio_discount', { discount_percent: opt.discount_percent })
      }
      name={'payment_radio_sum'}
      value={opt.sum}
      onClick={() => setValue('payment_sum', opt.sum)}
      content={
        <div>
          {opt.sms_payment_desc_title && <div>{opt.sms_payment_desc_title}</div>}
          <div>
            {t('payment_radio_label')}{' '}
            {opt.discount_percent && <span className="full_price">{opt.sum_without_discount}</span>}{' '}
            <b className="actual_price">{opt.sum}</b>
          </div>
        </div>
      }
    />
  ));

  const onSubmit = (data) => {
    if ($.isEmptyObject(errors)) {
      setPaymentSum(data.payment_sum);
      setPaymentObject("sms")
      setStep('payment_method');
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Cell md={3} lg={3}></Cell>
          <Cell md={6} lg={6}>
            <div className="payment-header">
              <div>
                <h3>{t('page_title')}</h3>
                <h5>{t('page_subtitle')}</h5>
              </div>
              <div className="img_box">
                <img src={smsImg} />
              </div>
            </div>
          </Cell>
          <Cell md={3} lg={3}></Cell>
        </Row>
        <Row>
          <Cell md={3} lg={3}></Cell>
          <Cell md={6} lg={6}>
            <div className="payment-body">
              <div className="errors_box">
                {errors.payment_sum && <span>{t('errors_payment_sum')}</span>}
              </div>
              {payment_radio}
              <Radio
                title={t('payment_radio_manual_title')}
                className={'with_accordion'}
                name={'payment_radio_sum'}
                accordion={true}
                value={0}
                onClick={() => setValue('payment_sum', 0)}
                content={
                  <Input type={'number'} onChange={(value) => setValue('payment_sum', value)} />
                }
              />
              <input type="hidden" name="payment_sum" {...payment_sum_validate} />
            </div>
          </Cell>
          <Cell md={3} lg={3}></Cell>
        </Row>
        <Row>
          <Cell md={3} lg={3}></Cell>
          <Cell md={6} lg={6}>
            <div className="payment-footer">
              <Button
                className={'btn_v2-secondary'}
                text={t('button_back')}
                onClick={() => setStep('start')}
              />
              <Button className={'btn_v2-primary'} text={t('button_next')} />
            </div>
          </Cell>
          <Cell md={3} lg={3}></Cell>
        </Row>
      </form>
    </Container>
  );
};

export default Sms;
