import React from 'react';

export default (props) => {
  return (
    <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.333313" width="36" height="36" rx="8" fill="#FFE8AD" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.9786 13.0602C21.669 10.4149 17.7391 9.68247 14.583 11.5046C12.4246 12.7508 11.1193 14.9071 10.8738 17.2128C10.8154 17.762 10.3228 18.1598 9.7736 18.1014C9.22442 18.0429 8.82661 17.5503 8.88506 17.0011C9.19524 14.0871 10.8475 11.3519 13.583 9.77257C17.7119 7.38879 22.8863 8.45656 25.7652 12.0806L25.8739 11.6752C26.0168 11.1417 26.5652 10.8251 27.0986 10.9681C27.6321 11.111 27.9487 11.6593 27.8057 12.1928L27.0737 14.9249C27.005 15.181 26.8374 15.3995 26.6078 15.5321C26.3781 15.6647 26.1051 15.7006 25.8489 15.632L23.1169 14.8999C22.5834 14.757 22.2668 14.2086 22.4098 13.6752C22.5527 13.1417 23.1011 12.8251 23.6345 12.9681L23.9786 13.0602ZM26.8921 17.8986C27.4413 17.9571 27.8391 18.4497 27.7807 18.9989C27.4705 21.9129 25.8182 24.6481 23.0827 26.2274C18.9541 28.6111 13.7799 27.5436 10.9009 23.9199L10.7924 24.3249C10.6495 24.8583 10.1011 25.1749 9.56766 25.032C9.0342 24.889 8.71761 24.3407 8.86056 23.8072L9.59261 21.0752C9.73555 20.5417 10.2839 20.2251 10.8174 20.3681L13.5494 21.1001C14.0829 21.2431 14.3995 21.7914 14.2565 22.3249C14.1136 22.8583 13.5652 23.1749 13.0318 23.032L12.687 22.9396C14.9965 25.5851 18.9266 26.3176 22.0827 24.4954C24.2412 23.2492 25.5465 21.0929 25.7919 18.7872C25.8504 18.238 26.343 17.8402 26.8921 17.8986Z"
        fill="#2C2C2B"
      />
    </svg>
  );
};
