import React from 'react';

export default (props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="menu-05">
        <path
          d="M2.25 6.375H15.75M2.25 11.625H15.75"
          stroke="#161615"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
