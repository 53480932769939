import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16Z"
          fill="#EE8208"
        />
        <path
          d="M20.4442 12.1291C20.4442 14.4053 18.4464 16.2569 15.9887 16.2569C13.5315 16.2569 11.5332 14.4053 11.5332 12.1291C11.5332 9.85207 13.5315 8 15.9887 8C18.4464 8 20.4442 9.85207 20.4442 12.1291ZM17.8335 12.129C17.8335 11.1863 17.006 10.4198 15.9887 10.4198C14.9722 10.4198 14.1439 11.1863 14.1439 12.129C14.1439 13.071 14.9722 13.8381 15.9887 13.8381C17.006 13.8381 17.8335 13.071 17.8335 12.129Z"
          fill="white"
        />
        <path
          d="M17.8028 19.6269C18.7097 19.4354 19.5844 19.1029 20.39 18.634C20.9997 18.2778 21.1834 17.531 20.7992 16.966C20.4153 16.3998 19.61 16.2293 18.9992 16.5855C17.1741 17.649 14.8244 17.6488 13.0004 16.5855C12.3897 16.2293 11.584 16.3998 11.2009 16.966C10.8168 17.5315 10.9999 18.2778 11.6096 18.634C12.4153 19.1024 13.2899 19.4354 14.1969 19.6269L11.7059 21.9352C11.1967 22.4076 11.1967 23.1734 11.7064 23.6457C11.9615 23.8817 12.2953 23.9998 12.6291 23.9998C12.9634 23.9998 13.2977 23.8817 13.5528 23.6457L15.9995 21.3774L18.4484 23.6457C18.9576 24.1181 19.7838 24.1181 20.2935 23.6457C20.8037 23.1734 20.8037 22.4071 20.2935 21.9352L17.8028 19.6269Z"
          fill="white"
        />
      </svg>
    </SVGUniqueID>
  );
};
