import {createContext, useContext} from 'react';

export const StateContext = createContext(null);

export function useNotificationsState() {
  return useContext(StateContext);
}

export function tableData(action_types, notifications, salon_id) {
  let salonNotifications = _filterBySalon(notifications, salon_id)
    .sort((a, b) => a['visit_time_offset'] - b['visit_time_offset']);

  return action_types.map(({action_type, is_single_item, action_type_name, modal_data}) => {
    let items = _filterByActionType(salonNotifications, action_type);
    return {
      action_type: action_type,
      action_type_name: action_type_name,
      is_single_item: is_single_item,
      modal_data: modal_data,
      items: items,
    };
  });
}

export function getActionTypeName(action_type) {
  const {state} = useNotificationsState();
  return state.action_types.find(at => at.action_type === action_type)?.action_type_name;
}

export function removeNotification(notifications, id, action_type) {
  return notifications.filter((notification) => notification.id != id || notification.action_type != action_type);
}

function _filterBySalon(notifications, salonId) {
  return notifications.filter(({salon_id}) => salon_id === salonId);
}

function _filterByActionType(notifications, actionType) {
  return notifications.filter(({action_type}) => action_type === actionType);
}
