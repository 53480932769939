import React from 'react';

export default (props) => {
  return (
    <svg
      width="164"
      height="129"
      viewBox="0 0 164 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.9743 55.7211C93.6415 61.9453 93.2294 69.5145 93.2294 69.5145C93.8458 71.5115 94.1034 73.602 93.9902 75.689C98.0657 76.2527 104.104 81.791 106.541 84.954C110.297 89.8288 113.56 96.3248 115.824 104.564C118.088 112.804 120.427 116.564 121.523 116.521C122.619 116.478 124.33 114.887 125.236 111.017C126.142 107.148 126.09 103.57 126.257 100.208C126.323 98.8901 126.076 96.6486 127.07 95.6184C127.375 95.3162 127.766 95.1141 128.188 95.0387L127.985 95.0002C127.985 95.0002 127.136 88.6922 128.782 87.345C128.894 87.2539 129.015 87.1729 129.142 87.1027L129.024 86.4008V83.5049C124.948 81.1841 123.017 75.6324 125.311 71.5047V69.5779C124.188 69.8885 123.047 70.1267 121.894 70.2911C118.842 70.7145 115.64 70.59 112.756 69.279C110.061 68.0564 107.743 66.1363 105.495 64.1348C103.133 62.0336 100.81 59.8623 98.2129 58.103C96.867 57.1953 95.4495 56.3987 93.9743 55.7211Z"
        fill="#FFF8E5"
      />
      <path
        d="M45.2556 71.9146C45.1651 71.9433 45.0745 71.9705 44.9839 71.9961C45.3802 74.129 45.6338 76.1487 45.6338 76.1487C48.5976 76.0105 50.3886 79.6582 47.5583 84.0733C50.5018 84.5398 51.9916 88.2711 52.723 91.9844C54.4933 87.8496 57.1552 84.157 60.5186 81.1706C62.2484 79.6468 64.3269 78.7344 66.3919 77.7947C68.853 76.6762 73.3384 75.689 73.3384 75.689C75.3173 73.1599 75.709 69.7252 76.1709 66.4896C76.4653 64.436 76.4992 62.3552 76.8117 60.2993C76.8683 59.9393 76.9226 59.4638 76.9724 58.9612L76.9339 58.9386C76.8677 58.9925 76.7908 59.0317 76.7083 59.0538C76.6259 59.0758 76.5396 59.0801 76.4553 59.0664C76.3711 59.0527 76.2906 59.0214 76.2194 58.9743C76.1481 58.9273 76.0876 58.8657 76.0418 58.7936C75.5605 57.8652 75.1809 56.8874 74.9098 55.8774H74.946C73.7867 55.6645 72.5482 55.1347 72.1905 53.8645C66.3783 55.4336 61.5125 58.891 56.9479 63.0299C54.4822 65.267 52.0641 67.5832 49.4059 69.5485C48.1153 70.5176 46.7727 71.4256 45.2556 71.9146Z"
        fill="#FFF8E5"
      />
      <path
        d="M171.896 75.7457C171.136 72.4241 170.185 68.9373 168.24 66.1772C166.295 63.4172 163.641 61.6194 160.643 60.5666C154.51 58.4088 147.953 60.0367 142.093 62.439C142.5 62.6642 142.871 62.9489 143.193 63.2836C143.633 63.7175 143.963 64.2495 144.158 64.836C144.352 65.4225 144.404 66.0467 144.31 66.6572L144.457 66.7025C146.445 67.2527 146.929 68.8286 146.637 70.5516L146.802 70.6037C148.614 70.9162 148.854 73.0332 148.478 74.9057L148.951 75.1049C150.208 76.271 150.008 79.065 148.867 80.2763C148.391 80.7444 147.755 81.0159 147.088 81.0371L147.192 81.3586C146.691 83.7677 144.98 84.8251 143.359 85.0334L143.148 88.1217C143.474 88.2858 143.782 88.483 144.067 88.7104C145.213 89.6455 144.846 91.7535 144.846 93.2728V98.1974L144.393 98.1091C148.632 100.903 147.563 105.545 147.751 108.896C147.957 111.988 147.922 115.092 147.647 118.179L156.889 118.068L162.362 118.002C164.074 117.982 165.91 118.17 167.594 117.828C169.485 117.443 170.255 115.899 170.807 114.063C171.31 112.383 171.748 110.681 172.123 108.957C172.907 105.301 173.422 101.593 173.665 97.8623C174.164 90.4433 173.568 82.9912 171.896 75.7457Z"
        fill="#FFF8E5"
      />
      <path
        d="M27.4364 112.799C26.9949 109.652 25.4553 106.708 25.2719 103.475C25.1716 101.049 25.2404 98.6191 25.4779 96.2026C25.7813 92.6411 26.2115 89.6365 28.2085 86.5345C28.6372 85.8767 29.1385 85.2691 29.7029 84.7232C29.7029 84.7232 26.7594 74.7607 27.8802 74.1268C27.6727 74.0682 27.4745 73.9805 27.2915 73.8664C25.5466 74.6077 23.8783 75.5177 22.3103 76.5834C17.037 80.1563 12.9094 85.4908 10.1901 91.552C8.81919 94.6392 7.764 97.8571 7.04061 101.157C6.36136 104.245 5.6821 107.659 5.90852 110.861C6.13494 114.257 7.74704 117.158 10.8762 118.091C12.4113 118.543 14.0755 118.46 15.6491 118.471L21.1692 118.516L26.687 118.562C27.1444 116.519 27.6878 114.527 27.4364 112.799Z"
        fill="#FFF8E5"
      />
      <path
        d="M93.99 75.6891C98.0656 76.2529 104.104 81.7911 106.54 84.9519C110.297 89.8289 113.559 96.3249 115.824 104.564C118.088 112.804 120.427 116.565 121.523 116.521C122.618 116.478 124.328 114.887 125.236 111.017C126.144 107.148 126.089 103.57 126.257 100.208C126.323 98.8902 126.076 96.6487 127.07 95.6185C128.161 94.4864 130.45 95.0864 131.856 95.3241C135.787 95.9921 140.619 95.8404 144.065 97.9053C148.684 100.67 147.552 105.461 147.746 108.896C148.199 116.87 147.937 118.858 147.391 120.753C146.968 122.216 146.377 127.231 143.105 127.376C139.833 127.521 143.431 122.635 145.054 125.712C145.974 127.453 143.999 130.41 142.854 131.304"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.6508 125.508C24.896 121.432 27.9572 116.503 27.4364 112.799C26.9949 109.652 25.4553 106.708 25.2719 103.475C25.1716 101.049 25.2404 98.619 25.4779 96.2026C25.7813 92.641 26.2115 89.6364 28.2085 86.5345C30.0923 83.591 33.3369 82.0853 36.1807 80.1834C38.8253 78.4151 42.6676 76.2845 45.6337 76.1486C48.5998 76.0128 50.3885 79.6581 47.5583 84.0733C53.1056 84.9518 53.4678 97.3596 53.3795 99.5128C53.298 101.514 53.1531 106.957 50.8776 107.89C48.9553 108.678 48.987 107.132 49.1523 105.57C49.4579 102.674 50.4904 99.617 51.1425 96.7822C52.501 90.8523 55.929 85.219 60.523 81.1751C62.2529 79.6513 64.3314 78.7389 66.3964 77.7992C68.8575 76.6807 73.3429 75.6935 73.3429 75.6935"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M127.991 95.0004C127.991 95.0004 127.142 88.6923 128.788 87.3451C130.434 85.9979 134.249 86.7089 137.603 86.9149C140.956 87.121 142.928 87.7753 144.076 88.7104C145.224 89.6456 144.855 91.7535 144.855 93.2728V98.1974"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M129.024 83.5052C124.871 81.1368 122.933 75.3971 125.458 71.2491C127.464 67.9547 134.933 64.2414 137.521 68.9419C139.473 72.4921 133.813 72.9065 131.775 72.2114C133.373 72.5125 134.945 75.1752 134.945 77.6703"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M130.292 64.6512C132.386 63.7444 134.531 62.9619 136.718 62.3077C139.027 61.6692 141.418 61.4564 143.193 63.2836C146.073 66.2497 142.967 70.4475 140.549 72.9064C138.466 75.0393 136.041 74.9442 135.47 72.9245"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M125.31 70.9999C125.31 63.879 125.292 56.9416 127.21 50.1399C127.588 48.8041 128.324 44.2281 131.512 45.838C133.299 46.7436 132.239 52.2117 132.13 53.989C131.983 56.3981 131.945 61.3477 131.945 63.6753"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M138.01 66.8972C137.607 67.2888 137.276 67.7487 137.032 68.2557"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M144.456 66.7025C148.192 67.7372 146.619 72.3901 144.561 74.8716C142.502 77.3532 140.472 78.0913 139.086 77.4506C137.954 76.9275 137.077 75.5735 138.25 74.3531"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M146.802 70.6195C149.569 71.0995 148.67 75.7909 147.509 77.3509C146.7 78.4355 145.244 79.7917 143.659 79.4091C142.5 79.1306 141.884 78.0506 142.491 76.8709"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M148.951 75.1027C150.208 76.2688 150.008 79.0628 148.867 80.2741C147.69 81.5217 144.821 81.2998 145.448 79.1647"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M147.192 81.3563C146.601 84.1979 144.327 85.1624 142.502 85.0673"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M143.356 85.0674L143.161 87.9362"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.6938 84.7232C29.6938 84.7232 26.7504 74.7607 27.8712 74.1267C28.9919 73.4928 34.0139 70.8482 36.8102 69.5191C38.1506 68.8806 42.1718 66.5938 43.3763 67.0783C44.5809 67.5629 45.6405 76.1487 45.6405 76.1487"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.2563 66.508C44.3318 63.3562 42.9348 57.7795 45.1356 55.393C47.959 52.3319 53.2187 49.1394 51.4323 47.2012C49.1794 44.7536 46.3265 47.5861 44.2752 48.6752C42.0291 49.8684 39.86 51.1205 37.3037 52.6375C26.6303 48.2858 22.1495 50.6949 16.1019 52.0036C13.8128 52.5062 8.66853 54.3176 12.461 56.5682C15.4928 58.3659 20.3857 58.692 22.1971 61.8188C23.3178 63.7456 24.1194 66.3314 24.656 68.4665C25.025 69.9359 25.7745 73.5451 27.8734 74.127"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9992 53.808C14.003 53.242 19.0793 54.9401 20.3269 55.7303"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.2724 51.8541C19.4755 52.2503 21.9887 53.439 23.7208 54.1998"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.1344 50.8781C24.9096 51.4125 26.6983 52.5446 28.4077 53.2216"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path className="custom-path"
        d="M152.402 24.3667C151.193 15.3213 146.207 9.10605 138.364 6.8645C134.048 5.63278 129.843 5.53769 126.207 6.58601C122.132 7.76339 118.876 10.2812 116.521 14.0714C114.185 17.8036 112.901 22.0977 112.805 26.4996C112.719 31.0913 114.01 35.4137 116.537 38.9956C119.193 42.7632 123.103 44.9618 127.536 45.1905C128.594 45.2448 129.66 45.2833 130.731 45.2833C134.127 45.2833 137.551 44.8961 140.573 43.4153C144.71 41.3776 147.54 38.9345 149.479 35.6967C151.226 32.7623 152.918 28.2453 152.402 24.3667Z"
        fill="#FFD977"
      />
      <path
        d="M122.892 9.03345C122.892 9.03345 125.032 14.6939 125.865 17.0442C126.735 19.5008 129.635 26.1598 129.635 26.1598C129.635 26.1598 139.58 28.2247 142.233 28.8632C144.81 29.4836 147.568 29.9297 149.852 30.7199"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M129.73 25.8406C129.73 25.8406 146.107 17.3748 148.485 15.6812"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M110.46 16.1681H108.506"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M138.678 0.877869L140.904 0.966172"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M156.119 22.4511L158.47 22.39"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.3429 75.6891C75.3218 73.16 75.7135 69.7252 76.1754 66.4897C76.4697 64.436 76.5037 62.3552 76.8161 60.2994C77.0222 58.9544 77.2214 55.9861 77.2214 55.9861C77.2214 55.9861 71.3866 56.5816 72.17 52.343C72.6025 50.0131 75.5233 47.3323 77.998 48.3127C78.5912 43.5104 78.4894 38.6673 83.063 35.785C88.2027 32.5494 93.8496 38.5812 97.4542 42.0115C99.9897 44.4221 102.308 47.0512 104.383 49.8682C101.376 50.2441 94.2776 49.9271 94.2776 49.9271C93.9945 55.497 93.2292 69.5146 93.2292 69.5146C94.6376 74.1268 94.1485 79.3887 91.9703 79.4657C89.7922 79.5427 88.5424 75.6845 86.8555 73.7939C85.9929 76.0762 83.1513 79.7601 80.1852 79.7646C77.2191 79.7691 74.3934 77.5253 73.3429 75.6891Z"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M76.0779 68.0701C79.7006 71.9849 88.1778 71.4505 92.4888 69.2429"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.5156 52.2457C74.773 52.2224 75.0322 52.2638 75.2694 52.3662C75.5067 52.4686 75.7146 52.6288 75.8741 52.8321"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M84.3468 51.9583C84.9061 52.9636 86.0631 53.19 87.082 52.7394"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M85.6509 45.7679V44.9551"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M89.9529 45.2131V44.2349"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M86.1762 81.7548C86.4026 84.0077 85.2253 87.3474 84.8177 89.5504C84.1792 92.9897 83.0516 96.2434 83.0403 98.9876C83.029 101.732 83.6222 103.117 85.1505 104.195C86.6789 105.273 87.2698 106.018 87.1657 107.974C87.0208 110.673 85.9272 113.635 84.8087 116.064C84.109 117.586 82.7935 119.234 84.3241 120.543C86.1536 122.091 88.3997 120.731 89.0993 123.939C89.5114 125.852 88.8548 128.356 88.3498 130.154"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M94.6378 95.1952C97.5405 95.1952 100.049 94.928 102.841 95.3378"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M100.758 93.6644L100.561 97.7671"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M98.4118 93.2728C98.4118 95.0842 98.7854 97.2533 98.2171 98.9333"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M85.8457 96.9317L86.6291 96.54"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M89.5408 110.01L90.2246 109.84"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M92.1946 124.831L91.6104 124.872"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.9186 55.8774C75.1898 56.8875 75.5694 57.8653 76.0507 58.7937C76.0991 58.8697 76.1637 58.9341 76.2399 58.9822C76.3161 59.0302 76.402 59.0607 76.4915 59.0716C76.5809 59.0824 76.6717 59.0732 76.7572 59.0448C76.8426 59.0163 76.9207 58.9692 76.9858 58.9069C77.1217 57.5484 77.2258 55.9861 77.2258 55.9861C76.4553 56.0492 75.6798 56.0127 74.9186 55.8774Z"
        fill="#2C2C2B"
      />
      <path
        d="M91.2504 33.9396L86.2397 33.3985C84.7589 33.2377 83.2646 33 81.7838 33.283C79.7822 33.6657 76.9294 35.2415 76.6984 37.5193C76.634 37.9645 76.7347 38.4179 76.9814 38.7941C77.015 38.8407 77.0513 38.8853 77.0901 38.9276C74.6108 40.5669 74.4863 44.4613 74.3301 47.2191C74.2938 47.8531 74.2689 48.4916 74.2531 49.1301C74.9016 48.5933 75.6846 48.2441 76.5173 48.1203C76.8662 48.8973 77.2601 49.6533 77.6969 50.3844C77.7643 50.4918 77.8643 50.5747 77.9824 50.6209C78.1005 50.667 78.2302 50.674 78.3525 50.6407C78.4749 50.6075 78.5833 50.5358 78.6617 50.4362C78.7402 50.3367 78.7845 50.2145 78.7883 50.0878C78.7568 48.6676 79.0938 47.2635 79.7664 46.0123C80.3121 45.016 81.0457 44.133 81.4328 43.053C81.82 41.973 81.7068 40.5964 80.98 39.7224C82.1551 39.4258 83.3121 38.4476 84.0638 37.6031C85.1461 36.385 86.1016 35.6083 87.8043 35.6151C89.1152 35.6151 90.3809 35.7578 91.5447 35.0378C92.0723 34.714 91.8073 34.0076 91.2504 33.9396Z"
        fill="#2C2C2B"
      />
      <path
        d="M129.024 86.401V83.5051"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35.8388 63.7726C35.504 63.7466 35.1681 63.8043 34.8613 63.9407C34.5545 64.0771 34.2866 64.2878 34.0817 64.5538"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path className="custom-path"
        d="M48.1854 18.7017C48.174 18.5568 48.165 18.3983 48.1537 18.2284C47.9952 15.7175 47.7325 11.5129 45.2578 10.4419C41.5219 8.82527 38.4924 13.6548 37.6523 15.1741C36.878 16.5756 36.2395 18.2284 35.567 19.9809C33.9119 24.2829 32.2002 28.7184 28.4122 29.3705C25.5457 29.8641 24.5857 27.9939 23.2544 24.901C22.2604 22.5938 21.2347 20.2073 18.769 19.5824C15.5312 18.7628 10.7175 20.2979 6.18464 32.2619C6.14142 32.379 6.12592 32.5046 6.13935 32.6287L8.29033 52.6487C8.31102 52.8432 8.40294 53.0233 8.54841 53.1541C8.69389 53.2849 8.88262 53.3573 9.07827 53.3574C9.12614 53.3573 9.1739 53.3528 9.22092 53.3438L49.0707 46.044C49.2609 46.0095 49.4319 45.9067 49.5516 45.7549C49.6712 45.6031 49.7313 45.4128 49.7205 45.2199C49.7137 45.007 48.5182 23.4678 48.1854 18.7017Z"
        fill="#FFD977"
      />
      <path
        d="M55.9109 44.7693C55.9109 44.7693 38.4585 47.1761 34.675 47.792C28.4417 48.8063 9.0761 52.5672 9.0761 52.5672C9.0761 52.5672 5.67982 20.8685 4.85565 13.5371C4.62923 11.4834 4.23979 6.47278 4.23979 6.47278"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.83064 9.27585C2.5982 8.29319 4.23973 6.47278 4.23973 6.47278L7.26922 8.35432"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M54.3304 42.7292C55.064 43.345 55.8338 43.8432 56.5946 44.4319C56.1191 45.3692 55.4625 46.3021 55.1681 47.2961"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.81335 18.3146H1.54314"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.1861 39.2402H3.91589"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.08113 27.8672H2.81319"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.04666 48.614H4.77645"
        stroke="#2C2C2B"
        stroke-width="1.58493"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
