import React from 'react';
import { Container, Row, Cell } from '@components/grid';
import Card from '@front_office/components/Card';

import translator from '@webpack/translator';
const t = translator('front_office.views.payments.variants.start.');

const Start = ({
  subscription_enabled,
  onboarding_url,
  setStep,
  setPaymentType,
  sms_enabled,
  whatsAppImg,
  smsImg,
  balanceImg,
}) => {
  return (
    <Container>
      <Row>
        <Cell>
          <h3 className="header-v2">{t('header')}</h3>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <h4 className="header-v2-sub">{t('subtitle')}</h4>
        </Cell>
      </Row>
      <Row className={{ 'justify-content-center': !sms_enabled }}>
        <Cell md={4} lg={4}>
          {subscription_enabled && (
            <Card
              title={t('whatsapp_title')}
              icon={whatsAppImg}
              button={{ text: t('pay_button') }}
              onClick={() => {
                setStep('whats_app'), setPaymentType('whats_app');
              }}
            />
          )}
          {!subscription_enabled && (
            <Card
              title={t('whatsapp_title')}
              icon={whatsAppImg}
              link={{ href: onboarding_url, text: t('whatsapp_connect') }}
            />
          )}
        </Cell>
        {sms_enabled && (
          <Cell md={4} lg={4}>
            <Card
              title={t('sms_title')}
              icon={smsImg}
              button={{ text: t('pay_button') }}
              onClick={() => {
                setStep('sms'), setPaymentType('sms');
              }}
            />
          </Cell>
        )}
        <Cell md={4} lg={4}>
          <Card
            title={t('balance_title')}
            icon={balanceImg}
            button={{ text: t('pay_button') }}
            onClick={() => {
              setStep('manual'), setPaymentType('manual');
            }}
          />
        </Cell>
      </Row>
    </Container>
  );
};

export default Start;
