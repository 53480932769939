import React from 'react';

export default (props) => {
  return (
    <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.666687" width="36" height="36" rx="8" fill="#FFE8AD" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6667 9C13.6961 9 9.66669 13.0294 9.66669 18C9.66669 22.9706 13.6961 27 18.6667 27C23.6372 27 27.6667 22.9706 27.6667 18C27.6667 13.0294 23.6372 9 18.6667 9ZM7.66669 18C7.66669 11.9249 12.5916 7 18.6667 7C24.7418 7 29.6667 11.9249 29.6667 18C29.6667 24.0751 24.7418 29 18.6667 29C12.5916 29 7.66669 24.0751 7.66669 18ZM23.8738 14.2929C24.2643 14.6834 24.2643 15.3166 23.8738 15.7071L17.8738 21.7071C17.4833 22.0976 16.8501 22.0976 16.4596 21.7071L13.4596 18.7071C13.0691 18.3166 13.0691 17.6834 13.4596 17.2929C13.8501 16.9024 14.4833 16.9024 14.8738 17.2929L17.1667 19.5858L22.4596 14.2929C22.8501 13.9024 23.4833 13.9024 23.8738 14.2929Z"
        fill="#2C2C2B"
      />
    </svg>
  );
};
