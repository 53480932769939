import React, { useRef } from 'react';
import classNames from 'classnames';

const Radio = ({ className, name, title, label, content, checked, value, onClick, img, validate }) => {
  const radioId = `${name}_${value}`

  return (
    <label className={classNames("custom_payment_radio", className)} htmlFor={radioId} onClick={onClick}>
      <input id={radioId} type="radio" name={name} value={value} {...validate} checked={checked}/>

      <div className='custom_payment_radio-body'>
        <div className='custom_payment_radio-checkmark_box'>
          <div className='checkmark'></div>
        </div>
        <div className='custom_payment_radio-body-content'>
          <div className='custom_payment_radio-body-content-header'>
            <div className='custom_payment_radio-body-content-header-title'>
              {title}
            </div>
            {label && <div className='custom_payment_radio-body-content-header-label'>
              {label}
            </div> || ""}
          </div>
          <div className='custom_payment_radio-body-content-desc'>{content}</div>
        </div>
      </div>
      {img && <div className='img_box'>
        <img src={img} className={value} />
      </div>}
    </label>
  )
}

export default Radio;