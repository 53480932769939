import React from 'react';
import { Col, Row } from 'antd';

import translator from '@webpack/translator';
const t = translator('main_page.elements.advantages.');

const Advantages = () => {
  return (
    <div className="container">
      <div className="advantages">
        <Row>
          <Col xs={0} md={1} lg={2} xl={4}></Col>
          <Col xs={24} md={22} lg={20} xl={16}>
            <Row gutter={{ md: 65 }}>
              <Col xs={24}>
                <div className="advantages-body">
                  <div className="advantages-body-title">{t('body_title')}</div>
                </div>
              </Col>
              <Col xs={24} md={8} lg={8}>
                <div className="advantages-body">
                  <div className="advantages-body-sub_title">{t('sub_title_1')}</div>
                  <div className="advantages-body-desc">{t('desc_1')}</div>
                </div>
              </Col>
              <Col xs={24} md={8} lg={8}>
                <div className="advantages-body">
                  <div className="advantages-body-sub_title">{t('sub_title_2')}</div>
                  <div className="advantages-body-desc">{t('desc_2')}</div>
                </div>
              </Col>
              <Col xs={24} md={8} lg={8}>
                <div className="advantages-body">
                  <div className="advantages-body-sub_title">{t('sub_title_3')}</div>
                  <div className="advantages-body-desc">{t('desc_3')}</div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={0} md={1} lg={2} xl={4}></Col>
        </Row>
      </div>
    </div>
  );
};

export default Advantages;
