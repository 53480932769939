import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#tariff_sms_mts)">
          <path
            d="M0 15.9988C0 24.8352 7.16242 31.9971 16.0011 31.9971C24.8359 31.9971 32 24.8352 32 15.9988C32 7.16177 24.8359 -8.01086e-05 16.0011 -8.01086e-05C7.16242 -8.01086e-05 0 7.16177 0 15.9988Z"
            fill="#E30613"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.12753 12.75C4.63128 12.75 4.04291 13.2271 3.55759 14.0292C3.05176 14.8599 2.75 15.8882 2.75 16.7813C2.75 18.0887 3.48368 19.4071 5.12767 19.4071C6.76968 19.4071 7.50533 18.0887 7.50533 16.7813C7.50533 15.8882 7.20371 14.8599 6.70032 14.0292C6.21046 13.2271 5.62215 12.75 5.12753 12.75ZM17.9663 14.7115H19.6307V19.0506H21.4139V14.7115H23.0776V13.1064H17.9663V14.7113V14.7115ZM26.8819 14.7115H29.4973V13.1064H26.8819C24.4791 13.1064 23.1958 14.3011 23.1958 16.0783C23.1958 17.8555 24.4791 19.0506 26.8819 19.0506H29.4973V17.4456H26.8819C25.7698 17.4456 25.0389 17.0481 25.0389 16.0785C25.0389 15.1089 25.7698 14.7115 26.8819 14.7115ZM14.3394 13.1064L13.389 16.6543L12.4386 13.1064H9.28852V19.0506H11.0711V14.2158L12.3666 19.0506H14.4114L15.7075 14.2129V19.0506H17.4908V13.1064H14.3394Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="tariff_sms_mts">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  );
};
