import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Cell = ({ id, xs, sm, md, lg, xl, children, className, style }) => {
    const bootstrapClasses = [];
    xs !== undefined ? bootstrapClasses.push('col-xs-' + xs) : bootstrapClasses.push('col-xs-12');
    sm !== undefined ? bootstrapClasses.push('col-sm-' + sm) : '';
    md !== undefined ? bootstrapClasses.push('col-md-' + md) : '';
    lg !== undefined ? bootstrapClasses.push('col-lg-' + lg) : '';
    xl !== undefined ? bootstrapClasses.push('col-xl-' + xl) : '';

    let classes = classNames(bootstrapClasses, className)

    return (
        <div id={id} className={classes} style={style}>
            {children}
        </div>
    );
};

Cell.propTypes = {
    xs: PropTypes.oneOf([0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    sm: PropTypes.oneOf([0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    md: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    lg: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    xl: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    className: PropTypes.string,
    id: PropTypes.string
};

Cell.defaultProps = {
    xs: 12,
};

export default Cell;
