import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_364_2228)">
          <path
            d="M16.6047 21.5303C15.9863 21.4891 15.2731 21.3807 14.7107 21.2178C14.6053 21.1872 14.1691 21.0269 14.0582 20.976C12.1 20.069 10.7359 18.0889 10.7359 15.7918C10.7359 12.6411 13.2992 10.0747 16.452 10.0747C19.5768 10.0747 22.1228 12.5973 22.1666 15.7124H24.2316C24.1878 11.4592 20.7159 8.01221 16.452 8.01221C12.1626 8.01221 8.67138 11.5014 8.67138 15.7918C8.67138 18.7496 10.3348 21.3221 12.7704 22.6364C13.2102 22.869 13.8413 23.1087 14.0556 23.1744C14.7499 23.381 15.602 23.5185 16.3604 23.576C16.6179 23.5953 17.5173 23.6055 17.7795 23.6065H32V21.5497H17.8034C17.6146 21.5497 16.7895 21.5425 16.6047 21.5303ZM17.8034 18.3007C17.6532 18.3007 16.8679 18.2956 16.7228 18.2834C16.4769 18.2625 16.1206 18.2259 15.8916 18.1734C15.633 18.1042 15.3902 18.0126 15.2808 17.9525C14.5137 17.5377 13.991 16.7263 13.991 15.7918C13.991 14.4323 15.0919 13.3298 16.452 13.3298C17.7836 13.3298 18.8662 14.389 18.9095 15.7124H20.8936C20.8518 13.2946 18.881 11.3462 16.452 11.3462C13.9961 11.3462 12.0053 13.3364 12.0053 15.7918C12.0053 17.5234 12.9979 19.0189 14.4435 19.7513C14.6659 19.8817 15.0843 20.0181 15.2034 20.0491C15.6116 20.1565 16.1598 20.2298 16.6047 20.2624C16.7691 20.2761 17.5743 20.2822 17.7444 20.2833V20.2863H31.9995V18.3007H17.8034ZM7.39887 23.5989H5.3201V10.0793H2.28237V8H7.39887V23.5989ZM0 11.1181V13.1175H2.28237V23.5989H4.2797V11.1181H0Z"
            fill="#D91920"
          />
          <path
            d="M28.3268 8.14521C27.578 8.14521 26.957 8.74227 26.957 9.49152C26.957 10.2408 27.578 10.8618 28.3268 10.8618C29.0984 10.8618 29.6955 10.2408 29.6955 9.49152C29.6955 8.74227 29.0984 8.14521 28.3268 8.14521ZM28.3268 10.6408C27.7083 10.6408 27.1754 10.1548 27.1754 9.49152C27.1754 8.86188 27.7083 8.36357 28.3268 8.36357C28.9447 8.36357 29.4751 8.86188 29.4751 9.49152C29.4751 10.1548 28.9447 10.6408 28.3268 10.6408Z"
            fill="#D91920"
          />
          <path
            d="M28.8483 9.86043C28.8483 9.70926 28.8259 9.56877 28.6529 9.51329C28.8697 9.43796 28.8911 9.29595 28.8911 9.20993C28.8911 8.86228 28.5755 8.81799 28.4457 8.81799H27.8486V10.1643H28.0975V9.61204H28.2935C28.5755 9.61204 28.5989 9.74183 28.5989 9.86043C28.5989 10.09 28.5989 10.1333 28.6529 10.1643H28.9023C28.8483 10.1098 28.8697 10.0676 28.8483 9.86043ZM28.3912 9.41556H28.0975V8.99156H28.349C28.5231 8.99156 28.6412 9.04654 28.6412 9.20993C28.6412 9.29595 28.5989 9.41556 28.3912 9.41556Z"
            fill="#D91920"
          />
        </g>
        <defs>
          <clipPath id="clip0_364_2228">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  );
};
