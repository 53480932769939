import React, { FC } from 'react';

interface Props {
  children: JSX.Element | JSX.Element[];
  title?: string;
  icon?: JSX.Element;
}

export const InfoPane: FC<Props> = ({ children, title, icon }) => (
  <div className="telegram-flow_info-pane">
    {title && (
      <div className="d-flex telegram-flow_info-pane_title">
        {icon && <span className="telegram-flow_info-pane_icon">{icon}</span>}
        <h2>{title}</h2>
      </div>
    )}
    {children && <div className="telegram-flow_info-pane_body">{children}</div>}
  </div>
);
