export default class Router {
  static post(path, data, scope = null) {
    return this._request('POST', path, data, scope);
  }

  static patch(path, data, scope = null) {
    return this._request('PATCH', path, data, scope);
  }

  static async _request(method, path, data, scope) {
    const form_data = new FormData();

    if (scope) {
      for (let key in data) form_data.append(`${scope}[${key}]`, data[key]);
    } else {
      for (let key in data) form_data.append(key, data[key]);
    }

    const response = await fetch(path, {
      method: method,
      headers: {
        Accept: 'text/html',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      body: form_data,
    });

    return response.json();
  }
}
