import React from 'react';
import { Row, Col, Form, Select } from 'antd';
import v1_select_arrow from '@imgs/front_office/form/v1_select_arrow.svg';

const { Option } = Select;

const SelectField = ({
  label,
  name,
  arrayValues,
  defaultValue,
  arrayHashValues,
  onChange,
  helpLink,
  helpText,
}) => {
  return (
    <Form.Item label={label} name={name}>
      <Row gutter={30}>
        <Col span={24} md={24} lg={12}>
          <Select
            popupClassName="bulk_deliveries-ant-select-custom-dropdown"
            size="large"
            defaultValue={defaultValue}
            suffixIcon={<img src={v1_select_arrow} />}
            onChange={onChange}
          >
            {arrayHashValues &&
              arrayHashValues.map((value) => (
                <Option key={value[1]} value={value[1]}>
                  {value[0]}
                </Option>
              ))}
            {arrayValues &&
              arrayValues.map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
          </Select>
        </Col>
        <Col span={24} md={24} lg={12}>
          <div>
            <a href={helpLink}> {helpText} </a>
          </div>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default SelectField;
