import React from 'react';

const Card = ({ title, description, icon, handler, link, additionalInfo }) => {
  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="soc_networks-cart" onClick={handler}>
      {icon && (
        <div className="soc_networks-cart-icon">
          <img src={icon} />
        </div>
      )}
      {title && (
        <div className="soc_networks-cart-title">
          {title}
          {additionalInfo && (
            <span className="soc_networks-cart-additional_info">{additionalInfo}</span>
          )}
        </div>
      )}
      {link && (
        <a className="soc_networks-cart-link" href={link.href} onClick={handleClick}>
          {link.text}
        </a>
      )}
      {description && <div className="soc_networks-cart-desc">{description}</div>}
    </div>
  );
};

export default Card;
