import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Typography } from 'antd';
import { useForm } from 'react-hook-form';
import Router from '@webpack/router.js';

import {
  SelectField,
  PhonesField,
  UsernamesField,
  TextareaField,
  UploadImage,
  SmsProgressBar,
  SmsPresenter,
  Actions,
} from './form';
import CurrentModal from './modals/CurrentModal';

import translator from '@webpack/translator';
const t = translator('front_office.views.bulk_deliveries.new_view.');

const { Title } = Typography;

const NewView = ({
  senders,
  dispatchRoutings,
  defaultRouting,
  utmMarks,
  timezones,
  defaultTimezone,
  urlShorting,
  whatsappActive,
  calculatePath,
  bulkCreatePath,
  senderNamePath,
  utmMarksPath,
  dispatchRoutingPath,
  sms_word,
  shortLinkEnabled,
  showUploadButton,
}) => {
  const { register, setValue, getValues } = useForm();
  const [rendSenderName, setRendSenderName] = useState(defaultRouting[0].includes(sms_word));
  const [visibleModal, setVisibleModal] = useState(false);
  const [textTexarea, setTextTexarea] = useState('');
  const [phonesTexarea, setPhonesTexarea] = useState('');
  const [usernamesTexarea, setUsernamesTexarea] = useState('');
  const [imageFile, setImageFile] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isSmsSelected, setIsSmsSelected] = useState(false);
  const [isOnlySmsSelected, setIsOnlySmsSelected] = useState(false);
  const [isWhatsAppSelected, setIsWhatsAppSelected] = useState(false);
  const [isTelegramBotSelected, setIsTelegramBotSelected] = useState(false);
  const [isTelegramSelected, setIsOnlyTelegramSelected] = useState(defaultRouting[0] == 'telegram');

  const [isUrlShorterChecked, setUrlShorterChecked] = useState(false);
  const [messagePresenter, setMessagePresenter] = useState('');
  const [smsLengthValue, setSmsLengthValue] = useState('0 / 160 ');
  const [smsCountValue, setSmsCountValue] = useState('0');
  const [whatsAppMessageTimeDelivery, setWhatsAppMessageTimeDelivery] = useState('0');
  const [phonesCount, setPhonesCount] = useState(0);
  const [usernamesCount, setUsernamesCount] = useState(0);

  const [response, setResponse] = useState(null);

  useEffect(() => {
    setSmsValues();
    smsPhoneTextDisplay();
  }, [textTexarea, isSmsSelected, isUrlShorterChecked]);

  useEffect(() => {
    setWhatsAppValues();
    smsPhoneTextDisplay();
  }, [textTexarea, isWhatsAppSelected]);

  useEffect(() => {
    smsPhoneTextDisplay();
  }, [imageFile]);

  useEffect(() => {
    calculatePhonesCount();
    setWhatsAppValues();
  }, [phonesTexarea]);

  useEffect(() => {
    calculatePhonesCount();
    setWhatsAppValues();
  }, [isTelegramSelected]);

  useEffect(() => checkRoute(defaultRouting[0]), []);

  const checkRoute = (option_text) => {
    const smsRegex = /(с|c|s)(м|m)(с|c|s)/i;
    const whatsappRegex = /whatsapp/i;
    const telegramBotRegex = /telegram bot/i;
    const onlySmsRegex = /^(с|c|s)(м|m)(с|c|s)$/i;
    const onlyTelegramRegex = /^telegram$/i;
    setIsSmsSelected(smsRegex.test(option_text));
    setIsWhatsAppSelected(whatsappRegex.test(option_text));
    setIsTelegramBotSelected(telegramBotRegex.test(option_text));
    setIsOnlySmsSelected(onlySmsRegex.test(option_text));
    setIsOnlyTelegramSelected(onlyTelegramRegex.test(option_text));
  };

  const symbolsCount = () => {
    if (textTexarea === '') return 0;
    return shortLinksInText(textTexarea).length;
  };

  const setSmsValues = () => {
    const nonLatinPattern = /[\u0400-\u04FF\{\}\[\]\\\|\^\~\€]/;
    let smsTotal = 0;
    let divider = 160;
    let len = symbolsCount();

    if (len === 0) {
      clearSmsTextarea();
      return;
    }

    if (nonLatinPattern.test(shortLinksInText(textTexarea))) {
      smsTotal = len > 70 ? Math.ceil(len / 67) : 1;
      divider = smsTotal > 1 ? 67 : 70;
      ruProgressBar(len, smsTotal);
    } else {
      smsTotal = len > 160 ? Math.ceil(len / 153) : 1;
      divider = smsTotal > 1 ? 153 : 160;
      enProgressBar(len, smsTotal);
    }

    setSmsLengthValue(`${len} / ${divider} `);
    setSmsCountValue(smsTotal);
  };

  const clearSmsTextarea = () => {
    setWhatsAppMessageTimeDelivery('0');
    setSmsLengthValue('0 / 160 ');
    setSmsCountValue('0');
    setTextTexarea('');
    setMessagePresenter(t('your_text'));
    ruProgressBar(0, 0);
    enProgressBar(0, 0);
  };

  const setWhatsAppValues = () => {
    let len = symbolsCount();
    let formattedTime = formatTimeText(formatTime(len));

    setWhatsAppMessageTimeDelivery(formattedTime);
  };

  const smsPhoneTextDisplay = () => {
    if (textTexarea.length === 0) {
      let text = imageFile ? '' : t('your_text');
      setMessagePresenter(text);
      return;
    }

    let text = shortLinksInText(textTexarea);

    let newText = $('#url_shorter').prop('checked')
      ? text.replace('p-sms.ru/testme', '<a href="/blog/shortsms">p-sms.ru/testme</a>')
      : text;
    if (isOnlySmsSelected) {
      newText = newText.replace(/\n+/g, ' ');
    }

    setMessagePresenter(newText);
  };

  const clearPhonesTextarea = () => {
    setPhonesTexarea('');
    setPhonesCount(0);
  };

  const clearUsernamesTextarea = () => {
    setUsernamesTexarea('');
    setUsernamesCount(0);
  };

  const updatePhonesLength = (text, setValue) => {
    if (text == '') return clearPhonesTextarea();
    setPhonesTexarea(text);
    setPhonesCount(0);
    let phones = text.split(/[^\d]+/).filter((phone) => {
      return /^(?!0)\d{8,15}$/.test(phone);
    });
    setPhonesCount(phones.length);
    setValue('phone_list', phones.join(' '));
    $('#hidden_field_phones').val(phones.join(' '));
  };

  const updateUsernamesLength = (text, setValue) => {
    if (text == '') return clearUsernamesTextarea();
    setUsernamesTexarea(text);
    setUsernamesCount(0);
    let usernames = text.split(/\s+/).filter((word) => word.trim() !== '');
    setUsernamesCount(usernames.length);
    setValue('username_list', usernames.join(' '));
    $('#hidden_field_usernames').val(usernames.join(' '));
  };

  const calculatePhonesCount = () => {
    setPhonesCount(phonesCount);
  };

  const formatTime = (textLength) => {
    let textLengthMultipliedByPhonesCount = textLength * phonesCount;
    let timeInSeconds = textLengthMultipliedByPhonesCount;
    let min = timeInSeconds / 60;
    let formattedMinutes = Math.floor(min % 60);
    let formattedHours = Math.floor((min - formattedMinutes) / 60);

    return `${formattedHours} ${formattedMinutes}`;
  };

  const recountSms = () => {
    const smsTextArea = $('#smstext');
    if (smsTextArea.val() !== '') {
      setSmsValues();
    }
  };

  const dynamicPath = rendSenderName ? calculatePath : bulkCreatePath;

  const onSubmit = (path = dynamicPath) => {
    if (!whatsappActive) return setVisibleModal(true);
    setIsLoading(true);

    const data = getValues();
    data.with_attachment = false;

    if (path == calculatePath) {
      if (imageFile) data.with_attachment = true;
      delete data.image_file;
    }

    Router.post(path, data)
      .then((response) => {
        setResponse(response);
        setValue('external_id', Date.now());
      })
      .catch((_error) => {
        setResponse(false);
      })
      .finally(() => {
        setVisibleModal(true);
        setIsLoading(false);
      });
  };

  const clearFields = () => {
    clearSmsTextarea();
    clearPhonesTextarea();
    clearUsernamesTextarea();
    setImageFile('');
    setValue('phone_list', '');
    setValue('username_list', '');
    setValue('text', '');
    setValue('image_file', '');
  };

  register('dispatch_routing', { value: defaultRouting[1] });
  register('sender_name', { value: senders[0] });
  register('utm_mark', { value: '' });
  register('phone_list', { value: '' });
  register('username_list', { value: '' });
  register('text', { value: '' });
  register('scheduled_at', { value: '' });
  register('url_shorting', { value: urlShorting });
  register('external_id', { value: Date.now() });
  register('image_file', { value: '' });
  return (
    <div className="bulk_deliveries">
      <CurrentModal
        isLoading={isLoading}
        response={response}
        whatsappActive={whatsappActive}
        visibleModal={visibleModal}
        onCancel={() => setVisibleModal(false)}
        onOk={() => onSubmit(bulkCreatePath)}
        successOnCancel={() => {
          setVisibleModal(false), clearFields();
        }}
      />
      <Row>
        <Col span={24}>
          <Title>{t('title')}</Title>
        </Col>
        <Col span={24} lg={14} xl={14}>
          <section className="widget">
            <Form id="form" layout="vertical" onFinish={() => onSubmit()}>
              <SelectField
                label={t('dispatch_routing_label')}
                name="dispatch_routing"
                arrayHashValues={dispatchRoutings}
                defaultValue={defaultRouting}
                onChange={(value, option) => {
                  setValue('dispatch_routing', value);
                  checkRoute(option.children);
                  setRendSenderName(option.children.includes(sms_word));
                }}
                helpLink={dispatchRoutingPath}
                helpText={t('dispatch_routing_help')}
              />
              {rendSenderName && (
                <SelectField
                  label={t('sender_name_label')}
                  name="sender_name"
                  arrayValues={senders}
                  defaultValue={senders[0]}
                  onChange={(value, _e) => {
                    smsPhoneHeaderDisplay(value), setValue('sender_name', value);
                  }}
                  helpLink={senderNamePath}
                  helpText={t('sender_name_help')}
                />
              )}
              {(utmMarks.length && (
                <SelectField
                  label={t('utm_mark_label')}
                  name="utm_mark"
                  arrayValues={utmMarks}
                  onChange={(value, _e) => {
                    setValue('utm_mark', value);
                  }}
                  helpLink={utmMarksPath}
                  helpText={t('utm_mark_help')}
                />
              )) ||
                ''}
              <PhonesField
                phonesTexarea={phonesTexarea}
                onChange={(e) => {
                  setPhonesTexarea(e.target.value), updatePhonesLength(e.target.value, setValue);
                }}
                onClick={() => {
                  clearPhonesTextarea(), setValue('phone_list', '');
                }}
                phonesCount={phonesCount}
              />
              {isTelegramSelected && (
                <UsernamesField
                  usernamesTexarea={usernamesTexarea}
                  onChange={(e) => {
                    setUsernamesTexarea(e.target.value),
                      updateUsernamesLength(e.target.value, setValue);
                  }}
                  onClick={() => {
                    clearUsernamesTextarea(), setValue('usernames_list', '');
                  }}
                  usernamesCount={usernamesCount}
                />
              )}

              <TextareaField
                textTexarea={textTexarea}
                urlShorting={urlShorting}
                shortLinkEnabled={shortLinkEnabled}
                recountSms={recountSms}
                onChange={(e) => {
                  setTextTexarea(e.target.value);
                  setValue('text', e.target.value);
                }}
                onClick={() => {
                  clearSmsTextarea(), setValue('text', '');
                }}
                onChangeCheckbox={(e) => {
                  setValue('url_shorting', e.target.checked);
                  setUrlShorterChecked(e.target.checked);
                }}
              />

              {showUploadButton && (
                <UploadImage
                  imageFile={imageFile}
                  onFileChange={(file) => {
                    setImageFile(file), setValue('image_file', file);
                  }}
                  isTelegramBotSelected={isTelegramBotSelected}
                  isSmsSelected={isSmsSelected}
                />
              )}

              <SmsProgressBar
                isSmsSelected={isSmsSelected}
                isWhatsAppSelected={isWhatsAppSelected}
                lengthValue={smsLengthValue}
                countValue={smsCountValue}
                timeValue={whatsAppMessageTimeDelivery}
              />
              <Actions
                isLoading={isLoading}
                defaultTimezone={defaultTimezone}
                timezones={timezones}
                setValue={setValue}
                isSmsSelected={isSmsSelected}
              />
            </Form>
          </section>
        </Col>
        <Col span={0} lg={10} xl={10}>
          <SmsPresenter sender_name={senders[0]} text={messagePresenter} imageFile={imageFile} />
        </Col>
      </Row>
    </div>
  );
};

const formatHoursText = (hours) => {
  if (hours.length === 2 && hours[0] === 1) {
    return `${hours} ${t('hours_prepositional')} `;
  }

  switch (hours.slice(-1)) {
    case '1':
      return `${hours} ${t('hour_nominative')} `;

    case '2':
    case '3':
    case '4':
      return `${hours} ${t('hour_counting')} `;

    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
    case '0':
      return `${hours} ${t('hours_prepositional')} `;
  }
};

const formatMinutesText = (minutes) => {
  if (minutes.length === 2 && minutes[0] === 1) {
    return `${minutes} ${t('minutes_genitive')}`;
  }
  switch (minutes.slice(-1)) {
    case '1':
      return `${minutes} ${t('minute_accusative')}`;

    case '2':
    case '3':
    case '4':
      return `${minutes} ${t('minute_genitive')}`;

    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
    case '0':
      return `${minutes} ${t('minutes_genitive')}`;
  }
};

const formatTimeText = (text) => {
  let time = text.split(' ');
  let hours = time[0];
  let minutes = time[1];
  let formattedMessage = '';
  formattedMessage += formatHoursText(hours);
  formattedMessage += formatMinutesText(minutes);

  return formattedMessage;
};

const ruProgressBar = (len, smsTotal) => {
  $('#en_bar').css({ display: 'none' });
  $('#ru_bar').css({ display: 'inline' });
  $('#ru_bar_line').css({ width: `${calc_text_precent(len, smsTotal, -0.15, -0.1)}% ` });
};

const enProgressBar = (len, smsTotal) => {
  $('#ru_bar').css({ display: 'none' });
  $('#en_bar').css({ display: 'inline' });
  $('#en_bar_line').css({ width: `${calc_text_precent(len, smsTotal, 0.45, -0.9)}% ` });
};

function calc_text_precent(len, smsTotal, add_value, remove_value) {
  let maxLen = 938;
  if (smsTotal == 1) {
    return (len / maxLen) * 100 + add_value;
  } else if (smsTotal > 1 && smsTotal < 5) {
    return (len / maxLen) * 100 - remove_value;
  } else if (len > maxLen) {
    return 100;
  } else {
    return (len / maxLen) * 100;
  }
}

const smsPhoneHeaderDisplay = (value) => {
  $('#sms_presenter_header').text(value);
};

const shortLinksInText = (text) => {
  let urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  if ($('#url_shorter').prop('checked')) {
    return text.replace(urlRegex, 'p-sms.ru/testme');
  } else {
    return text;
  }
};

export default NewView;
