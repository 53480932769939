import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="2" width="28" height="28" rx="6" fill="#2789F6" />
        <path
          d="M22.9233 18.3341C22.7079 18.0418 22.6596 17.7459 22.7774 17.4461C23.0711 16.6463 27.3494 11.5661 25.5671 11.5661C24.3424 11.5824 22.8696 11.5661 21.6954 11.5661C21.4775 11.6192 21.3364 11.7336 21.2482 11.9663C20.5616 13.5495 19.7214 15.4815 18.4901 16.7231C18.3281 16.8538 18.2271 16.8482 18.0425 16.7999C17.1788 15.8905 18.0998 12.7751 17.5745 11.5441C17.471 11.3031 17.2444 11.1942 17.0125 11.133C15.8399 10.8535 13.1617 11.0198 12.7247 11.5824C12.5998 11.7432 12.5824 11.831 12.6725 11.8454C13.0889 11.9111 13.3836 12.0682 13.5571 12.3167C13.8844 13.0374 14.1256 16.8764 13.1617 16.8764C12.1978 16.8764 10.6435 13.1088 10.2165 12.0644C10.1029 11.7499 9.81365 11.5696 9.50887 11.5164L6.65729 11.5382C6.15606 11.5382 5.87592 11.7854 6.05358 12.2836C7.53881 15.9703 10.789 23.1164 15.7739 22.9375C16.2733 22.9375 17.1324 23.1292 17.5224 22.6963C18.0584 21.9852 17.4047 20.7188 18.3237 20.2359C18.5555 20.1126 18.7958 20.2159 18.9949 20.3676C20.0172 21.146 20.5599 22.5122 21.8206 22.927C22.0422 23 22.2365 23.0183 22.4032 22.9818L25.0674 22.938C25.562 22.938 26.0492 22.7179 25.9469 22.1266C25.5585 20.8585 24.0108 19.555 22.9233 18.3341Z"
          fill="white"
        />
      </svg>
    </SVGUniqueID>
  );
};
