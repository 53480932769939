import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Space, notification } from 'antd';
import axios from 'axios';

import translator from '@webpack/translator';
const t = translator('front_office.views.registration.sign_up.change_email.');

const ChangeEmail = ({ customer, setCustomerData, setSection }) => {
  const onFinish = (values) => {
    axios({
      method: 'patch',
      url: `/front_office/change_customer_email/${customer.id}`,
      data: values,
      headers: {
        Accept: 'application/json',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    }).then(({ data }) => {
      if (data.success) {
        setCustomerData(data.customer);
        setSection('email');
      } else {
        data.errors.forEach((error) =>
          notification['error']({ description: error, placement: 'top' }),
        );
      }
    });
  };

  return (
    <>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.List name="customer">
          {(_fields) => (
            <>
              <Form.Item label={t('email_label')} name="email">
                <Input />
              </Form.Item>
              <Form.Item>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Button block={true} type="primary" htmlType="submit">
                    {t('change_button')}
                  </Button>
                  <Button
                    block={true}
                    type="default"
                    onClick={() => setSection('email')}
                    htmlType="button"
                  >
                    {t('cancel_button')}
                  </Button>
                </Space>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </>
  );
};

export default ChangeEmail;
