import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Space, notification } from 'antd';
import axios from 'axios';

import translator from '@webpack/translator';
const t = translator('front_office.views.registration.sign_up.confirm_email.');

const ConfirmEmail = ({ customer, setSection }) => {
  const [disableResend, setDisableResend] = useState(true);

  const smsTimer = () => {
    const timerBlock = document.getElementById('update_sms');

    let seconds = 60;
    const timerId = setInterval(function () {
      timerBlock.innerHTML = t('resend_button_seconds', { seconds: --seconds });
    }, 1000);

    setTimeout(function () {
      clearInterval(timerId);
      timerBlock.innerHTML = t('resend_button');
      setDisableResend(false);
    }, seconds * 1000);
  };

  const onClickResendEmail = () => {
    setDisableResend(true);

    const data = {
      customer: {
        email: customer.email,
      },
    };

    axios({
      method: 'post',
      url: '/customers/confirmation',
      data: data,
      headers: {
        Accept: 'application/json',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    }).then(() => {
      smsTimer();
    });
  };

  useEffect(() => {
    smsTimer();
  }, []);

  return (
    <>
      <div>
        <h3 className="title">{t('confirm_email_title')}</h3>
        <p>
          <span
            dangerouslySetInnerHTML={{ __html: t('confirm_email_text', { email: customer.email }) }}
          ></span>
        </p>
      </div>

      <Form layout="vertical">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Button
            id="update_sms"
            block={true}
            type="primary"
            disabled={disableResend}
            onClick={() => onClickResendEmail()}
            htmlType="button"
          >
            {t('resend_button_seconds', { seconds: 60 })}
          </Button>
          <Button
            block={true}
            type="default"
            htmlType="button"
            onClick={() => setSection('change_email')}
          >
            {t('change_email_button')}
          </Button>
        </Space>
      </Form>
    </>
  );
};

export default ConfirmEmail;
