/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Support component names relative to this directory:
import './styles.scss';

var componentRequireContext = require.context('webpack', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

const img = require.context('../img', true);

console.log('turbo 2 loaded');

import {
  go_to_step,
  play_firework,
  init_whats_app,
  select_dispatch_routing,
  whats_app_open_modal,
  restart_step_one,
  change_delivery_length_limit,
} from './utils_scripts';

window.go_to_step = go_to_step;
window.play_firework = play_firework;
window.init_whats_app = init_whats_app;
window.select_dispatch_routing = select_dispatch_routing;
window.whats_app_open_modal = whats_app_open_modal;
window.restart_step_one = restart_step_one;
window.change_delivery_length_limit = change_delivery_length_limit;
