import React from 'react';
import {SVGUniqueID} from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M1.5 8.7C1.5 6.17976 1.5 4.91965 1.99047 3.95704C2.4219 3.11031 3.11031 2.4219 3.95704 1.99047C4.91965 1.5 6.17976 1.5 8.7 1.5H15.3C17.8202 1.5 19.0804 1.5 20.043 1.99047C20.8897 2.4219 21.5781 3.11031 22.0095 3.95704C22.5 4.91965 22.5 6.17976 22.5 8.7V15.3C22.5 17.8202 22.5 19.0804 22.0095 20.043C21.5781 20.8897 20.8897 21.5781 20.043 22.0095C19.0804 22.5 17.8202 22.5 15.3 22.5H8.7C6.17976 22.5 4.91965 22.5 3.95704 22.0095C3.11031 21.5781 2.4219 20.8897 1.99047 20.043C1.5 19.0804 1.5 17.8202 1.5 15.3V8.7Z"
          fill="url(#paint0_linear_662_13509)"
        />
        <path
          d="M12 17.25C15.7279 17.25 18.75 14.7316 18.75 11.625C18.75 8.5184 15.7279 6 12 6C8.27208 6 5.25 8.5184 5.25 11.625C5.25 13.5941 6.46417 15.3269 8.30265 16.3319C8.2411 16.832 8.0561 17.4995 7.5 18C8.55159 17.8107 9.39085 17.4303 10.0178 17.0036C10.6446 17.1638 11.3102 17.25 12 17.25Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_662_13509"
            x1="12"
            y1="1.5"
            x2="12"
            y2="22.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5AF575" />
            <stop offset="1" stopColor="#13BD2C" />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  );
};
