import React from 'react';
import { Container, Row, Cell } from '@components/grid';

import translator from '@webpack/translator';
const t = translator('front_office.views.payments.variants.canceled.');

const Canceled = ({ edit_customer_url }) => (
  <Container>
    <Row>
      <Cell md={4} lg={4}></Cell>
      <Cell md={4} lg={4}>
        <div className="payment-header end">
          <h3>{t('payment_failed_1')}</h3>
          <h3>{t('payment_failed_2')}</h3>
          <div className="desc">
            {t('payment_failed_desc')}
          </div>
          <a href={edit_customer_url} className="btn_v2-primary">
            {' '}{t('turn_on_notifications')}{' '}
          </a>
        </div>
      </Cell>
      <Cell md={4} lg={4}></Cell>
    </Row>
  </Container>
);

export default Canceled;
