import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_53_6429)">
          <path
            d="M1.41483 7.12027C0.634688 7.12027 0 7.75496 0 8.5351V15.4649C0 16.245 0.634688 16.8797 1.41483 16.8797H2.36419V7.12027H1.41483Z"
            fill="#161615"
          />
          <path
            d="M19.5542 5.64423H16.468C15.6878 5.64423 15.0531 6.27891 15.0531 7.05905V11.1509H8.94613V7.05905C8.94613 6.27891 8.31145 5.64423 7.53135 5.64423H4.4451C3.66496 5.64423 3.03027 6.27891 3.03027 7.05905V16.941C3.03027 17.7211 3.66496 18.3558 4.4451 18.3558H7.53135C8.31149 18.3558 8.94613 17.7211 8.94613 16.941V12.8487H15.0531V16.941C15.0531 17.7211 15.6878 18.3558 16.468 18.3558H19.5542C20.3344 18.3558 20.9691 17.7211 20.9691 16.941V7.05905C20.9691 6.27891 20.3344 5.64423 19.5542 5.64423Z"
            fill="#161615"
          />
          <path
            d="M22.5851 7.12027H21.6357V16.8797H22.5851C23.3652 16.8797 23.9999 16.2451 23.9999 15.4649V8.5351C23.9999 7.75496 23.3652 7.12027 22.5851 7.12027Z"
            fill="#161615"
          />
        </g>
        <defs>
          <clipPath id="clip0_53_6429">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  );
};
