import React from 'react';
import { Row, Col, Form, Button, Select, Tooltip, DatePicker, TimePicker, Switch } from 'antd';
import moment from 'moment';
import LoadingSpin from '@front_office/components/bulk_deliveries/LoadingSpin';
import Helper from '@webpack/helper.js';

import translator from '@webpack/translator';
const t = translator('front_office.views.bulk_deliveries.form.actions.');

import question_circle from '@imgs/front_office/bulk_deliveries/question_circle.svg';
import v1_select_arrow from '@imgs/front_office/form/v1_select_arrow.svg';

const { Option } = Select;

const buildScheduledAt = (setValue) => {
  let scheduled_date = $('#scheduled_date')[0].value || moment().format('DD-MM-YYYY');
  let scheduled_time = $('#scheduled_time')[0].value || moment().format('HH:mm');
  let scheduled_timezone = $('#bulk_deliveries_base_timezone')[0].value || '+03:00';

  let scheduled_at = `${scheduled_date} ${scheduled_time} ${scheduled_timezone}`;

  $('#bulk_sch_date').html(scheduled_date);
  $('#bulk_sch_time').html(scheduled_time);
  $('#bulk_sch_timezone').html(scheduled_timezone);
  setValue('scheduled_at', scheduled_at);
};

const showScheduledBox = (checked, setValue) => {
  if (checked == true) {
    $('#scheduled_box').css('max-height', '1000px');
    $('#scheduled_help').css('max-height', '1000px');
    $('#scheduled_help').css('visibility', 'initial');
    buildScheduledAt(setValue);
  } else {
    $('#scheduled_box').css('max-height', '0');
    $('#scheduled_help').css('max-height', '0');
    $('#scheduled_help').css('visibility', 'hidden');
    setValue('scheduled_at', '');
  }
};

const Actions = ({ setValue, defaultTimezone, timezones, isLoading, isSmsSelected }) => {
  const currentDate = moment().format('DD-MM-YYYY');
  const currentTime = moment().format('HH:mm');
  const disabledDate = (current) => {
    return moment().add(-1, 'days') >= current || moment().add(1, 'month') <= current;
  };

  return (
    <Form.Item>
      <Row className="form-group actions" gutter={30}>
        <Col span={0} lg={8} xl={8}>
          <Button disabled={isLoading} htmlType="submit" className="btn btn-primary">
            {isLoading && <LoadingSpin />}
            {isSmsSelected ? t('calculate_and_send') : t('send')}
          </Button>
        </Col>
        <Col span={24} md={12} lg={8} xl={8}>
          <div className="grey_box">
            <div className="grey_box-title">{t('delayed_sending')}</div>
            <Tooltip
              placement="top"
              title={<span>{t('delayed_sending_tooltip')}</span>}
              arrowPointAtCenter
            >
              <img src={question_circle} className="img question_circle"></img>
            </Tooltip>
            <Switch
              id="schedule_checkbox"
              size="small"
              defaultChecked={false}
              onChange={(checked, _e) => showScheduledBox(checked, setValue)}
            />
          </div>
          <div id="scheduled_box" className="scheduled_box">
            <div className="scheduled_box-datetime">
              <div className="scheduled_box-datetime_field">
                <input type="hidden" name="scheduled_at" />
                <DatePicker
                  id="scheduled_date"
                  size="large"
                  format={'DD-MM-YYYY'}
                  placeholder={t('select_date')}
                  defaultValue={moment()}
                  showToday={false}
                  onChange={(_value) => buildScheduledAt(setValue)}
                  disabledDate={disabledDate}
                />
              </div>
              <div className="scheduled_box-datetime_field">
                <TimePicker
                  id="scheduled_time"
                  size="large"
                  format={'HH:mm'}
                  showNow={false}
                  placeholder={t('select_time')}
                  onChange={(_value) => buildScheduledAt(setValue)}
                  defaultValue={moment()}
                />
              </div>
            </div>
            <input type="hidden" id="bulk_deliveries_base_timezone" value={Helper.formatUTCOffset(Helper.getUTCOffset(defaultTimezone))} />
            <Select
              size="large"
              showSearch
              filterOption={(inputValue, option) => (option?.label?.toLowerCase() ?? '').includes(inputValue.toLowerCase())}
              popupClassName="bulk_deliveries-ant-select-custom-dropdown"
              defaultValue={defaultTimezone}
              suffixIcon={<img src={v1_select_arrow} />}
              onChange={(value) => {
                let [day, month, year] = ($('#scheduled_date')[0].value || moment().format('DD-MM-YYYY')).split('-');
                let offset = Helper.formatUTCOffset(Helper.getUTCOffset(value, new Date(year, month, day)));
                $('#bulk_deliveries_base_timezone').val(offset), buildScheduledAt(setValue);
              }}
            >
              {timezones.map((timezone) => (
                <Option key={timezone[1]} value={timezone[1]} label={timezone[0]}>
                  {timezone[0]}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={24} lg={8} xl={8}>
          <p className="help-block" id="scheduled_help">
            {t('delayed_sending_restrictions')} <b>{t('scheduled_time_limit')}</b>{' '}
            {t('sending_will_start')} <b id="bulk_sch_date">{currentDate}</b> {t('at')}{' '}
            <b id="bulk_sch_time">{currentTime}</b>. {t('timezone')} —{' '}
            <b>
              GMT <span id="bulk_sch_timezone">+03:00</span>
            </b>
            .
          </p>
        </Col>
        <Col span={24} lg={0} xl={0}>
          <Button disabled={isLoading} htmlType="submit" className="btn btn-primary">
            {isLoading && <LoadingSpin />}
            {t('calculate_and_send')}
          </Button>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default Actions;
