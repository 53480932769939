import React from 'react';

export default ({ background = '#FFE177', height = 20 }) => {
  return (
    <svg
      width="18"
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.191 0H4.81C1.77 0 0 1.78 0 4.83V15.16C0 18.26 1.77 20 4.81 20H13.191C16.28 20 18 18.26 18 15.16V4.83C18 1.78 16.28 0 13.191 0"
        fill={background}
      />
      <path
        d="M12.9208 10.74H5.08078C4.64978 10.74 4.30078 10.39 4.30078 9.96002C4.30078 9.53002 4.64978 9.17902 5.08078 9.17902H12.9208C13.3508 9.17902 13.7008 9.53002 13.7008 9.96002C13.7008 10.39 13.3508 10.74 12.9208 10.74Z"
        fill="#1F1F1F"
      />
      <path
        d="M12.9209 15.31H5.08094C4.78094 15.35 4.49094 15.2 4.33094 14.95C4.17094 14.69 4.17094 14.36 4.33094 14.11C4.49094 13.85 4.78094 13.71 5.08094 13.74H12.9209C13.3199 13.78 13.6209 14.12 13.6209 14.53C13.6209 14.929 13.3199 15.27 12.9209 15.31Z"
        fill="#1F1F1F"
      />
      <path
        d="M5.08078 4.65999V4.64999H8.06978C8.50078 4.64999 8.85078 4.99999 8.85078 5.42899C8.85078 5.86999 8.50078 6.21999 8.06978 6.21999H5.08078C4.64978 6.21999 4.30078 5.86999 4.30078 5.43999C4.30078 5.00999 4.64978 4.65999 5.08078 4.65999Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
