import React from 'react';
import { Modal, Button, Result } from 'antd';
import bulk_success from '@imgs/front_office/bulk_deliveries/bulk_success.svg';

import translator from '@webpack/translator';
const t = translator('front_office.views.bulk_deliveries.modals.success.');

const Success = ({ visible, onCancel, response }) => {
  const { title, desc } = response;

  return (
    <Modal centered visible={visible} onCancel={onCancel} maskClosable={true} footer={null}>
      <Result
        icon={<img src={bulk_success} />}
        title={title}
        subTitle={desc}
        extra={
          <Button type="primary" onClick={() => onCancel()}>
            {t('make_another_delivery')}
          </Button>
        }
      />
    </Modal>
  );
};

export default Success;
