import React from 'react';
import { Switch, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import RowHandlerIcon from '../Icons/RowHandlerIcon';
import WhatsappIcon from '../Icons/WhatsappIcon';
import TelegramIcon from '../Icons/TelegramIcon';
import TelegramBotIcon from '../Icons/TelegramBotIcon';
import VkOkIcon from '../Icons/VkOkIcon';
import SmsIcon from '../Icons/SmsIcon';

import translator from '@webpack/translator';
const t = translator('front_office.views.integrations.channel_info.');

interface Props {
  disabled?: boolean,
  channel: [string, boolean];
  handleSwitch: (arg1: boolean, arg2: string) => void;
}

export default ({ disabled, channel: [channelName, isActive], handleSwitch }: Props) => {

  const channelsIconsAndText = {
    whatsapp: {
      title: t('whatsapp_title'),
      icon: <WhatsappIcon />,
      desc: t('whatsapp_desc'),
    },
    telegram: {
      title: t('telegram_bot_title'),
      icon: <TelegramBotIcon />,
      desc: t('telegram_bot_desc'),
    },
    sms: {
      title: t('sms_title'),
      icon: <SmsIcon />,
      desc: t('sms_desc'),
    },
    notify: {
      title: t('vk_ok_title'),
      icon: <VkOkIcon />,
      desc: t('vk_ok_desc'),
    },
    tdlib: {
      title: t('telegram_title'),
      icon: <TelegramIcon />,
      desc: t('telegram_desc'),
    },
  };

  const { desc, icon, title } = channelsIconsAndText[channelName];
  return (
    <>
      <div className="d-flex align-items-center">
        <RowHandlerIcon style={{ marginRight: '13px' }} />
        <span style={{ marginRight: '9px' }}>{icon}</span>
        <span style={{ marginRight: '13px' }}>{title}</span>
        <Tooltip title={desc}>
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
      <Switch disabled={disabled} onChange={() => handleSwitch(isActive, channelName)} checked={isActive} />
    </>
  );
};

