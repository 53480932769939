import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_53_6409)">
          <path
            d="M8.00508 13.6794H3.929C1.79274 13.6794 -0.0243087 15.4277 0.000246 17.5591C0.0100679 18.4529 0.329279 19.273 0.849838 19.9212L0.501162 20.486C0.245793 20.9034 0.309635 21.4436 0.653401 21.7874C1.06592 22.1999 1.73872 22.1999 2.15124 21.7874L2.75037 21.1882C3.212 21.3258 3.708 21.3749 4.22365 21.3258C5.87864 21.1637 7.25861 19.9114 7.59256 18.281C7.71042 17.7064 7.69078 17.1515 7.568 16.6309H8.31446C8.75645 16.6309 9.18861 16.4836 9.5422 16.2135L24 5.05581C23.7888 4.64329 23.4942 4.29953 23.1504 4.03434C22.0749 3.22403 20.5722 3.31734 19.5458 4.18166L13.0093 9.71138L9.5422 7.03492C9.19352 6.76482 8.76136 6.61749 8.31446 6.61749H7.568C7.69078 6.10184 7.71042 5.5469 7.59256 4.96741C7.25861 3.33698 5.87864 2.08469 4.22365 1.92263C1.94498 1.69673 0.0248007 3.46958 0.000246 5.69423C-0.0243087 7.83049 1.79274 9.57387 3.929 9.57387H8.00508C8.20642 9.57387 8.40286 9.64754 8.56001 9.77522L10.7454 11.6266L8.56001 13.4781C8.40286 13.6107 8.20642 13.6794 8.00508 13.6794ZM3.83569 19.2533C2.87314 19.2533 2.09722 18.4725 2.09722 17.5149C2.09722 16.5523 2.87806 15.7764 3.83569 15.7764C4.79823 15.7764 5.57416 16.5572 5.57416 17.5149C5.57416 18.4774 4.79332 19.2533 3.83569 19.2533ZM3.83569 7.48181C2.87314 7.48181 2.09722 6.70098 2.09722 5.74334C2.09722 4.78571 2.87314 3.99996 3.83569 3.99996C4.79823 3.99996 5.57416 4.7808 5.57416 5.73843C5.57416 6.69606 4.79332 7.48181 3.83569 7.48181ZM16.6581 12.5695L23.9951 18.1926C23.8134 18.556 23.5629 18.8654 23.2732 19.1109C22.2026 20.0194 20.6115 19.9703 19.536 19.0667L14.129 14.5192L16.6581 12.5695Z"
            fill="#161615"
          />
        </g>
        <defs>
          <clipPath id="clip0_53_6409">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  );
};
