import React, { useState, useEffect } from 'react';
import { Menu, Drawer } from 'antd';
import LogoCollection from '@front_office/Icons/logo/LogoCollection';

function labelLink(link) {
  if (link.href) {
    return (
      <a href={link.href} data-method={link.method}>
        {link.title}
      </a>
    );
  } else {
    return link.title;
  }
}

function formatItem({ link, key, icon, children, type }) {
  const label = labelLink(link);
  const child = children && children.map((ch) => formatItem(ch));

  return {
    key: key,
    icon: icon,
    children: child,
    label: label,
    type: type,
  };
}
const MobileSidebar = ({ open, setOpen, items, logo, activeItem }) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      open={open}
      placement="left"
      maskClosable={true}
      closable={false}
      closeIcon={false}
      onClose={onClose}
      title={logo}
      className="sidebar_v2-mobile_menu"
      width={264}
    >
      <Menu
        rootClassName="sidebar_v2-body__menu"
        style={{
          width: '100%',
        }}
        mode="inline"
        items={items}
        selectedKeys={[activeItem]}
        defaultSelectedKeys={[activeItem]}
      />
    </Drawer>
  );
};

const DesktopSidebar = ({ items, logo, activeItem, openedKeys }) => {
  return (
    <div className="sidebar_v2-body">
      <div className="sidebar_v2-body__logo">{logo}</div>
      <Menu
        rootClassName="sidebar_v2-body__menu"
        style={{
          width: 256,
        }}
        mode="inline"
        items={items}
        selectedKeys={[activeItem]}
        defaultSelectedKeys={[activeItem]}
        defaultOpenKeys={openedKeys}
      />
    </div>
  );
};

const Sidebar = ({ project_scope, menu, current_path, opened_keys, active_item }) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const mobileMenuStateSwitcher = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  const formatedItems = menu.map((item) => formatItem(item));

  const logo = () => {
    const Logo = LogoCollection[project_scope];
    return (
      <a className="sidebar_v2-logo" href="/front_office/index">
        <Logo />
      </a>
    );
  };

  return (
    <>
      <MobileSidebar
        activeItem={current_path}
        items={formatedItems}
        open={openMobileMenu}
        setOpen={setOpenMobileMenu}
        logo={logo()}
      />
      <DesktopSidebar activeItem={active_item} items={formatedItems} logo={logo()} openedKeys={opened_keys} />
      <div id="sidebar_mobile_button_trigger" onClick={mobileMenuStateSwitcher}></div>
    </>
  );
};

export default Sidebar;
