import React from 'react';
import {SVGUniqueID} from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect
          x="2.15925"
          y="3.53369"
          width="21"
          height="21"
          rx="6"
          transform="rotate(-3 2.15925 3.53369)"
          fill="#2789F6"
        />
        <path
          d="M18.4714 14.9462C18.2986 14.7357 18.2508 14.516 18.3272 14.2869C18.5158 13.6763 21.5207 9.70344 20.1859 9.7734C19.2692 9.83366 18.1655 9.87928 17.2861 9.92537C17.1249 9.97366 17.0238 10.0649 16.9668 10.2426C16.5147 11.4553 15.9613 12.9353 15.0878 13.9136C14.9716 14.0178 14.8957 14.0176 14.7556 13.9887C14.073 13.3415 14.6405 10.972 14.1988 10.0706C14.1117 9.89418 13.9378 9.82155 13.7617 9.78475C12.8725 9.62147 10.8731 9.85118 10.5679 10.2897C10.4806 10.415 10.471 10.4814 10.5391 10.4887C10.8536 10.5216 11.0805 10.6277 11.2202 10.8069C11.4936 11.3339 11.8249 14.1997 11.103 14.2376C10.3811 14.2754 9.06906 11.5146 8.70826 10.7491C8.61081 10.518 8.38709 10.3943 8.15672 10.3664L6.02183 10.4947C5.64641 10.5144 5.44631 10.7106 5.59892 11.0767C6.85602 13.7796 9.57081 19.0043 13.2974 18.6746C13.6714 18.655 14.3223 18.7649 14.5975 18.4253C14.971 17.8717 14.4317 16.9489 15.1011 16.5511C15.2698 16.4497 15.4538 16.5177 15.6089 16.6234C16.4051 17.1663 16.8653 18.1683 17.8257 18.4294C17.9946 18.4754 18.1408 18.4815 18.2643 18.4476L20.2579 18.3102C20.6284 18.2908 20.9847 18.1069 20.8848 17.668C20.5441 16.7335 19.3338 15.8179 18.4714 14.9462Z"
          fill="white"
        />
      </svg>
    </SVGUniqueID>
  );
};
