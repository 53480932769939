import React from 'react';
import { useState } from 'react';
import QuestionView from './QuestionView';
import SiteSettings from './SiteSettings';
import TelegramChatImg from '../../../Icons/TelegramChatImg';

import translator from '@webpack/translator';
const t = translator('front_office.views.integrations.telegram_bot.question_step.');

interface Props {
  setStep: (arg: string) => void;
  telegramBotsUrl: string;
  customerBotMenuTitle: string;
  customerBotMenuLink: string;
}

export default ({ setStep, telegramBotsUrl, customerBotMenuTitle, customerBotMenuLink }: Props) => {
  const [hasSiteLink, setHasSiteLink] = useState<boolean>(false);
  const [menuTitle, setMenuTitle] = useState(customerBotMenuTitle || t('default_menu_title'));
  return (
    <div className="telegram-flow_split-pane">
      {hasSiteLink ? (
        <>
          <div className="telegram-flow_chat-img">
            <TelegramChatImg menuTitle={menuTitle} />
          </div>
          <SiteSettings
            setStep={setStep}
            menuTitle={menuTitle}
            menuLink={customerBotMenuLink}
            setMenuTitle={setMenuTitle}
            telegramBotsUrl={telegramBotsUrl}
          />
        </>
      ) : (
        <QuestionView
          showSiteLinkForm={() => setHasSiteLink(true)}
          moveToLastStep={() => setStep('lastStep')}
        />
      )}
    </div>
  );
};
