import React from 'react';
import { Row, Col } from 'antd';
import { InfoPane } from '../InfoPane';
import { FooterButtons } from '../FooterButtons';
import DocumentIcon from '../../../Icons/DocumentIcon';

import translator from '@webpack/translator';
const t = translator('front_office.views.integrations.telegram_tdlib.notification.');

export const Notification = ({
  setStep,
  forwardStep,
  backwardStep,
  backAction,
  authorizedNumber,
  setCustomerHasTdLib,
}) => {
  const handleNextAction = () => {
    authorizedNumber ? sendResetSession() : setStep(forwardStep);
  };

  const sendResetSession = () => {
    const queryOptions = {
      method: 'PATCH',
      headers: {
        Accept: 'text/javascript',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    };

    const requestServer = async (url, options = {}) => {
      const response = await fetch(url, options);
      const body = await response.json();
      return body;
    };
    const handleResponse = (body: { errors: [any]; success: string }) => {
      if (body.success) {
        setCustomerHasTdLib(false);
        setStep(forwardStep);
      } else {
        console.error("sever error");
      }
    };

    requestServer('/front_office/reset_session', queryOptions)
      .then((body) => handleResponse(body))
      .catch((error) => {
        console.error("fetch error");
      });
  };

  return (
    <>
      <Row>
        <Col>
          <InfoPane title={t('note_title')} icon={<DocumentIcon />}>
            {authorizedNumber ? (
              <p dangerouslySetInnerHTML={{__html: t('linked_number_text', { number: authorizedNumber })}}></p>
            ) : (
              <p>
                {t('telegram_note_text_1')}
                <br />
                <br />
                {t('telegram_note_text_2')}
              </p>
            )}
          </InfoPane>
        </Col>
      </Row>
      <Row>
        <Col>
          <FooterButtons
            backwardAction={() => backAction(backwardStep)}
            forwardAction={handleNextAction}
          />
        </Col>
      </Row>
    </>
  );
};
