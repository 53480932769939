import React, { useState } from 'react';
import { Row, Col, Form, Input } from 'antd';
import Button from '@components/Button';
import { InfoPane } from '../InfoPane';
import VideoView from '../VideoView';
import classNames from 'classnames';

import translator, { locale_for_video } from '@webpack/translator';
const t = translator('front_office.views.integrations.telegram_tdlib.code_confirmation.');

export const CodeConfirmation = ({ setStep, backwardStep, passwordStep, routingSelectorStep, setCustomerHasTdLib }) => {
  const [inputStatus, setInputStatus] = useState({});
  const [formDisabled, setFormDisabled] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const onFinish = ({ confirmationCode }) => {
    sendCode(confirmationCode);
    setInputStatus({
      validateStatus: 'validating',
      help: t('sending_code_help_text'),
    });
  };
  const setError = (errorMessage) => {
    setInputStatus({
      validateStatus: 'error',
      help: errorMessage,
    });
  };

  const handleInputChange = ({ target }) => {
    setInputStatus({});
    setButtonDisabled(target.value ? false : true);
  };

  const sendCode = (code: string) => {
    setFormDisabled(true);
    setButtonDisabled(true);
    const tdlibData = new FormData();
    tdlibData.append('tdlib_session[code]', code);

    const queryOptions = {
      method: 'PATCH',
      headers: {
        Accept: 'text/javascript',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      body: tdlibData,
    };

    const requestServer = async (url, options = {}) => {
      const response = await fetch(url, options);
      const body = await response.json();
      setFormDisabled(false);
      setButtonDisabled(false);
      return body;
    };

    const handleResponse = (body) => {
      if (body.success) {
        const handleStatus = {
          authorized: () => { setCustomerHasTdLib(true); setStep(routingSelectorStep); },
          wait_code: () => setError(t('wrong_code_error')),
          wait_password: () => setStep(passwordStep),
        };
        handleStatus[body.success]();
      } else {
        console.error(t('server_error'));
      }
    };

    requestServer('/front_office/send_code', queryOptions)
      .then((body) => handleResponse(body))
      .catch((error) => {
        console.error('Cant fetch', error);
        setError(t('server_error'));
      });
  };

  return (
    <div className="telegram-flow_split-pane">
      <VideoView link={`/step3_tdlib_${locale_for_video()}.mp4` }/>
      <div className="telegram-flow_split-pane_right">
        <Row>
          <Col>
            <InfoPane title={t('code_title')}>
              <p>{t('code_description')}</p>
            </InfoPane>
          </Col>
        </Row>
        <Form onFinish={onFinish} disabled={formDisabled}>
          <Row>
            <Col>
              <p className="telegram-flow_text">{t('enter_code_text')}</p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                {...inputStatus}
                name="confirmationCode"
                className="telegram-flow_bottom-spacing"
              >
                <Input
                  autoFocus
                  className="telegram-flow_input"
                  onChange={(event) => handleInputChange(event)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <div className="telegram-flow_footer">
                  <Button
                    htmlType='button'
                    className="btn_v2-secondary"
                    text={t('back_button_text')}
                    onClick={() => setStep(backwardStep)}
                  />
                  <Button
                    htmlType='submit'
                    type='primary'
                    className={classNames('btn_v2', {'btn_v2-primary_disabled': buttonDisabled})}
                    disabled={buttonDisabled}
                  >
                    {t('forward_button_text')}
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
