import React from 'react';
import { InActiveWhatsapp, Bulk, Success, NetworkError } from './';

const CurrentModal = ({
  response,
  whatsappActive,
  visibleModal,
  onCancel,
  successOnCancel,
  onOk,
  isLoading,
}) => {
  if (!whatsappActive) {
    return <InActiveWhatsapp centered visible={visibleModal} onCancel={onCancel} />;
  }

  if (!response) {
    return <NetworkError centered visible={visibleModal} onCancel={onCancel} />;
  }

  if (response && !response.success) {
    return (
      <Bulk
        centered
        visible={visibleModal}
        onOk={onOk}
        onCancel={onCancel}
        response={response}
        isLoading={isLoading}
      />
    );
  }

  if (response && response.success) {
    return (
      <Success centered visible={visibleModal} onCancel={successOnCancel} response={response} />
    );
  }

  return <div></div>;
};

export default CurrentModal;
