import React from 'react';

export default ({currency, ...props}) => {
  switch (currency.toUpperCase()) {
    case "USD":
      return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <g clipPath="url(#clip0_2523_7721)">
            <rect width="52" height="52" rx="8" fill="#FFE8AD" />
            <path d="M35.3042 16.0282C29.4716 15.7902 24.0113 17.0232 20.3274 21.0215C16.7198 24.9374 15.6119 28.1391 14.3605 33.4834C13.4998 37.146 14.3422 40.4697 15.1968 44.0499C16.1643 48.0756 18.9692 50.85 22.76 53.5237C26.5507 56.1974 33.6072 57.1863 36.6807 57.0611C40.1174 56.9268 42.9803 56.7956 46.2949 55.6877C48.7946 54.8544 52.7227 52.4554 54.1999 50.2793C57.4572 45.3869 58.6872 39.4208 57.6305 33.6391C56.6325 28.4107 54.0961 23.4571 49.9361 20.0143C46.8901 17.4963 41.1368 16.2694 35.3042 16.0282Z" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40 31.8146C37.3662 29.9741 34.1363 31.4013 34.0043 34.6018C33.8626 37.5428 37.2392 40.7528 39.8339 37.8743" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M34 43C35.5567 43.3113 37.46 43.692 39 44" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31.3184 16.1165C34.2301 16.0005 38.9792 16.9558 40.9905 18.1706C43.0019 19.3853 45.8129 21.3662 47.8731 24.1863C49.5701 26.512 51.3647 30.9346 51.6089 33.7242C51.9568 37.7164 50.8367 43.4911 48.7643 46.9339C47.809 48.5179 45.0377 51.8966 41.9214 54.027C38.8052 56.1574 36.0034 56.8655 32.4599 56.7739" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24 32.2701C25.3535 31.5562 26.714 30.8138 28 30C27.9361 33.9869 27.389 37.9774 27.389 42" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24.4946 41.5439C25.3279 42.1361 27.2415 42.0353 28.261 42.1544C29.0057 42.2337 29.6497 42.4413 30.4219 42.3833" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.7342 38.7361C17.6934 35.101 18.7769 31.3103 20.8127 28.2612C21.9298 26.5856 23.5596 24.5071 25.4123 23.6555C27.036 22.9139 28.7696 22.7399 30.4849 22.6147" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M36.8301 22.7188C38.8446 22.9307 40.7537 23.7251 42.3239 25.0048C44.2529 26.4973 45.4676 28.9482 46.1482 31.2708C46.858 33.7474 47.0162 36.3495 46.6117 38.8938C46.2073 41.4382 45.25 43.8629 43.8072 45.9974C42.8733 47.3617 40.8192 49.0495 39.3267 49.6599" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33.8242 50.9074C32.6338 51.1912 31.3336 50.8677 30.1616 50.5869" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.2292 48.4385C22.8669 47.0437 21.4324 45.4902 20.5015 42.9966" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M46.2944 22.0957C48.2478 22.5169 50.1859 22.0347 52.1179 22.1995" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M51.2881 29.894C52.3899 30.2298 55.3199 30.3824 56.4767 29.894" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M51.5996 39.9839C53.5469 39.9839 55.5674 39.9015 57.4231 39.9839" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M47.5488 49.3296C48.9284 49.7508 50.4728 49.4791 51.9164 49.5371C52.7588 49.5707 53.8393 49.351 54.6206 49.6409" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M43.6943 52.9834C45.9224 52.9834 48.1779 53.1238 50.351 53.1909" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_2523_7721">
              <rect width="52" height="52" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    case "RUB":
      return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <g clipPath="url(#clip0_1850_60697)">
            <rect width="52" height="52" rx="8" fill="#FFE8AD" />
            <path d="M36.8232 15.0303C30.5747 14.7752 24.7252 16.0962 20.7786 20.3796C16.9137 24.5747 15.7268 28.0046 14.3862 33.73C13.4641 37.6537 14.3666 41.2144 15.2821 45.0499C16.3186 49.3627 19.3235 52.3349 23.3846 55.1992C27.4456 58.0635 35.0053 59.1229 38.2979 58.9888C41.9796 58.8449 45.0467 58.7043 48.5976 57.5174C51.2755 56.6248 55.4837 54.0548 57.0663 51.7234C60.5558 46.4822 61.8735 40.0907 60.7415 33.8967C59.6723 28.2956 56.9551 22.9888 52.4984 19.3006C49.2352 16.603 43.0717 15.2886 36.8232 15.0303Z" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32.5527 15.125C35.6721 15.0007 40.7598 16.0242 42.9146 17.3255C45.0693 18.6269 48.0808 20.749 50.2879 23.7702C52.1058 26.2618 54.0285 30.9996 54.29 33.9882C54.6628 38.265 53.4628 44.4514 51.2426 48.1397C50.2192 49.8367 47.2503 53.4563 43.9119 55.7386C40.5734 58.0209 37.5718 58.7794 33.7756 58.6814" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.3145 33.6743C27.5602 33.0203 28.8125 32.3402 29.9962 31.5947C29.9373 35.247 29.4338 38.9026 29.4338 42.5876" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.3145 42.3652C27.2071 42.9996 29.2572 42.8917 30.3493 43.0192C31.1471 43.1042 31.8371 43.3265 32.6643 43.2644" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M36.5196 43.1254C36.5196 40.0409 36.6926 35.339 36.7874 33.3379C36.8757 31.1603 39.037 30.1891 40.7994 31.4415C42.6646 33.191 43.7656 35.5856 41.8072 37.0544C39.8488 38.5231 38.453 37.3684 34.5122 37.0544" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M35.1973 40.4321C36.7242 40.4321 38.5913 40.4321 40.1019 40.7591" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.0719 39.3573C17.957 35.463 19.1177 31.402 21.2986 28.1355C22.4954 26.3404 24.2414 24.1137 26.2262 23.2014C27.9657 22.4069 29.8229 22.2205 31.6605 22.0864" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M38.458 22.1978C40.6162 22.4249 42.6614 23.2759 44.3436 24.6468C46.41 26.2457 47.7114 28.8713 48.4406 31.3596C49.2009 34.0128 49.3704 36.8004 48.9371 39.5261C48.5038 42.2519 47.4783 44.8495 45.9327 47.1362C44.9321 48.5977 42.7316 50.4059 41.1327 51.0599" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M35.2372 52.397C33.962 52.7011 32.5691 52.3545 31.3135 52.0537" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.0297 49.7518C23.4989 48.2576 21.9621 46.5933 20.9648 43.9219" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M48.5977 21.5303C50.6903 21.9815 52.7666 21.4649 54.8363 21.6414" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M49.9414 50.7065C51.4193 51.1578 53.0738 50.8668 54.6204 50.9289C55.5229 50.9649 56.6804 50.7294 57.5174 51.0401" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_1850_60697">
              <rect width="52" height="52" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    default:
      return null;
  }
};
