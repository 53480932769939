import React from 'react';
import { Col, Row, Collapse } from 'antd';
import Xmark from '../imgs/Xmark';
import classNames from 'classnames';

const { Panel } = Collapse;

import translator from '@webpack/translator';
const t = translator('main_page.elements.faq.');

const Faq = () => {
  const panels = [
    {
      header: t('can_i_connect_multiple_channels_crm'),
      text: t('can_i_connect_multiple_channels_crm_answer'),
    },
    {
      header: t('did_not_see_my_crm_in_the_list'),
      text: t('did_not_see_my_crm_in_the_list_answer'),
    },
    {
      header: t('how_many_messages_can_i_send_to_messengers'),
      text: t('how_many_messages_can_i_send_to_messengers_answer'),
    },
    {
      header: t('can_i_continue_using_messengers_after_connecting_to_your_service'),
      text: t('can_i_continue_using_messengers_after_connecting_to_your_service_answer'),
    },
    {
      header: t('what_functionality_do_you_have_in_messengers'),
      text: t('what_functionality_do_you_have_in_messengers_answer'),
    },
  ];
  return (
    <div className="faq">
      <div className="container">
        <Row>
          <Col xs={0} md={0} lg={2} xl={4}></Col>
          <Col xs={24} md={24} lg={20} xl={16}>
            <div className="faq-body">
              <div className="faq-body-title">{t('title')}</div>
              <Collapse
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <div className={classNames('collapse-panel-xmark', isActive ? 'open' : 'close')}>
                    <Xmark />
                  </div>
                )}
              >
                {panels.map((panel) => (
                  <Panel key={panel.header} header={panel.header}>
                    <div>{panel.text}</div>
                  </Panel>
                ))}
              </Collapse>
            </div>
          </Col>
          <Col xs={0} md={0} lg={2} xl={4}></Col>
        </Row>
      </div>
    </div>
  );
};

export default Faq;
