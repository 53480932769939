import React from 'react';

export default (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="bell-ringing-02">
        <path
          d="M9.33353 14H6.66687M1.52943 3.87991C1.51986 2.91234 2.04152 2.00881 2.88423 1.53331M14.4683 3.87991C14.4779 2.91235 13.9562 2.00881 13.1135 1.53332M12.0002 5.33331C12.0002 4.27245 11.5788 3.25503 10.8286 2.50489C10.0785 1.75474 9.06106 1.33331 8.0002 1.33331C6.93933 1.33331 5.92192 1.75474 5.17177 2.50489C4.42163 3.25503 4.0002 4.27245 4.0002 5.33331C4.0002 7.39344 3.48051 8.80395 2.89998 9.73692C2.41028 10.5239 2.16544 10.9174 2.17442 11.0272C2.18436 11.1487 2.21011 11.195 2.30805 11.2677C2.3965 11.3333 2.79526 11.3333 3.59277 11.3333H12.4076C13.2051 11.3333 13.6039 11.3333 13.6923 11.2677C13.7903 11.195 13.816 11.1487 13.826 11.0272C13.835 10.9174 13.5901 10.5239 13.1004 9.73692C12.5199 8.80395 12.0002 7.39344 12.0002 5.33331Z"
          stroke="#161615"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
