import React from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row, Cell } from '@components/grid';
import Input from '@components/form/Input';
import Button from '@components/Button';

import translator from '@webpack/translator';
const t = translator('front_office.views.payments.variants.manual.');

const Manual = ({ setStep, setPaymentSum, smsImg }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if ($.isEmptyObject(errors)) {
      setPaymentSum(data.payment_sum);
      setStep('payment_method');
    }
  };

  const inputValidate = register('payment_sum', {
    required: true,
    valueAsNumber: true,
    validate: { positive: (value) => parseInt(value) > 0 },
  });
  const inputError = errors.payment_sum && <span>{t('payment_sum_validation_error')}</span>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Row>
          <Cell md={4} lg={4}></Cell>
          <Cell md={4} lg={4}>
            <div className="payment-header vertical">
              <div className="img_box">
                <img src={smsImg} />
              </div>
              <div>
                <h3>{t('balance_replenishment')}</h3>
                <h5>{t('how_much_we_put')}</h5>
              </div>
            </div>
          </Cell>
          <Cell md={4} lg={4}></Cell>
        </Row>
        <Row>
          <Cell md={4} lg={4}></Cell>
          <Cell md={4} lg={4}>
            <div className="payment-body">
              <Input
                type={'number'}
                name={'payment_sum'}
                validate={inputValidate}
                error={inputError}
              />
            </div>
          </Cell>
          <Cell md={4} lg={4}></Cell>
        </Row>
        <Row>
          <Cell md={4} lg={4}></Cell>
          <Cell md={4} lg={4}>
            <div className="payment-footer vertical">
              <Button htmlType={'submit'} className={'btn_v2-primary'} text={t('next_button')} />
              <Button
                className={'btn_v2-secondary'}
                text={t('back_button')}
                onClick={() => setStep('start')}
              />
            </div>
          </Cell>
          <Cell md={4} lg={4}></Cell>
        </Row>
      </Container>
    </form>
  );
};

export default Manual;
