import React from 'react';
import {SVGUniqueID} from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="12" cy="12" r="10.5" fill="url(#paint0_linear_662_13526)" />
        <path
          d="M11.8986 6.00685L12.0003 6C12.38 6 12.6938 6.28215 12.7435 6.64823L12.7503 6.75L12.7495 7.499L16.25 7.49951C17.4926 7.49951 18.5 8.50687 18.5 9.74951V14.2541C18.5 15.4967 17.4926 16.5041 16.25 16.5041H7.75C6.50736 16.5041 5.5 15.4967 5.5 14.2541V9.74951C5.5 8.50687 6.50736 7.49951 7.75 7.49951L11.2495 7.499L11.2503 6.75C11.2503 6.3703 11.5325 6.05651 11.8986 6.00685ZM9.74929 10.4995C9.05933 10.4995 8.5 11.0588 8.5 11.7488C8.5 12.4388 9.05933 12.9981 9.74929 12.9981C10.4393 12.9981 10.9986 12.4388 10.9986 11.7488C10.9986 11.0588 10.4393 10.4995 9.74929 10.4995ZM14.242 10.4995C13.5521 10.4995 12.9927 11.0588 12.9927 11.7488C12.9927 12.4388 13.5521 12.9981 14.242 12.9981C14.932 12.9981 15.4913 12.4388 15.4913 11.7488C15.4913 11.0588 14.932 10.4995 14.242 10.4995Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_662_13526"
            x1="12"
            y1="1.5"
            x2="12"
            y2="22.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#37BBFE" />
            <stop offset="0.546875" stopColor="#1999D9" />
            <stop offset="1" stopColor="#007DBB" />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  );
};
