import React from 'react';
import { Button, Divider, Typography, Col, Row, Tooltip } from 'antd';
import CustomerIncomeIcon from '../Icons/CustomerIncomeIcon';
import { Icon } from './Icon';
import AsyncWidget from './AsyncWidget';

import translator from '@webpack/translator';
const t = translator('front_office.components.customer_income.');
const { Text } = Typography;

const Content = ({
  currency,
  title,
  description,
  showHowCalculated,
  showButton,
  buttonHref,
  buttonLabel,
}) => (
  <>
    <Row wrap={false}>
      <Col flex="68px">
        <Icon size={52} currency={currency} icon={CustomerIncomeIcon} />
      </Col>
      <Col flex="auto">
        <Row>
          <Text style={{ color: '#424242' }}>{t('subtitle')}</Text>
        </Row>
        <Row>
          <Text style={{ fontSize: '20px', lineHeight: '28px' }} strong>
            {title}
          </Text>
        </Row>
      </Col>
    </Row>
    <Divider style={{ margin: '16px 0' }} />
    <Text style={{ flex: 1, color: 'rgba(0, 0, 0, 0.65)' }}>
      {description}
      {showHowCalculated && (
        <div style={{ marginTop: 16 }}>
          <Tooltip
            placement="topLeft"
            trigger={['hover', 'click']}
            title={<span dangerouslySetInnerHTML={{ __html: t('how_calculated_text') }} />}
          >
            <Text style={{ cursor: 'pointer' }} underline>
              {t('how_calculated')}
            </Text>
          </Tooltip>
        </div>
      )}
    </Text>
    {showButton && (
      <Button
        style={{ borderRadius: 8, marginTop: 16 }}
        size="large"
        block
        target="_blank"
        href={buttonHref}
      >
        <Text ellipsis>{buttonLabel}</Text>
      </Button>
    )}
  </>
);

const CustomerIncome = ({ endpoint }) => {
  return <AsyncWidget endpoint={endpoint}>{(state) => <Content {...state} />}</AsyncWidget>;
};

export default CustomerIncome;
