import React from 'react';
import { Col, Row } from 'antd';
import classNames from 'classnames';

import translator from '@webpack/translator';
const t = translator('main_page.elements.footer.');

const Footer = () => {
  const nav = [
    { title: t('nav.services.title'), href: t('nav.services.href') },
    { title: t('nav.integrations.title'), href: t('nav.integrations.href') },
    { title: t('nav.docs.title'), href: t('nav.docs.href') },
    { title: t('nav.tariffs.title'), href: t('nav.tariffs.href') },
    { title: t('nav.blog.title'), href: t('nav.blog.href') },
    { title: t('nav.contacts.title'), href: t('nav.contacts.href') },
    { title: t('nav.policies.title'), href: t('nav.policies.href'), className: 'desk-hide' },
  ];
  return (
    <footer className="container">
      <div className="footer">
        <div className="footer-body">
          <Row>
            <Col xs={0} lg={4} xl={4}></Col>
            <Col xs={24} lg={16} xl={16}>
              <div className="footer-box">
                <a className="footer-nav-item desk-visible" href="#">
                  {t('chatpush')}
                </a>
                <div className="footer-nav">
                  {nav.map(({ title, href, className }) => (
                    <div
                      key={`${title}${href}`}
                      className={classNames('footer-nav-item', className)}
                    >
                      <a href={href}>{title}</a>
                    </div>
                  ))}
                </div>
                <a className="footer-nav-item desk-visible" href={t('nav.policies.href')}>
                  {t('nav.policies.title')}
                </a>
              </div>
            </Col>
            <Col xs={0} lg={4} xl={4}></Col>
          </Row>
        </div>
        <div className="footer-pannel">
          <div className="header">
            <div className="left"></div>
            <div className="center">
              <div className="center-pannel"></div>
            </div>
            <div className="right"></div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
