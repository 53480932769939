import React from "react";

export default (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12.1574" cy="12.8426" r="12.1574" fill="#868686" />
      <path
        d="M13.2884 9.7985C13.0658 9.75738 12.8614 9.89943 12.819 10.1167C12.7766 10.334 12.9191 10.5461 13.1356 10.5887C13.7876 10.7158 14.291 11.2204 14.4186 11.8746V11.8751C14.4549 12.0634 14.6207 12.2003 14.8116 12.2003C14.8372 12.2003 14.8628 12.1979 14.8889 12.1933C15.1055 12.1498 15.248 11.9381 15.2056 11.7204C15.0151 10.7433 14.263 9.98868 13.2884 9.7985Z"
        fill="white"
      />
      <path
        d="M13.2593 8.17036C13.155 8.15541 13.0502 8.18625 12.9669 8.2526C12.8812 8.31989 12.8276 8.41708 12.816 8.52596C12.7913 8.74604 12.9501 8.9451 13.1699 8.96987C14.6857 9.13902 15.8639 10.3198 16.0343 11.8403C16.0571 12.044 16.228 12.1978 16.432 12.1978C16.4473 12.1978 16.4623 12.1968 16.4776 12.195C16.5843 12.1833 16.6793 12.1305 16.7463 12.0464C16.8129 11.9623 16.8432 11.8576 16.8311 11.7506C16.6187 9.85348 15.1504 8.3811 13.2593 8.17036Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6582 13.3438C13.9161 15.6011 14.4283 12.9896 15.866 14.4263C17.252 15.8119 18.0486 16.0895 16.2926 17.8451C16.0726 18.0218 14.675 20.1485 9.76357 15.2384C4.85149 10.3277 6.97698 8.92874 7.1538 8.70884C8.91411 6.94842 9.18693 7.74966 10.5729 9.13528C12.0106 10.5725 9.40021 11.0864 11.6582 13.3438Z"
        fill="white"
      />
    </svg>
  );
};
