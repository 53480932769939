import React, { useRef, useState } from 'react';
import jstz from 'jstz';
import Helper from '@webpack/helper.js';
import Captcha from '@front_office/components/Captcha';

import { Button, Checkbox, Form, Input, Space } from 'antd';

import translator from '@webpack/translator';
const t = translator('front_office.views.registration.sign_in.');

const SignIn = ({ setSection }) => {
  Helper.setCookie('time_zone', jstz.determine().name());
  const captchaRef = useRef(null);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const onClickLink = (e, section) => {
    e.preventDefault();
    setSection(section);
  };

  const onChangeCaptcha = (value) => {
    setDisableSubmit(false);
  };

  return (
    <form
      className="ant-form ant-form-vertical"
      action="/customers/sign_in"
      method="post"
      acceptCharset="UTF-8"
    >
      <input
        type="hidden"
        name="authenticity_token"
        value={$('meta[name="csrf-token"]').attr('content')}
      />
      <Form.Item label={t('phone_or_email')} name="login">
        <Input name="customer[login]" size="large" />
      </Form.Item>
      <Form.Item label={t('password')} name="password">
        <Input.Password name="customer[password]" className="password-input" />
      </Form.Item>
      <Form.Item name="remember" valuePropName="checked">
        <Checkbox name="customer[remember]" defaultChecked="true" value="1">
          {t('remember_me')}
        </Checkbox>
        <a className="with_underline" onClick={(e) => onClickLink(e, 'restore_password')}>
          {t('forgot_password')}
        </a>
      </Form.Item>
      <Form.Item>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Captcha ref={captchaRef} onChange={onChangeCaptcha} />

          <Button block={true} type="primary" htmlType="submit" disabled={disableSubmit}>
            {t('sign_in')}
          </Button>
          <a
            className="ant-btn ant-btn-default ant-btn-block"
            onClick={(e) => onClickLink(e, 'start')}
          >
            {t('sign_up')}
          </a>
        </Space>
      </Form.Item>
    </form>
  );
};

export default SignIn;
