import React from 'react';
import { Col, Row } from 'antd';

import translator from '@webpack/translator';
const t = translator('main_page.elements.welcome.');

const Welcome = () => {
  return (
    <div className="container">
      <div className="welcome">
        <Row>
          <Col xs={0} md={2} lg={4} xl={2}></Col>
          <Col xs={24} md={20} lg={16} xl={20}>
            <div className="welcome-body">
              <div
                className="welcome-body-title"
                dangerouslySetInnerHTML={{ __html: t('title') }}
              ></div>
              <div
                className="welcome-body-desc"
                dangerouslySetInnerHTML={{ __html: t('desc') }}
              ></div>
              <div className="welcome-body-actions">
                <a className="button button-primary" href="/customers/sign_up">
                  {t('registration_button_text')}
                </a>
                <a className="button button-common" href="#tariffs">
                  {t('tariffs_button_text')}
                </a>
              </div>
            </div>
          </Col>
          <Col xs={0} md={2} lg={4} xl={2}></Col>
        </Row>
      </div>
    </div>
  );
};

export default Welcome;
