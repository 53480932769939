import React from 'react';

import {
  Header,
  Footer,
  JoinUs,
  Welcome,
  Partners,
  TariffsTabs,
  AnimatedPhone,
  Advantages,
  TextWithLotti,
  Faq,
} from './elements';

import MessengerDesktop from './lottiefile/main_page/messenger_desktop.json';
import MessengerWithTablet from './lottiefile/main_page/messenger_with_tablet_and_mobile.json';
import Integrations from './lottiefile/main_page/integrations.json';
import Cascade from './lottiefile/main_page/cascade.json';
import Api from './lottiefile/main_page/api.json';

import translator from '@webpack/translator';
const t = translator('main_page.index_view.');

const IndexView = ({ tariff_sms_default_prices }) => {
  const text_with_lotties = [
    {
      id: 'services',
      title: t('all_necessary_channels_in_one_place'),
      desc: t(
        'simple_setup_according_to_the_instructions_and_ready_made_integrations_for_sms_whatsapp_telegram_vk_ok_and_other_channels_choose_what_suits_you',
      ),
      lottie: {
        animationData: MessengerDesktop,
        loop: true,
        autoplay: true,
      },
      links: [
        { text: t('whatsapp'), href: '/channels/whatsapp' },
        { text: t('sms'), href: '/channels/sms' },
        { text: t('vk'), href: '/channels/VK' },
        { text: t('ok'), href: '/channels/ok' },
        { text: t('telegram'), href: '/channels/Telegram' },
        { text: t('all_channels'), href: '/channels', className: 'button-black' },
      ],
      revert: true,
      col_grid: { xs: 0, md: 0, lg: 20, xl: 16 },
    },
    {
      id: 'services_2',
      title: t('all_necessary_channels_in_one_place'),
      desc: t(
        'simple_setup_according_to_the_instructions_and_ready_made_integrations_for_sms_whatsapp_telegram_vk_ok_and_other_channels_choose_what_suits_you',
      ),
      lottie: { animationData: MessengerWithTablet, loop: true, autoplay: true },
      links: [
        { text: t('whatsapp'), href: '/channels/whatsapp' },
        { text: t('sms'), href: '/channels/sms' },
        { text: t('vk'), href: '/channels/VK' },
        { text: t('ok'), href: '/channels/ok' },
        { text: t('telegram'), href: '/channels/Telegram' },
        { text: t('all_channels'), href: '/channels', className: 'button-black' },
      ],
      revert: true,
      col_grid: { xs: 24, md: 24, lg: 0, xl: 0 },
    },
    {
      id: 'integrations',
      title: t('messenger_integrations'),
      desc: t('send_messages_from_your_convenient_CRM_or_use_multiple_ones'),
      links: [
        { text: '1c', href: '/integrations/1c' },
        { text: 'amoCRM', href: '/integrations/amocrm' },
        { text: 'YCLIENTS', href: '/integrations/yclients' },
        { text: 'Opencart', href: '/integrations/opencart' },
        { text: 'Bitrix 24', href: '/integrations/bitrix24' },
        { text: 'Webasyst', href: '/integrations/webasyst' },
        { text: t('all_integrations'), href: '/integrations', className: 'button-black' },
      ],
      lottie: { animationData: Integrations, loop: true, autoplay: true },
      revert: false,
    },
    {
      id: 'send_api',
      title: t('api_delivery'),
      desc: t(
        'send_automated_messages_from_programs_applications_websites_or_CRMs_our_modules_are_used_in_major_systems_they_can_be_trusted',
      ),
      links: [{ text: t('documentation'), href: t('documentation_href') }],
      lottie: { animationData: Api },
      revert: true,
    },
    {
      id: 'cascade',
      title: t('cascade_delivery'),
      desc: t(
        'guaranteed_delivery_and_budget_savings_due_to_step-by-step_sending_if_the_client_did_not_receive_the_message_via_the_first_channel_we_will_send_the_message_through_the_next_ones',
      ),
      links: [{ text: t('all_about_cascades'), href: '/blog/kaskad' }],
      lottie: {
        animationData: Cascade,
        loop: true,
        autoplay: true,
      },
      revert: false,
    },
  ];

  return (
    <>
      <Header />
      <Welcome />
      <AnimatedPhone />
      <Advantages />
      {text_with_lotties.map((text) => (
        <TextWithLotti key={text.id} {...text} />
      ))}
      <TariffsTabs tariff_sms_default_prices={tariff_sms_default_prices} />
      <Partners />
      <Faq />
      <JoinUs />
      <Footer />
    </>
  );
};

export default IndexView;
