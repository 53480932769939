import React from 'react';

export default props => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="52" height="52" fill="white" />
      <g id="&#208;&#154;&#208;&#190;&#208;&#188;&#208;&#191;&#208;&#190;&#208;&#189;&#208;&#181;&#208;&#189;&#209;&#130;&#209;&#139;">
        <path d="M-2229 -565C-2229 -566.105 -2228.1 -567 -2227 -567H3502C3503.1 -567 3504 -566.105 3504 -565V1634C3504 1635.1 3503.1 1636 3502 1636H-2227C-2228.1 1636 -2229 1635.1 -2229 1634V-565Z" fill="#E5E5E5" />
        <g id="&#208;&#146;&#208;&#184;&#208;&#180;&#208;&#182;&#208;&#181;&#209;&#130; &#226;&#134;&#146; &#208;&#158;&#208;&#179;&#209;&#128;&#208;&#176;&#208;&#189;&#208;&#184;&#209;&#135;&#208;&#181;&#208;&#189;&#208;&#184;&#208;&#181; &#208;&#190;&#209;&#130;&#208;&#191;&#209;&#128;&#208;&#176;&#208;&#178;&#208;&#186;&#208;&#184;">
          <rect x="-43.5" y="-43.5" width="1699" height="409" rx="4.5" fill="#9747FF" fillOpacity="0.1" />
          <g id="&#208;&#161;&#208;&#190;&#209;&#129;&#209;&#130;&#208;&#190;&#209;&#143;&#208;&#189;&#208;&#184;&#208;&#181;=&#208;&#158;&#208;&#177;&#209;&#139;&#209;&#135;&#208;&#189;&#208;&#190;&#208;&#181;">
            <rect x="-24" y="-24" width="400" height="370" rx="16" fill="white" />
            <g id="Content">
              <g id="header">
                <g id="icon">
                  <g clipPath="url(#clip0_573_11297)">
                    <rect width="52" height="52" rx="8" fill="#FFE8AD" />
                    <g id="alarm-line">
                      <path id="Vector" d="M52.0084 29.6242C54.7258 33.647 55.3378 41.217 52.6261 47.4962C50.325 52.8493 43.8957 57.7591 37.9417 57.9986C34.222 58.1492 29.6365 57.5826 26.1954 56.1769C20.9378 54.0309 17.7718 49.7435 16.762 44.3302C15.2367 36.0266 17.8767 27.5849 25.5214 23.177C30.747 20.1549 35.3001 20.0648 41.1851 21.3995C44.039 22.0352 49.2947 25.5987 52.0084 29.6242Z" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_2" d="M45.2222 33.019C45.2222 33.019 39.9827 37.2927 36.6823 40.1031" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_3" d="M37.2102 38.5585C39.119 40.8811 36.4699 43.6311 34.2032 42.8875C32.2787 42.262 31.0629 38.6565 33.3685 37.4775C34.4301 36.9388 36.2658 37.2346 37.2102 38.5585Z" fill="#161615" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_4" d="M17.4175 23.1081C14.1839 15.5883 23.6698 10.3546 27.2638 16.4607" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_5" d="M49.0228 19.7317C54.9274 15.6749 61.3374 24.3589 56.8837 28.3271" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_6" d="M34.8735 40.5247L36.7935 26.0059" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_7" d="M13.6857 30.0003C11.0816 34.0904 10.6377 41.6881 13.4965 47.9092" stroke="#161615" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <rect x="-43.5" y="-43.5" width="1699" height="409" rx="4.5" stroke="#9747FF" strokeDasharray="10 5" />
        </g>
        <path d="M-2227 -566H3502V-568H-2227V-566ZM3503 -565V1634H3505V-565H3503ZM3502 1635H-2227V1637H3502V1635ZM-2228 1634V-565H-2230V1634H-2228ZM-2227 1635C-2227.55 1635 -2228 1634.55 -2228 1634H-2230C-2230 1635.66 -2228.66 1637 -2227 1637V1635ZM3503 1634C3503 1634.55 3502.55 1635 3502 1635V1637C3503.66 1637 3505 1635.66 3505 1634H3503ZM3502 -566C3502.55 -566 3503 -565.552 3503 -565H3505C3505 -566.657 3503.66 -568 3502 -568V-566ZM-2227 -568C-2228.66 -568 -2230 -566.657 -2230 -565H-2228C-2228 -565.552 -2227.55 -566 -2227 -566V-568Z" fill="black" fillOpacity="0.1" />
      </g>
      <defs>
        <clipPath id="clip0_573_11297">
          <rect width="52" height="52" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
