import React from 'react';

export default (props) => {
  return (
    <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_186_9896)">
        <rect x="0.333313" width="52" height="52" rx="8" fill="#FFE8AD" />
        <path
          d="M37.1566 12.0303C30.9081 11.7752 25.0585 13.0962 21.1119 17.3796C17.247 21.5747 16.0601 25.0046 14.7195 30.73C13.7974 34.6537 14.6999 38.2144 15.6154 42.0499C16.6519 46.3627 19.6568 49.3349 23.7179 52.1992C27.7789 55.0635 35.3386 56.1229 38.6312 55.9888C42.313 55.8449 45.38 55.7043 48.9309 54.5174C51.6089 53.6248 55.817 51.0548 57.3996 48.7234C60.8891 43.4822 62.2068 37.0907 61.0748 30.8967C60.0056 25.2956 57.2884 19.9888 52.8317 16.3006C49.5685 13.603 43.4051 12.2886 37.1566 12.0303Z"
          stroke="#161615"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.8862 12.125C36.0056 12.0008 41.0933 13.0242 43.2481 14.3256C45.4028 15.6269 48.4143 17.749 50.6214 20.7703C52.4393 23.2618 54.362 27.9997 54.6235 30.9882C54.9963 35.2651 53.7963 41.4515 51.5761 45.1397C50.5527 46.8367 47.5838 50.4563 44.2453 52.7386C40.9069 55.0209 37.9053 55.7795 34.1091 55.6814"
          stroke="#161615"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.6476 30.6742C27.8933 30.0203 29.1457 29.3402 30.3293 28.5947C30.2705 32.247 29.7669 35.9026 29.7669 39.5876"
          stroke="#161615"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.6476 39.3654C27.5402 39.9997 29.5903 39.8918 30.6824 40.0193C31.4803 40.1043 32.1702 40.3267 32.9974 40.2645"
          stroke="#161615"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M36.8527 40.1252C36.8527 37.0407 37.0257 32.3388 37.1206 30.3377C37.2088 28.1601 39.3701 27.189 41.1325 28.4413C42.9977 30.1909 44.0987 32.5854 42.1403 34.0542C40.1819 35.523 38.7862 34.3682 34.8453 34.0542"
          stroke="#161615"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M35.5308 37.4321C37.0578 37.4321 38.9248 37.4321 40.4354 37.7591"
          stroke="#161615"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.4051 36.3573C18.2901 32.463 19.4508 28.402 21.6318 25.1355C22.8285 23.3404 24.5745 21.1137 26.5593 20.2015C28.2988 19.4069 30.156 19.2205 31.9936 19.0865"
          stroke="#161615"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M38.7914 19.1976C40.9496 19.4247 42.9948 20.2758 44.677 21.6467C46.7435 23.2456 48.0448 25.8712 48.774 28.3595C49.5343 31.0127 49.7038 33.8003 49.2705 36.526C48.8372 39.2518 47.8117 41.8494 46.2661 44.136C45.2655 45.5976 43.065 47.4058 41.4661 48.0597"
          stroke="#161615"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M35.5707 49.3968C34.2955 49.7009 32.9026 49.3543 31.647 49.0535"
          stroke="#161615"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.3631 46.7516C23.8323 45.2573 22.2955 43.593 21.2982 40.9216"
          stroke="#161615"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M48.9309 18.5303C51.0235 18.9815 53.0998 18.4649 55.1696 18.6414"
          stroke="#161615"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M50.2747 47.7064C51.7527 48.1576 53.4072 47.8666 54.9538 47.9287C55.8562 47.9647 57.0137 47.7292 57.8508 48.0399"
          stroke="#161615"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M46.1451 51.6202C48.532 51.6202 50.9483 51.7707 53.2764 51.8426"
          stroke="#161615"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_186_9896">
          <rect x="0.333313" width="52" height="52" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
