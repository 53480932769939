import React from 'react';
import { Modal, Typography } from 'antd';

const { Paragraph } = Typography;

import translator from '@webpack/translator';
const t = translator('front_office.views.bulk_deliveries.modals.in_active_whatsapp.');

const InActiveWhatsapp = ({ visible, onCancel }) => {
  return (
    <Modal
      centered
      title={t('modal_title')}
      okText={t('button_connect_whatsapp')}
      cancelText={t('button_return_to_send_form')}
      visible={visible}
      onCancel={onCancel}
      maskClosable={true}
      okButtonProps={{
        type: 'link',
        href: '/front_office/onboarding',
        className: 'ant-btn-primary',
      }}
    >
      <Paragraph>{t('paragraph_whatsapp_not_activated')}</Paragraph>
    </Modal>
  );
};

export default InActiveWhatsapp;
