import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#tariff_sms_megafon)">
          <path
            d="M12.9331 13.1924C11.8287 13.1924 10.9318 12.2968 10.9318 11.1938C10.9318 10.0882 11.8287 9.19267 12.9331 9.19267C14.0375 9.19267 14.9319 10.0882 14.9319 11.1938C14.9319 12.2968 14.0375 13.1924 12.9331 13.1924ZM16.0005 0C7.16143 0 0 7.16193 0 15.9989C0 23.6672 5.39733 30.0757 12.5983 31.6358V17.2786L12.5993 17.2776C12.6154 16.5846 13.1675 16.0263 13.8544 16.0003V15.9993H14.5203H15.8755C15.9694 15.9993 16.046 15.9252 16.0509 15.8326L16.0519 0.00286576M27.0267 24.7266C25.9235 24.7266 25.0266 23.831 25.0266 22.7255C25.0266 21.6237 25.9235 20.7281 27.0267 20.7281C28.1298 20.7281 29.0267 21.6237 29.0267 22.7255C29.0267 23.831 28.1298 24.7266 27.0267 24.7266ZM22.3384 24.7266C21.2352 24.7266 20.3396 23.831 20.3396 22.7255C20.3396 21.6237 21.2352 20.7281 22.3384 20.7281C23.4428 20.7281 24.3385 21.6237 24.3385 22.7255C24.3385 23.831 23.4428 24.7266 22.3384 24.7266ZM17.6477 24.7266C16.5445 24.7266 15.6464 23.831 15.6464 22.7255C15.6464 21.6237 16.5445 20.7281 17.6477 20.7281C18.7534 20.7281 19.649 21.6237 19.649 22.7255C19.649 23.831 18.7534 24.7266 17.6477 24.7266ZM17.9751 0.120579V15.9984V16.616V16.6272L17.9712 16.6311C17.9652 17.3451 17.3831 17.9219 16.6666 17.9219H16.0514H14.6974C14.5986 17.9219 14.5195 18.001 14.5195 18.0986V31.9308C15.0075 31.9753 15.4992 32 15.9995 32C24.8373 32 32 24.8356 32 15.9987C32 7.83249 25.88 1.09425 17.9749 0.120876"
            fill="#00B956"
          />
        </g>
        <defs>
          <clipPath id="tariff_sms_megafon">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  );
};
