import React from 'react';
import {SVGUniqueID} from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="12" cy="12" r="10.5" fill="url(#paint0_linear_662_13518)" />
        <path
          d="M17.2399 7.65659C17.3334 7.05249 16.7591 6.57567 16.2219 6.8115L5.52361 11.5086C5.13842 11.6778 5.1666 12.2612 5.5661 12.3884L7.77236 13.091C8.19344 13.2251 8.64939 13.1558 9.01709 12.9017L13.9912 9.4652C14.1412 9.36157 14.3047 9.57485 14.1766 9.70697L10.5961 13.3985C10.2488 13.7566 10.3177 14.3634 10.7355 14.6254L14.7442 17.1392C15.1938 17.4212 15.7723 17.1379 15.8564 16.5946L17.2399 7.65659Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_662_13518"
            x1="12"
            y1="1.5"
            x2="12"
            y2="22.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#37BBFE" />
            <stop offset="0.546875" stopColor="#1999D9" />
            <stop offset="1" stopColor="#007DBB" />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  );
};
