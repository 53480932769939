import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Chart">
          <g id="Chart_2">
            <path
              id="Chart_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.33 2.00043H16.669C20.07 2.00043 21.99 3.92943 22 7.33043V16.6704C22 20.0704 20.07 22.0004 16.669 22.0004H7.33C3.929 22.0004 2 20.0704 2 16.6704V7.33043C2 3.92943 3.929 2.00043 7.33 2.00043ZM12.049 17.8604C12.48 17.8604 12.839 17.5404 12.879 17.1104V6.92043C12.919 6.61043 12.77 6.29943 12.5 6.13043C12.219 5.96043 11.879 5.96043 11.61 6.13043C11.339 6.29943 11.19 6.61043 11.219 6.92043V17.1104C11.27 17.5404 11.629 17.8604 12.049 17.8604ZM16.65 17.8604C17.07 17.8604 17.429 17.5404 17.48 17.1104V13.8304C17.509 13.5094 17.36 13.2104 17.089 13.0404C16.82 12.8704 16.48 12.8704 16.2 13.0404C15.929 13.2104 15.78 13.5094 15.82 13.8304V17.1104C15.86 17.5404 16.219 17.8604 16.65 17.8604ZM8.2192 17.1104C8.1792 17.5404 7.8202 17.8604 7.3892 17.8604C6.9592 17.8604 6.5992 17.5404 6.5602 17.1104V10.2004C6.5302 9.88942 6.6792 9.58042 6.9502 9.41042C7.2192 9.24042 7.5602 9.24042 7.8302 9.41042C8.0992 9.58042 8.2502 9.88942 8.2192 10.2004V17.1104Z"
              fill="#161615"
            />
          </g>
        </g>
      </svg>
    </SVGUniqueID>
  );
};
