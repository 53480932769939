import React, { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = ({ onChange }, ref) => {
  return (
    <div className="captcha">
      <ReCAPTCHA ref={ref} onChange={onChange} sitekey={process.env.recapchaClientKey} />
    </div>
  );
};

export default forwardRef(Captcha);
