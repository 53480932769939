import React from 'react';

export default (props) => {
  return (
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 14L2.70711 8.70711C2.31658 8.31658 2.31658 7.68342 2.70711 7.29289L8 2"
        stroke="#414141"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};
