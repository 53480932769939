import React from 'react';
import {SVGUniqueID} from 'react-svg-unique-id';

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 23.25C17.799 23.25 22.5 18.549 22.5 12.75C22.5 6.95101 17.799 2.25 12 2.25C6.20101 2.25 1.5 6.95101 1.5 12.75C1.5 14.6332 1.99575 16.4005 2.86386 17.9287L1.5 23.25L6.98615 21.9778C8.47603 22.7891 10.1842 23.25 12 23.25ZM12 21.6346C16.9068 21.6346 20.8846 17.6568 20.8846 12.75C20.8846 7.84316 16.9068 3.86538 12 3.86538C7.09316 3.86538 3.11538 7.84316 3.11538 12.75C3.11538 14.6445 3.70838 16.4006 4.71888 17.8427L3.92308 20.8269L6.95995 20.0677C8.39202 21.0559 10.1284 21.6346 12 21.6346Z"
          fill="#BFC8D0"
        />
        <path
          d="M21 12C21 16.9706 16.9706 21 12 21C10.1041 21 8.34516 20.4138 6.89449 19.4127L3.81818 20.1818L4.62432 17.1588C3.60069 15.698 3 13.9192 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
          fill="url(#paint0_linear_662_13501)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 13.8832 1.99575 15.6505 2.86386 17.1787L1.5 22.5L6.98615 21.2278C8.47603 22.0391 10.1842 22.5 12 22.5ZM12 20.8846C16.9068 20.8846 20.8846 16.9068 20.8846 12C20.8846 7.09316 16.9068 3.11538 12 3.11538C7.09316 3.11538 3.11538 7.09316 3.11538 12C3.11538 13.8945 3.70838 15.6506 4.71888 17.0927L3.92308 20.0769L6.95995 19.3177C8.39202 20.3059 10.1284 20.8846 12 20.8846Z"
          fill="white"
        />
        <path
          d="M9.37502 7.12504C9.12537 6.6236 8.7424 6.668 8.35551 6.668C7.66407 6.668 6.58594 7.49621 6.58594 9.03759C6.58594 10.3008 7.14259 11.6836 9.01831 13.7522C10.8285 15.7485 13.207 16.7812 15.1816 16.7461C17.1563 16.7109 17.5625 15.0117 17.5625 14.4379C17.5625 14.1835 17.4047 14.0566 17.296 14.0221C16.6231 13.6992 15.382 13.0975 15.0996 12.9844C14.8172 12.8714 14.6698 13.0243 14.5781 13.1075C14.3221 13.3515 13.8144 14.0707 13.6406 14.2325C13.4668 14.3942 13.2077 14.3124 13.0999 14.2512C12.7031 14.092 11.6272 13.6134 10.7696 12.7821C9.70899 11.754 9.64675 11.4002 9.44693 11.0854C9.28707 10.8335 9.40437 10.6789 9.46291 10.6114C9.69142 10.3477 10.007 9.94061 10.1485 9.73832C10.29 9.53602 10.1776 9.22889 10.1102 9.03759C9.82033 8.21488 9.57474 7.52618 9.37502 7.12504Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_662_13501"
            x1="19.875"
            y1="5.25"
            x2="3"
            y2="21"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5BD066" />
            <stop offset="1" stopColor="#27B43E" />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  );
};
