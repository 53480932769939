import React from 'react';
import { Container, Row, Cell } from '@components/grid';
import Card from './Card';
import telegram_logo from '@imgs/front_office/onboarding/telegram_logo.svg';
import whatsapp_logo from '@imgs/front_office/onboarding/whatsapp_logo.svg';
import vk_disabled_logo from '@imgs/front_office/onboarding/vk_disabled_logo.svg';
import left_arrow from '@imgs/front_office/onboarding/left_arrow.svg';
import tg_tdlib_logo from '@imgs/front_office/onboarding/tg_tdlib_logo.svg';

import translator from '@webpack/translator';
const t = translator('front_office.views.integrations.variants_view.');

export default ({ setVariant, telegramBotStep, telegramTdLibStep, helpPhone, helpWhatsapp, helpEmail, foreignCustomer }) => {
  const cardDetails = [
    {
      icon: whatsapp_logo,
      title: t('whatsapp_title'),
      description: t('whatsapp_description'),
      link: foreignCustomer && {} || { text: t('what_is_this'), href: t('whatsapp_help_link') },
      handler: () => window.go_to_step('action', 2),
    },
    {
      icon: telegram_logo,
      title: t('telegram_title'),
      link:  foreignCustomer && {} || { text: t('what_is_this'), href: t('telegrambot_help_link') },
      description: t('telegram_bot_description'),
      additionalInfo: t('bot_tag'),
      handler: () => setVariant(telegramBotStep),
    },
    {
      icon: tg_tdlib_logo,
      title: t('telegram_title'),
      description: t('telegram_tdlib_description'),
      link: foreignCustomer && {} || { text: t('what_is_this'), href: t('tdlib_help_link') },
      additionalInfo: t('number_tag'),
      handler: () => setVariant(telegramTdLibStep),
    },
    !foreignCustomer && {
      icon: vk_disabled_logo,
      title: t('vk_ok_title'),
      description: t('vk_ok_description'),
      additionalInfo: t('coming_soon_tag'),
      handler: () => {},
    } || {}
  ];

  const cellRange = foreignCustomer ? 4 : 3
  
  return (
    <Container>
      <Row className="soc_networks-title">
        <Cell md={4} lg={4} className="back-button">
          <a href="/front_office/onboarding">
            <img className="left-arrow-icon" src={left_arrow} />
            {t('back_button')}
          </a>
        </Cell>
        <Cell md={5} lg={5}>
          <h1 className="soc_networks-title_text">{t('title')}</h1>
        </Cell>
      </Row>
      <Row className="soc_networks-body">
        {cardDetails.map(({ icon, title, description, handler, link, additionalInfo }) => {
          if (icon){
             return <Cell md={cellRange} lg={cellRange} key={icon}>
              <Card
                icon={icon}
                title={title}
                description={description}
                handler={handler}
                link={link}
                additionalInfo={additionalInfo}
              />
            </Cell>
          }
        })}
      </Row>
    </Container>
  );
};
