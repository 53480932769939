import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingSpin = () => (
  <LoadingOutlined
    style={{
      fontSize: 20,
    }}
    spin
  />
);

export default LoadingSpin;
