import React from 'react';
import classNames from 'classnames';

const Input = ({ htmlType, label, name, placeholder, className, onChange, inputClassName, value, type, error, disabled, help, autocomplete, validate }) => {
  return (
    <div className={classNames("field input-field", className, { error: error })}>
      {label && <div className='label'>{label}</div>}
      <input
        placeholder={placeholder}
        autoComplete={autocomplete}
        className={classNames('input', inputClassName)}
        name={name}
        type={htmlType || type}
        disabled={disabled}
        value={value}
        onChange={(e) => { onChange(e.target.value) }}
        {...validate}
      />
      {help && <div className="input-help">{help}</div>}
      {error && <div className="input-error">{error}</div>}
    </div>
  )
}

export default Input;
