import React, { useState, useRef } from 'react';
import { Col, Row, Collapse, Carousel } from 'antd';
import Lottie from 'lottie-react';
import Arrow from '../imgs/Arrow';
import HorArrow from '../imgs/HorArrow';

import translator from '@webpack/translator';
const t = translator('main_page.elements.animated_phone.');

import classNames from 'classnames';
import { Salon, Ecom, Fitness, Sales } from '../imgs/animated_phone';

import ClientSalon from '../lottiefile/main_page/client_salon.json';
import ClientEcom from '../lottiefile/main_page/client_ecom.json';
import ClientFitness from '../lottiefile/main_page/client_fitness.json';
import ClientSales from '../lottiefile/main_page/client_sales.json';

const { Panel } = Collapse;

const AnimatedPhone = () => {
  let sliderRef = useRef < HTMLInputElement > null;
  let sliderRefTwo = useRef < HTMLInputElement > null;
  const [indexOfMobileCarousel, changeIndexOfMobileCarousel] = useState(0);

  const mobileCarouselPath = [
    'messengery-dlya-salonov-krasoty',
    'messengery-internet-manazinov',
    'messengery-fitnes-clubov',
    'messengery-dlya-otdelov-prodaj',
  ];

  const panels = [
    {
      key: 0,
      header: (
        <div className="ant-collapse-header-text-box">
          <div className="ant-collapse-header-text-box-pannel"></div>
          <div className="ant-collapse-header-text-box-icon">
            <Salon />
          </div>
          <div>{t('beauty_salons')}</div>
        </div>
      ),
      text: (
        <>
          <div className="ant-collapse-content-box-content-desc">
            {t('description_1_beauty_salons')}
          </div>

          <a className="button button-common" href="/for/messengery-dlya-salonov-krasoty">
            {t('button_text_beauty_salons')}
          </a>
        </>
      ),
    },
    {
      key: 1,
      header: (
        <div className="ant-collapse-header-text-box">
          <div className="ant-collapse-header-text-box-pannel"></div>
          <div className="ant-collapse-header-text-box-icon">
            <Ecom />
          </div>
          <div>{t('shop_online')}</div>
        </div>
      ),
      text: (
        <>
          <div className="ant-collapse-content-box-content-desc">
            {t('description_1_shop_online')}
          </div>

          <a className="button button-common" href="/for/messengery-internet-manazinov">
            {t('button_text_shop_online')}
          </a>
        </>
      ),
    },
    {
      key: 2,
      header: (
        <div className="ant-collapse-header-text-box">
          <div className="ant-collapse-header-text-box-pannel"></div>
          <div className="ant-collapse-header-text-box-icon">
            <Fitness />
          </div>
          <div>{t('fitness_clubs')}</div>
        </div>
      ),
      text: (
        <>
          <div className="ant-collapse-content-box-content-desc">
            {t('description_1_fitness_clubs')}
          </div>

          <a className="button button-common" href="/for/messengery-fitnes-clubov">
            {t('button_text_fitness_clubs')}
          </a>
        </>
      ),
    },
    {
      key: 3,
      header: (
        <div className="ant-collapse-header-text-box">
          <div className="ant-collapse-header-text-box-pannel"></div>
          <div className="ant-collapse-header-text-box-icon">
            <Sales />
          </div>
          <div>{t('sales_departments')}</div>
        </div>
      ),
      text: (
        <>
          <div className="ant-collapse-content-box-content-desc">
            {t('description_1_sales_departments')}
          </div>

          <a className="button button-common" href="/for/messengery-dlya-otdelov-prodaj">
            {t('button_text_sales_departments')}
          </a>
        </>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="animated_phone">
        <div className="animated_phone-body">
          <Row gutter={48}>
            <Col xs={0} md={0} lg={2} xl={4}></Col>
            <Col xs={0} md={12} lg={10} xl={8}>
              <div className="animated_phone-collapses">
                <Collapse
                  onChange={(collapse) => sliderRef.goTo(collapse)}
                  accordion={true}
                  expandIconPosition="end"
                  defaultActiveKey={['0']}
                  expandIcon={({ isActive }) => (
                    <div
                      className={classNames('collapse-panel-xmark', isActive ? 'open' : 'close')}
                    >
                      <Arrow />
                    </div>
                  )}
                >
                  {panels.map((panel) => (
                    <Panel header={panel.header} key={panel.key}>
                      <div className="ant-collapse-content-box-content">{panel.text}</div>
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </Col>
            <Col xs={24} md={0} lg={0} xl={0}>
              <div className="animated_phone-mobile-сarousel-manager">
                <div className="animated_phone-mobile-сarousel-manager-box">
                  <div
                    className="animated_phone-mobile-сarousel-manager-box-left_arrow"
                    onClick={() => {
                      sliderRefTwo.prev(), sliderRef.prev();
                    }}
                  >
                    <HorArrow />
                  </div>
                  <Carousel
                    ref={(node) => {
                      sliderRefTwo = node;
                    }}
                    dots={false}
                    effect="fade"
                    afterChange={(index) => changeIndexOfMobileCarousel(index)}
                  >
                    <div>
                      <div className="carousel-box">
                        <div className="carousel-box-pannel"></div>
                        <div className="carousel-box-icon">
                          <Salon />
                        </div>
                        <div>{t('beauty_salons')}</div>
                      </div>
                    </div>
                    <div>
                      <div className="carousel-box">
                        <div className="carousel-box-pannel"></div>
                        <div className="carousel-box-icon">
                          <Ecom />
                        </div>
                        <div>{t('shop_online')}</div>
                      </div>
                    </div>
                    <div>
                      <div className="carousel-box">
                        <div className="carousel-box-pannel"></div>
                        <div className="carousel-box-icon">
                          <Fitness />
                        </div>
                        <div>{t('fitness_clubs')}</div>
                      </div>
                    </div>
                    <div>
                      <div className="carousel-box">
                        <div className="carousel-box-pannel"></div>
                        <div className="carousel-box-icon">
                          <Sales />
                        </div>
                        <div>{t('sales_departments')}</div>
                      </div>
                    </div>
                  </Carousel>
                  <div
                    className="animated_phone-mobile-сarousel-manager-box-right_arrow"
                    onClick={() => {
                      sliderRefTwo.next(), sliderRef.next();
                    }}
                  >
                    <HorArrow />
                  </div>
                </div>
                <a
                  className="button button-primary"
                  href={`/for/${mobileCarouselPath[indexOfMobileCarousel]}`}
                >
                  {t('learn_more')}
                </a>
              </div>
            </Col>
            <Col xs={24} md={12} lg={10} xl={8}>
              <Carousel
                ref={(node) => {
                  sliderRef = node;
                }}
                dots={false}
                effect="fade"
              >
                <div>
                  <div className="animated_phone-phone_box">
                    <div className="animated_phone-phone_box-phone">
                      <Lottie loop autoplay animationData={ClientSalon} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="animated_phone-phone_box">
                    <div className="animated_phone-phone_box-phone">
                      <Lottie loop autoplay animationData={ClientEcom} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="animated_phone-phone_box">
                    <div className="animated_phone-phone_box-phone">
                      <Lottie loop autoplay animationData={ClientFitness} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="animated_phone-phone_box">
                    <div className="animated_phone-phone_box-phone">
                      <Lottie loop autoplay animationData={ClientSales} />
                    </div>
                  </div>
                </div>
              </Carousel>
            </Col>
            <Col xs={0} md={0} lg={2} xl={4}></Col>
          </Row>
        </div>
        <div className="animated_phone-pannel">
          <div className="header">
            <div className="left"></div>
            <div className="center">
              <div className="center-pannel"></div>
            </div>
            <div className="right"></div>
          </div>
          <div className="main"></div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedPhone;
