import React from 'react';
import { Col, Row, Tabs } from 'antd';
import MessengerTab from './tariffs/MessengerTab';
import SmsTab from './tariffs/SmsTab';

import translator from '@webpack/translator';
const t = translator('main_page.elements.tariffs_tabs.');

const Tarrifs = ({ tariff_sms_default_prices }) => {
  const items = [
    { label: t('messenger_tab'), key: '1', children: <MessengerTab /> }, // remember to pass the key prop
    {
      label: t('sms_tab'),
      key: '2',
      children: <SmsTab tariff_sms_default_prices={tariff_sms_default_prices} />,
    },
  ];
  return (
    <div className="container" id="tariffs">
      <div className="tariffs">
        <Row>
          <Col xs={1} md={6} lg={2} xl={4}></Col>
          <Col xs={22} md={12} lg={20} xl={16}>
            <div className="tariffs-body">
              <div className="tariffs-body-title">{t('title')}</div>
              <Tabs centered renderTabBar={false} items={items} />
            </div>
          </Col>
          <Col xs={1} md={6} lg={2} xl={4}></Col>
        </Row>
      </div>
    </div>
  );
};

export default Tarrifs;
