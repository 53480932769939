import React from 'react';
import {Alert} from 'antd';

const WarningAlert = ({message}) =>
  <Alert
    className='yclients-notification-settings-create__warning-alert'
    showIcon
    type='warning'
    closable
    message={message}
  />;


export default WarningAlert;
