import React from "react";
import {Row, Col, Typography, Skeleton} from 'antd';
import translator from '@webpack/translator';
const t = translator('front_office.views.altegio_notification_settings.');
const {Text} = Typography;

const TextPreview = ({text, selectedSalonName, isLoading, skeletonHeight}) => {
  return (
    <Row gutter={[0, 8]}>
      <Col sm={24} />
      <Col span={24}>
        <Text>{t('fields.text_preview')}</Text>
      </Col>
      <Col span={24}>
        <div className='altegio-notification-settings-create__preview'>
          <Skeleton loading={!text || isLoading} avatar={{size: 36}} title active paragraph={{rows: skeletonHeight}}>
            <div className='altegio-notification-settings-create__preview-avatar'>{selectedSalonName[0]}</div>
            <div className='altegio-notification-settings-create__preview-text'>
              <div className='altegio-notification-settings-create__preview-text-header'>
                <Text strong>{selectedSalonName}</Text>
                <Text type='secondary'>{t('fields.text_preview_time')}</Text>
              </div>
              <Text style={{whiteSpace: 'pre-wrap'}}>{text}</Text>
            </div>
          </Skeleton>
        </div>
      </Col>
    </Row>
  );
};

export default TextPreview;
