import React, { useState } from 'react';

import { ConfirmationCode, ChangePhone, ConfirmEmail, ChangeEmail, Start } from './sign_up';
import { Reset, Restore } from './password';
import SignIn from './SignIn';

const BaseView = ({
  customer,
  initialSection,
  onlyRussianNumbersAllowed,
  defaultCountry,
  projectScopeHeader,
  projectScopeShort,
}) => {
  const [section, setSection] = useState(initialSection || 'start');
  const [confirmationType, setConfirmationType] = useState('register_code');
  const [confimRouting, setConfirmRouting] = useState('phone');
  const [customerData, setCustomerData] = useState(customer || {});

  const sections = {
    start: (
      <Start
        defaultCountry={defaultCountry}
        onlyRussianNumbersAllowed={onlyRussianNumbersAllowed}
        setSection={setSection}
        setCustomerData={setCustomerData}
        projectScopeShort={projectScopeShort}
      />
    ),
    sms: (
      <ConfirmationCode
        confirmationType={confirmationType}
        customer={customerData}
        setSection={setSection}
        setCustomerData={setCustomerData}
        confimRouting={confimRouting}
        projectScopeHeader={projectScopeHeader}
      />
    ),
    change_phone: (
      <ChangePhone
        customer={customerData}
        setCustomerData={setCustomerData}
        setSection={setSection}
        onlyRussianNumbersAllowed={onlyRussianNumbersAllowed}
        defaultCountry={defaultCountry}
      />
    ),
    email: <ConfirmEmail customer={customerData} setSection={setSection} />,
    change_email: (
      <ChangeEmail
        customer={customerData}
        setCustomerData={setCustomerData}
        setSection={setSection}
      />
    ),
    sign_in: <SignIn setSection={setSection} />,
    restore_password: (
      <Restore
        setSection={setSection}
        setCustomerData={setCustomerData}
        setConfirmationType={setConfirmationType}
        onlyRussianNumbersAllowed={onlyRussianNumbersAllowed}
        setConfirmRouting={setConfirmRouting}
        defaultCountry={defaultCountry}
      />
    ),
    reset_password: <Reset customer={customerData} setSection={setSection} />,
  };

  const currentSection = sections[section];
  return currentSection;
};

export default BaseView;
