import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Section = ({ id, children, className, style }) => (
    <section id={id} className={classNames('widget', className)} style={style}>
        {children}
    </section>
);

Section.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string
};

export default Section;
