import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Container, Row, Cell, Section} from '@components/grid';
import Radio from '@front_office/components/payment/Radio';
import Button from '@components/Button';
import bank_card from '@imgs/front_office/payment/bank_card.svg';
import import_bank_card from '@imgs/front_office/payment/import_bank_card.svg';
import Helper from '@webpack/helper.js';

import translator from '@webpack/translator';
const t = translator('front_office.views.reccurent_payments.index_view.');

const IndexView = ({subscriptionCost, createUrl, reccurentPayments, currency, formData}) => {
  return (
    <div className="payment">
      <Container>
        <Row>
          <Cell>
            <Section className="widget-v2">
              <Row>
                <Cell md={12} lg={12}>
                  <h3 className="header-v2 flex text-align-start without_margin">
                    {' '}
                    {t('auto_payments')}{' '}
                  </h3>
                </Cell>
              </Row>
            </Section>
          </Cell>
        </Row>
        {reccurentPayments.length ? (
          <Index reccurentPayments={reccurentPayments} currency={currency} />
        ) : (
          <Form subscriptionCost={subscriptionCost} createUrl={createUrl} currency={currency} formData={formData} />
        )}
      </Container>
    </div>
  );
};

const Index = ({reccurentPayments, currency}) => {
  const reccurentPaymentsTrs = reccurentPayments.map((recPay) => (
    <tr key={recPay.id}>
      <td>{recPay.id}</td>
      <td>{recPay.created_at}</td>
      <td>
        <span className="fw-semi-bold">{Helper.formatMoney(recPay.sum, currency)}</span>
      </td>
      <td>
        <span className="fw-semi-bold">{t('subscription')}</span>
      </td>
      <td>{recPay.number_card}</td>
      <td>
        <a href={recPay.destroy_path} data-method="delete" data-confirm={t('delete_confirm')}>
          {t('delete_button')}
        </a>
      </td>
    </tr>
  ));

  return (
    <Row>
      <Cell md={12} lg={12}>
        <Section className="widget-v2 same_height">
          <table id="datatable-table" className="table table-striped table-hover">
            <thead>
              <tr>
                <th className="need_timezone">#</th>
                <th className="need_timezone">{t('date')}</th>
                <th>{t('sum')}</th>
                <th>{t('subscription')}</th>
                <th>{t('card')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{reccurentPaymentsTrs}</tbody>
          </table>
        </Section>
      </Cell>
    </Row>
  );
};

const Form = ({subscriptionCost, createUrl, currency, formData}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm();
  const [rails_errors, setRailsErrors] = useState([]);

  const onSubmit = (data) => {
    if ($.isEmptyObject(errors)) {
      const form_data = new FormData();
      for (let key in data) {
        form_data.append(key, data[key]);
      }

      fetch(createUrl, {
        method: 'POST',
        headers: {
          Accept: 'text/html',
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        },
        body: form_data,
      })
        .then((response) => response.json())
        .then((res_data) => {
          if (res_data.redirect_to) {
            window.location.href = res_data.redirect_to;
          }

          if (res_data.errors) {
            setRailsErrors(res_data.errors);
          }
        });
    }
  };

  const radioButtonsValidate = register('payment_method', {required: true});

  const icons = { tinkoff: bank_card, stripe: import_bank_card };

  return (
    <Row>
      <Cell md={6} lg={6}>
        <Section className="widget-v2 same_height">
          <Row>
            <Cell>
              <div className="payment-header">
                <h3>{t('payment_methods')}</h3>
              </div>
              <div className="payment-body">
                <div className="errors_box">
                  {rails_errors &&
                    rails_errors.slice(0).map((error) => <span key={error}>{error}</span>)}
                </div>
                <Radio
                  title={t('bank_card')}
                  name={'payment_method'}
                  value={'bank_card'}
                  checked={true}
                  content={t('safe_payment_online')}
                  onClick={() => setValue('gateway_type', formData.gateway_type)}
                  validate={radioButtonsValidate}
                  img={icons[formData.gateway_type]}
                />
              </div>
            </Cell>
          </Row>
        </Section>
      </Cell>
      <Cell md={6} lg={6}>
        <Section className="widget-v2 same_height">
          <form className='max_height' onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="sum" value={formData.check_sum} {...register('sum', {required: true})} />
            <input
              type="hidden"
              name="kind"
              value="parent_reccurent"
              {...register('kind', {required: true})}
            />
            <input
              type="hidden"
              name="gateway_type"
              value={formData.gateway_type}
              {...register('gateway_type', {required: true})}
            />
            <Row className='max_height'>
              <Cell>
                <div className="payment-header">
                  <div>
                    <h3>{t('subscription')}</h3>
                    <h5>
                      <div className="successed_text">{t('subscription_access')}</div>
                    </h5>
                    <div className="flex">
                      <h2>{t('subscription_cost', {cost: Helper.formatMoney(subscriptionCost, currency)})}</h2>
                    </div>
                    {formData.check_sum !== 0 &&
                      <p className="help">{t('help', {cost: Helper.formatMoney(formData.check_sum, currency)})}</p>
                    }
                  </div>
                </div>
              </Cell>
              <Cell className='align-self-end'>
                <div className="payment-footer without_margin">
                  <Button htmlType={'submit'} className={'btn_v2-primary'} text={t('bind')} />
                </div>
              </Cell>
            </Row>
          </form>
        </Section>
      </Cell>
    </Row>
  );
};

export default IndexView;
