import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Row = ({ id, children, className }) => {
    return (
        <div id={id} className={classNames('row', className)}>
            {children}
        </div>
    );
}

Row.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string
};

export default Row;
