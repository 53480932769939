import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Drawer, Divider, Skeleton, Button, Alert, Grid } from 'antd';
import { useNotificationsState, removeNotification } from './State';
import DispatchRouting from '../../components/DispatchRouting';
import translator from '@webpack/translator';
const t = translator('front_office.views.yclients_notification_settings.');

const Error = () => {
  return <Alert
    message={t('show.errorTitle')}
    description={t('show.errorDescription')}
    type="warning"
    showIcon />;
};

const Footer = ({ id, actionType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();
  const { state, updateState } = useNotificationsState();

  const deleteRecord = () => {
    setIsLoading(true);
    axios.delete(
      `/front_office/yclients_notification_settings/${actionType}/${id}`,
      {
        headers: {
          'Accept': 'application/json',
          'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
        }
      }
    ).then(({ data }) => {
      setIsLoading(false);
      if (data.success) {
        updateState({ notifications: removeNotification(state.notifications, id, actionType) });
        navigate('/', { replace: true });
      }
    }).catch((_error) => {
      setIsLoading(false);
    });
  }

  return <div className='yclients-notification-settings-show__footer'>
    {showConfirmation
      ?
      <>
        {t('show.are_you_sure')}
        <Button
          disabled={isLoading}
          onClick={deleteRecord}
          className='ant-custom-btn'
          type='primary'
          danger
        >
          {t('show.confirm_delete')}
        </Button>
        <Button
          disabled={isLoading}
          className='ant-custom-btn'
          type='primary'
          onClick={() => setShowConfirmation(false)}
        >
          {t('show.abort_delete')}
        </Button>
      </>
      :
      <>
        <Button
          className='ant-custom-btn'
          onClick={() => navigate(`/${actionType}/${id}/edit`)}
        >
          {t('show.edit')}
        </Button>
        <Button className='ant-custom-btn' danger onClick={() => setShowConfirmation(true)}>
          {t('show.delete')}
        </Button>
      </>
    }
  </div>;
}

const ShowDrawerContent = ({ isLoading, items }) => {
  if (isLoading) {
    return <>
      <Skeleton active paragraph={{ rows: 1, width: '100%' }} />
      <Skeleton active paragraph={{ rows: 1, width: '100%' }} />
      <Skeleton active paragraph={{ rows: 1, width: '100%' }} />
      <Divider />
      <Skeleton active paragraph={{ rows: 3, width: '100%' }} />
      <Divider />
      <Skeleton active paragraph={{ rows: 3, width: '100%' }} />
    </>;
  }

  return <ul className='yclients-notification-settings-show__items'>
    {items.map(({ type, title, value }, idx) => {
      switch (type) {
        case 'dispatch_routing':
          return <li key={idx}>
            <div className='yclients-notification-settings-show__items_title'>{title}</div>
            <div className='yclients-notification-settings-show__items_content'>
              <DispatchRouting routing={value} />
            </div>
          </li>;

        case 'services':
        case 'body':
          return <React.Fragment key={idx}>
            <Divider />
            <li>
              <div className='yclients-notification-settings-show__items_title'>{title}</div>
              <div className='yclients-notification-settings-show__items_content' dangerouslySetInnerHTML={{ __html: value }}></div>
            </li>
          </React.Fragment>;

        case 'positive_feedback_bot_response':
        case 'negative_feedback_bot_response':
          return <li key={idx}>
            <div className='yclients-notification-settings-show__items_title'>{title}</div>
            <div className='yclients-notification-settings-show__items_content' dangerouslySetInnerHTML={{ __html: value }}></div>
          </li>;

        case 'positive_feedback_values':
          return <React.Fragment key={idx}>
            <Divider />
            <li>
              <div className='yclients-notification-settings-show__items_title'>{title}</div>
              <div className='yclients-notification-settings-show__items_content'>{value}</div>
            </li>
          </React.Fragment>;

        case 'created_at':
          return <React.Fragment key={idx}>
            <Divider />
            <li>
              <div className='yclients-notification-settings-show__items_title'>{title}</div>
              <div className='yclients-notification-settings-show__items_content'>{value}</div>
            </li>
          </React.Fragment>;

        default:
          return <li key={idx}>
            <div className='yclients-notification-settings-show__items_title'>{title}</div>
            <div className='yclients-notification-settings-show__items_content'>{value}</div>
          </li>;
      }
    })}
  </ul>;
};

const ShowDrawer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [items, setItems] = useState([]);
  const { id, action_type } = useParams();
  const screens = Grid.useBreakpoint();

  const fetchData = () => {
    axios(
      `/front_office/yclients_notification_settings/${action_type}/${id}`,
      { headers: { 'Accept': 'application/json' } }
    ).then(({ data }) => {
      if (data.success) {
        setTitle(data.frontend_data.title);
        setItems(data.frontend_data.items);
      } else {
        setIsError(true);
      }
    }).catch((_error) => {
      setIsError(true);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return <Drawer
    open
    className='ant-custom-drawer'
    title={isLoading ? <Skeleton className='ant-custom-skeleton' active paragraph={false} title={{ width: '66%' }} /> : title}
    width={screens.md ? 400 : 'calc(100% - 12px)'}
    onClose={() => navigate('/')}
    footer={!isLoading && !isError && <Footer id={id} actionType={action_type} />}
  >
    {isError
      ? <Error />
      : <ShowDrawerContent isLoading={isLoading} items={items} />
    }
  </Drawer>;
};

export default ShowDrawer;
