import React from 'react';

import translator from '@webpack/translator';
const t = translator('front_office.views.integrations.telegram_bot.header.');

export const Header = ({ stepNumber }) => (
  <div className="telegram-flow_header">
    <h1>{t('title')}</h1>
    <p>{t('step_count', { stepNumber })}</p>
  </div>
);
